// @flow
import React from 'react';
import styled from 'styled-components';
import range from 'ramda/src/range';
import media from '../../theme';
import { Button } from '../Global';

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin: 50px auto;
  align-items: center;
  justify-content: center;
`;

const PaginationItem = styled.div`
  color: #444444;
  font-size: 16px;
  min-height: 45px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  border-right: 1px solid #dedede;
  &:last-child {
    border: none;
  }
  ${media.max.large`
    padding: 0 15px;
  `};
`;

const PageTotal = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-left: 20px;
  ${media.max.medium`
    margin-left: 5px;
  `};
`;

const Select = styled.select`
  width: 100%;
  height: 45px;
  font-size: 14px;
  line-height: 40px;
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  padding-left: 15px;
  color: #7e7e7e;
  background-color: #fff;
  &:disabled {
    border: none;
    background-color: #d6d6d6;
  }
  &:focus {
    border: 1px solid #999999;
    color: #444444;
    outline: none;
  }
  ${media.max.medium`
    padding-left: 5px;
  `};
`;

const PaginationArrow = styled.img`
  ${media.min.medium`
    display: none;
  `};
`;

type Props = {
  onShowAllResultsClick: Function,
  translations: Object,
  results: Object,
  globalStyling: Object,
  onPageNumberChange: Function,
  currentPage: number,
  totalResults: number,
  pageSize: number,
};

const renderOptions = pageTotal =>
  range(1, pageTotal + 1).map(p => (
    <option key={p} value={p}>
      {p}
    </option>
  ));

const directions = {
  next: 'next',
  prev: 'prev',
};
const onDirectionClick = (
  direction: string,
  currentPage: number,
  onChangeFn: Function,
) => {
  if (direction === directions.next) {
    return () => onChangeFn(currentPage + 1);
  }
  if (direction === directions.prev) {
    return () => onChangeFn(currentPage - 1);
  }
  return () => {};
};

export default function Pagination(props: Props) {
  const {
    currentPage,
    totalResults,
    pageSize,
    onPageNumberChange,
    onShowAllResultsClick,
    translations,
  } = props;
  const pageTotal = Math.ceil(totalResults / pageSize);
  return (
    <Container>
      <PaginationItem>
        <Button
          styleOverride={() => `
              text-transform: none;
              border: none;
              background-color: #ffffff;
              color: #444444;
                &:hover {
                  border: none;
                  background-color: #ffffff;
                  color: #444444;
                }
              `}
          mobileStyleOverride={() => `
            display: none;
          `}
          data-qa-hook="pagination-button-previous"
          onClick={onDirectionClick(
            directions.prev,
            currentPage,
            onPageNumberChange,
          )}
          disabled={currentPage === 1}
          text={translations.searchResultsPreviousLabel}
          buttonStyle="ButtonStyleBTT"
        />
        <PaginationArrow
          src="https://res.cloudinary.com/motortrak/image/upload/v1564589170/cms/global/icons/paging-left.svg"
          onClick={() =>
            currentPage !== 1 &&
            onDirectionClick(directions.prev, currentPage, onPageNumberChange)
          }
        />
      </PaginationItem>
      <PaginationItem>
        <Select
          data-qa-hook="pagination-dropdown"
          value={currentPage}
          onChange={e => onPageNumberChange(parseInt(e.target.value, 10))}
        >
          {renderOptions(pageTotal)}
        </Select>
        <PageTotal>/</PageTotal>
        <PageTotal>{pageTotal}</PageTotal>
      </PaginationItem>
      <PaginationItem>
        <Button
          styleOverride={() => `
              text-transform: unset;
              border: none;
              background-color: #ffffff;
              color: #444444;
                &:hover {
                  border: none;
                  background-color: #ffffff;
                  color: #444444;
                }
              `}
          mobileStyleOverride={() => `
            display: none;
          `}
          data-qa-hook="pagination-button-next"
          onClick={onDirectionClick(
            directions.next,
            currentPage,
            onPageNumberChange,
          )}
          disabled={currentPage === pageTotal}
          text={translations.searchResultsNextLabel}
          buttonStyle="ButtonStyleBTT"
        />
        <PaginationArrow
          src="https://res.cloudinary.com/motortrak/image/upload/v1564589170/cms/global/icons/paging-right.svg"
          onClick={() =>
            currentPage !== pageTotal &&
            onDirectionClick(directions.next, currentPage, onPageNumberChange)
          }
        />
      </PaginationItem>
      <PaginationItem>
        <Button
          styleOverride={() => `
                text-transform: unset;
                border: none;
                background-color: #ffffff;
                color: #444444;
                &:hover {
                  border: none;
                  background-color: #ffffff;
                  color: #444444;
                }
              `}
          data-qa-hook="pagination-button-all"
          onClick={() => onShowAllResultsClick()}
          text={translations.searchResultsShowAllLabel}
          buttonStyle="ButtonStyleBTT"
        />
      </PaginationItem>
    </Container>
  );
}
