/* eslint-disable no-unused-vars */
import {
  saveCustomerAuth,
  clearCustomerAuth,
  saveCustomerDetails,
  clearCustomerDetails,
  saveCustomerShortlist,
  clearCustomerShortlist,
  clearLeadsAuth,
  saveLeadsAuth,
  clearCustomerCompare,
  saveCustomerCompare,
  clearVehicleValuation,
  saveVehicleValuation,
  loadLocale,
  clearLocale,
  saveLocale,
} from './localStorage';

function clearOrSave({ entry, saveFunc, clearFunc }) {
  if (
    entry === null ||
    entry === undefined ||
    Object.keys(entry).length === 0
  ) {
    clearFunc();
  } else {
    saveFunc(entry);
  }
}

let [
  currentCustomerDetails,
  currentAuthenticationDetails,
  currentLeadsAuthentication,
  currentCustomerShortlist,
  currentCustomerCompare,
  currentVehicleValuation,
  currentLocale,
] = [null, null, null, [], [], null, null];

export default function subscriber({
  customerDetails,
  authenticationDetails,
  customerShortlist,
  leadsAuthentication,
  customerCompare,
  vehicleValuation,
  locale,
}) {
  const [
    previousCustomerDetails,
    previousAuthenticationDetails,
    previousCustomerShortlist,
    previousLeadsAuthentication,
    previousCustomerCompare,
    previousVehicleValuation,
    previousLocale,
  ] = [
    currentCustomerDetails,
    currentAuthenticationDetails,
    currentCustomerShortlist,
    currentLeadsAuthentication,
    currentCustomerCompare,
    currentVehicleValuation,
    currentLocale,
  ];
  [
    currentCustomerDetails,
    currentAuthenticationDetails,
    currentCustomerShortlist,
    currentLeadsAuthentication,
    currentCustomerCompare,
    currentVehicleValuation,
    currentLocale,
  ] = [
    customerDetails,
    authenticationDetails,
    customerShortlist,
    leadsAuthentication,
    customerCompare,
    vehicleValuation,
    locale,
  ];

  if (previousCustomerDetails !== currentCustomerDetails) {
    clearOrSave({
      entry: currentCustomerDetails,
      clearFunc: clearCustomerDetails,
      saveFunc: saveCustomerDetails,
    });
  }

  if (previousAuthenticationDetails !== currentAuthenticationDetails) {
    clearOrSave({
      entry: currentAuthenticationDetails,
      clearFunc: clearCustomerAuth,
      saveFunc: saveCustomerAuth,
    });
  }

  if (previousCustomerShortlist !== currentCustomerShortlist) {
    clearOrSave({
      entry: currentCustomerShortlist,
      clearFunc: clearCustomerShortlist,
      saveFunc: saveCustomerShortlist,
    });
  }

  if (previousCustomerCompare !== currentCustomerCompare) {
    clearOrSave({
      entry: currentCustomerCompare,
      clearFunc: clearCustomerCompare,
      saveFunc: saveCustomerCompare,
    });
  }

  if (previousLeadsAuthentication !== currentLeadsAuthentication) {
    clearOrSave({
      entry: currentLeadsAuthentication,
      clearFunc: clearLeadsAuth,
      saveFunc: saveLeadsAuth,
    });
  }

  if (previousVehicleValuation !== currentVehicleValuation) {
    clearOrSave({
      entry: currentVehicleValuation,
      clearFunc: clearVehicleValuation,
      saveFunc: saveVehicleValuation,
    });
  }

  if (previousLocale !== currentLocale) {
    clearOrSave({
      entry: currentLocale,
      clearFunc: clearLocale,
      saveFunc: saveLocale,
    });
  }
}
