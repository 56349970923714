// @flow
import React from 'react';
import styled from 'styled-components';

import type { Node } from 'react';

import transparentize from 'polished/lib/color/transparentize';
import media from '../theme';

type Position = {
  x: number,
  y: number,
  screenWidth: number,
  screenHeight: number,
};

type Props = {
  children?: ?Node,
  onClickOutside?: ?() => void,
  position?: ?Position,
  overlay?: boolean,
  width?: number,
};

const Overlay = styled.div.withConfig({
  displayName: 'Overlay',
})`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: ${transparentize(0.3, '#444444')};
  z-index: 98;
`;

const Container = styled.div.withConfig({
  displayName: 'ModalContainer',
})`
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  top: ${({ position }) => position.y}px;
  left: 0;
  z-index: 99;
  ${media.min.medium`
    position: fixed;
    height: auto;
    max-height: 100vh;
    overflow-y: scroll;
    box-shadow: 0 6px 14px 0 ${transparentize(0.2, '#444444')};
    width: ${({ width }) => (width ? `${width}px;` : 'auto')};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
  `};
`;

export default function Modal({
  children,
  onClickOutside,
  position,
  overlay,
  width,
}: Props) {
  return (
    <div>
      {overlay ? (
        <Overlay onClick={onClickOutside} position={position} />
      ) : null}
      <Container position={{ y: window.scrollY }} width={width}>
        {children}
      </Container>
    </div>
  );
}

Modal.defaultProps = {
  children: null,
  onClickOutside: () => {},
  position: null,
  overlay: true,
  width: 880,
};
