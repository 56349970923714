// @flow
import React from 'react';
import styled from 'styled-components';

const LinkButtton = styled.button`
  border: none;
  text-decoration: underline;
  ${({ fullWidth }) => fullWidth && 'width: 100%;'};
  color: ${({ theme }) => theme.linkColour};
  font-family: ${({ theme }) =>
    theme.fontFamily ? theme.fontFamily : 'inherit'};
  cursor: pointer;
  background-color: transparent;

  height: 45px;
  outline: none;
  font-size: 16px;
`;

function Link({ ...props }) {
  return <LinkButtton {...props} />;
}

Link.defaultProps = {
  fullWidth: false,
  theme: {
    fontFamily: 'inherit',
    linkColour: '#000000',
  },
};

export default Link;
