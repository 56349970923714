import {
  put,
  call,
  take,
  takeEvery,
  select,
  all,
  actionChannel,
  fork,
  cancel,
  takeLatest,
  flush,
  delay,
} from 'redux-saga/effects';
import querystring from 'querystring';

import { actions, constants } from './reducer';
import { get, localeConfigSerializer } from '../../helpers/http';
import { mapVehicleAndFinance as getFinance } from '../../shared/financeCalculator/effects';
import {
  inventorySearchServiceUrl,
  getPlaceholderBrandImageUrl,
  getAVLConfig,
  appendLocaleQueryString,
  getInventoryStatus,
  getFinanceStatus,
  getGlobalFinance,
} from '../../shared/selectors/settings';
import { localiseNumber } from '../../shared/localisation/numbers';
import { actions as errorActions } from '../../shared/errors';

let currentLoadingTasks = [];

function* mapVehicleAndFinance(vehicle, pageNumber, page, instance) {
  try {
    const globalFinance = yield select(getGlobalFinance);
    const vehicleWithFinance = yield getFinance(
      vehicle,
      globalFinance.defaultProduct,
    );
    yield put(
      actions.getFinanceSuccess(page, instance, {
        vehicle: vehicleWithFinance,
        pageNumber,
      }),
    );
  } catch (error) {
    // noop leave vehicle as is
  }
}

function* batchFinanceRequests(page, instance, pageSize, pageNumber, vehicles) {
  const infiniteScroll = yield select(
    state => state.pages[page][instance].infiniteScroll,
  );
  // to prevent messed up rendering with white space
  const maxFinanceToLoadAtOnce = infiniteScroll ? 2 : Math.ceil(pageSize / 2);
  // Finance can only be cached for 15 mins on the front end
  const vehiclesToLoadFinanceFor = vehicles.filter(
    v => !v.finance || v.finance.mustRefreshAfter < Date.now(),
  );

  while (vehiclesToLoadFinanceFor.length > 0) {
    const vehicleBatch = vehiclesToLoadFinanceFor.splice(
      0,
      maxFinanceToLoadAtOnce,
    );
    yield put(
      actions.loadFinance(page, instance, {
        vehicles: vehicleBatch,
        pageNumber,
      }),
    );
  }
}

function* getInventory(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  const existingResults = yield select(
    state => state.pages[page][instance].searchResults[payload.pageNumber],
  );

  const financeStatus = yield select(getFinanceStatus);
  const pageSize = yield select(state => state.pages[page][instance].pageSize);
  if (existingResults && !payload.forceUpdate) {
    yield put(actions.changePage(page, instance, payload.pageNumber));

    if (financeStatus) {
      yield batchFinanceRequests(
        page,
        instance,
        pageSize,
        payload.pageNumber,
        existingResults,
      );
    }
  } else {
    try {
      const { locale, make, market } = yield select(getAVLConfig);
      const withLocaleQueryString = yield select(appendLocaleQueryString);
      const baseUrl = yield select(inventorySearchServiceUrl);
      const placeholdingImage = yield select(getPlaceholderBrandImageUrl);
      const inventoryStatus = yield select(getInventoryStatus);

      const location = yield select(
        state => state.shared.sessionPreferences.location || {},
      );

      let { filters } = payload;
      filters = Object.keys(location)
        ? `${filters}&${querystring.stringify(location)}`
        : filters;

      const url = `${baseUrl}inventory/make/${make}/market/${market}?${filters}&status=${inventoryStatus}`;

      const response = yield call(get, {
        url,
        config: localeConfigSerializer(withLocaleQueryString && locale, {
          pageNumber: payload.pageNumber,
          pageSize,
          status: inventoryStatus,
        }),
      });

      const data = {
        ...response.data,
        contents: [
          ...response.data.contents.map(v => ({
            ...v,
            odometer: {
              ...v.odometer,
              display: `${localiseNumber(v.odometer.reading, payload.locale)} ${
                v.odometer.units
              }`,
            },
          })),
        ],
        placeholdingImage,
      };

      yield put(actions.getInventorySuccess(page, instance, data));
      const vehicles = [...data.contents];

      if (financeStatus) {
        yield batchFinanceRequests(
          page,
          instance,
          pageSize,
          data.pageNumber,
          vehicles,
        );
      }
    } catch (error) {
      yield put(errorActions.setError(error));
    }
  }
}

function* getDealer(action) {
  const {
    payload,
    module: { page, instance },
  } = action;
  const { locale, make, market } = yield select(getAVLConfig);
  const withLocaleQueryString = yield select(appendLocaleQueryString);
  const baseUrl = yield select(inventorySearchServiceUrl);
  const url = `${baseUrl}inventory/make/${make}/market/${market}/dealers/${payload}`;

  try {
    const response = yield call(get, {
      url,
      ...(withLocaleQueryString && {
        config: localeConfigSerializer(locale),
      }),
    });

    yield put(actions.getDealerSuccess(page, instance, response.data));
  } catch (error) {
    // no-op
  }
}

function* handleFinance(action) {
  const {
    payload: { vehicles, pageNumber },
    module: { page, instance },
  } = action;

  yield all(
    vehicles.map(vehicle =>
      call(mapVehicleAndFinance, vehicle, pageNumber, page, instance),
    ),
  );
  yield delay(500);
}

function* loadAllNext(action) {
  const {
    payload: { pageNumber },
    module: { page, instance },
  } = action;

  const pageSize = yield select(state => state.pages[page][instance].pageSize);
  const totalResults = yield select(
    state => state.pages[page][instance].totalResults,
  );

  const totalPages = Math.ceil(totalResults / pageSize);
  let pageToLoad = pageNumber + 1;

  while (pageToLoad <= totalPages) {
    yield call(getInventory, {
      ...action,
      payload: {
        ...action.payload,
        pageNumber: pageToLoad,
      },
    });
    pageToLoad += 1;
    yield delay(1500);
  }
}

function* loadAllPrev(action) {
  const {
    payload: { pageNumber },
  } = action;
  let pageToLoad = pageNumber - 1;
  while (pageToLoad > 0) {
    yield delay(1500);
    yield call(getInventory, {
      ...action,
      payload: {
        ...action.payload,
        pageNumber: pageToLoad,
      },
    });
    pageToLoad -= 1;
  }
}

function* showAllTask(action) {
  const remaining = loadAllNext(action);
  const previous = loadAllPrev(action);
  yield all([remaining, previous]);
}

function* showAll(action) {
  // need to fork so can cancel
  currentLoadingTasks = [
    ...currentLoadingTasks,
    yield fork(showAllTask, action),
  ];
}

let financeChannel;

function* cancelLoading() {
  yield all(currentLoadingTasks.map(t => cancel(t)));
  currentLoadingTasks.length = 0;

  if (financeChannel) {
    yield flush(financeChannel);
  }
}

export default function* SearchResultsSaga() {
  yield takeLatest(constants.GET_INVENTORY, getInventory);
  yield takeEvery(constants.GET_DEALER, getDealer);
  yield takeLatest(constants.SHOW_ALL, showAll);
  yield takeLatest(constants.CLEAR_LOADED, cancelLoading);
  yield takeLatest(constants.CANCEL_LOADING, cancelLoading);

  const shouldLoadFinance = yield select(getFinanceStatus);

  if (shouldLoadFinance) {
    financeChannel = yield actionChannel(constants.LOAD_FINANCE);

    while (true) {
      const action = yield take(financeChannel);
      yield call(handleFinance, action);
    }
  }
}
