/* eslint-disable import/no-unresolved, import/extensions  */
import querystring from 'querystring';
import {
  call,
  put,
  takeLatest,
  takeEvery,
  select,
  take,
} from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { eventChannel } from 'redux-saga';
import { actions, shared } from 'cms-modules';
import {
  constants as configConstants,
  actions as configActions,
} from '../actions/config';
import appSettings from '../../settings';

const {
  router: { constants, actions: routerActions },
} = actions;
const {
  filters: { actions: filterActions },
} = shared;

let history;

function* trackNavigation(path) {
  const sendTracking = yield select(shared.selectors.usesGoogleAnalytics);

  if (sendTracking && window.ga) {
    window.ga('set', 'page', path);
    window.ga('send', 'pageview');
  }
}

const rewriteUrl = (url, rewrite) => {
  const match = url.match(new RegExp(rewrite.regex));
  return match && match[1]
    ? rewrite.redirectUrl.replace('$$MATCH$$', match[1])
    : url;
};
const matchUrlRedirect = (url, rewriteRules = []) => {
  const rewrite = rewriteRules.find(r => new RegExp(r.regex).test(url));
  return rewrite ? rewriteUrl(url, rewrite) : url;
};

function* navigate(action) {
  let path = action.payload;
  const { redirects = { rewriteRules: [] } } = yield select(
    state => state.config.config.global,
  );

  const pattern = new RegExp('(http)(s*)(:*/*/*)');
  if (!action.payload.startsWith('/')) {
    // this added to have access in subnav links from vdp
    path = '/'.concat(path);
  }

  if (action.payload.match(pattern) !== null) {
    window.open(action.payload);
  } else {
    const redirect = matchUrlRedirect(path, redirects.rewriteRules);
    if (path !== redirect) {
      history.push(redirect);
    } else {
      history.push(path);
    }
    trackNavigation(path);
  }
}

function createNavigationWatcher() {
  return eventChannel(emitter =>
    history.listen((location, action) => {
      emitter(routerActions.navigateSuccess(location));
      if (
        (location.pathname === '/search' ||
          location.pathname === '/searchresults') &&
        action === 'POP'
      ) {
        emitter(filterActions.initialiseFilters(location.search.substring(1)));
      }
      if (!location.state || !location.state.disableScroll) window.scroll(0, 0);
      if (location.state) {
        emitter(routerActions.navigateWithPayloadSuccess(location));
      } else {
        emitter(routerActions.navigateSuccess(location));
      }
    }),
  );
}

function* watchNavigations() {
  yield put(routerActions.navigateSuccess(history.location));
  yield put(configActions.initialised());
  const navigationChannel = yield call(createNavigationWatcher);
  while (true) {
    const action = yield take(navigationChannel);
    yield put(action);
  }
}

function* createHistory(action) {
  const { base } = action.payload.global.routing || '';
  if (!history) {
    history = createBrowserHistory({ basename: base });
    yield put(routerActions.historyCreated(history));
    const { pathname, search, hash } = history.location;
    // for configMarketByUrl (sites that run on a base url of www.domain.com/countryCode)
    // we need to prevent the /[countryCode]
    // from being appended to the end of the initial path
    // in cases where for example pathname == '/at/' and base == '/at/something'
    const initialPath = `${
      appSettings.configMarketByUrl && base.startsWith(pathname)
        ? `/${pathname
            .split('/')
            .filter((section, index) => (index > 1 ? section : ''))
            .join('/')}`
        : pathname
    }${search}${hash}`;
    yield put(routerActions.navigate(initialPath));
    yield call(watchNavigations);
  }
}

function* navigateWithPayload(action) {
  yield call(history.push, action.payload.path, action.payload.data);
  yield call(trackNavigation, action.payload.path);
}

function* navigateWithQuery(action) {
  const path = `${action.payload.path}?${querystring.stringify(
    action.payload.queryParams,
  )}`;
  yield call(history.push, path);
  yield call(trackNavigation, path);
}

function* navigateWithFilters(action) {
  yield call(history.push, action.payload.path, {
    disableScroll: action.payload.disableScroll,
  });
  yield call(trackNavigation, action.payload.path);
}

function* replace(action) {
  yield call(history.replace, action.payload);
}

function* routerSaga() {
  yield takeLatest(constants.navigate, navigate);
  yield takeLatest(constants.navigateWithPayload, navigateWithPayload);
  yield takeLatest(constants.navigateWithQuery, navigateWithQuery);
  yield takeLatest(constants.navigateWithFilters, navigateWithFilters);
  yield takeLatest(constants.replace, replace);
  yield takeEvery(configConstants.CONFIG_LOAD, createHistory);
}

export default [routerSaga];
