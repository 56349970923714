// @flow
import React from 'react';
import styled from 'styled-components';

import RadioButtonActive from './JaguarIcons/Global/RadioButtonActive';
import RadioButtonInactive from './JaguarIcons/Global/RadioButtonInactive';

import media from '../theme';

export type Props<Option> = {
  options: Option[],
  getLabelFromOption?: (?Option) => any,
  getValueFromOption?: (?Option) => any,
  onChange: Option => void,
  value?: ?Option,
  horizontal?: ?boolean,
  globalStyling: Object,
};

type ThemeProps = Props<*> & { theme: Theme };

const Container = styled.div.withConfig({
  displayName: 'Container',
})`
  font-family: ${(props: ThemeProps) => props.theme.fontFamily};
  display: flex;
  flex-direction: ${(props: ThemeProps) =>
    props.horizontal ? 'column' : 'row'};
  ${media.max.large`
  flex-direction: column;
`};
`;

const Option = styled.div.withConfig({
  displayName: 'Option',
})`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  width: ${(props: ThemeProps) =>
    props.horizontal ? '' : `calc(100% / ${props.options.length})`};
`;

const OptionLabel = styled.span`
  font-size: ${(props: ThemeProps) => props.theme.fontSizeContent};
  padding-left: 16px;
`;

const Radio = ({ on, globalStyling }: { on: boolean, globalStyling: Object }) =>
  on ? (
    <RadioButtonActive
      colour={
        globalStyling.colours && globalStyling.colours.primaryBrandColour.value
      }
      width="1.5em"
      height="1.5em"
    />
  ) : (
    <RadioButtonInactive colour="#FFFFFF" width="1.5em" height="1.5em" />
  );

export default function RadioGroup({
  options,
  getLabelFromOption,
  getValueFromOption,
  onChange,
  value,
  horizontal,
  globalStyling,
}: Props<*>) {
  return (
    <Container horizontal={horizontal}>
      {options.map(o => (
        <Option
          key={getValueFromOption(o)}
          onClick={() => onChange(o)}
          horizontal={horizontal}
          options={options}
        >
          <Radio
            on={!!value && getValueFromOption(o) === getValueFromOption(value)}
            globalStyling={globalStyling}
          />
          <OptionLabel>{getLabelFromOption(o)}</OptionLabel>
        </Option>
      ))}
    </Container>
  );
}

RadioGroup.defaultProps = {
  getLabelFromOption: opt => opt.label,
  getValueFromOption: opt => opt.value,
  value: null,
  horizontal: false,
};
