// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import type { Font, Option, Link as LinkType } from '../../types';
import {
  Paragraph,
  Button,
  fontStyleOverride,
  positionLayout,
} from '../../components/Global';
import ContentWrapper from '../../components/ContentWrapper';
import { actions } from '../../shared/sessionPreferences';
import media from '../../theme';

const Container = styled.div`
  box-sizing: border-box;
  ${({ fullWidth }) => `width: ${fullWidth ? '100%' : '90%'}`};
  ${props => props.colour && `color: ${props.colour}`};
  padding: 10px 16px;
  ${({ fullWidth }) => `margin: ${fullWidth ? '0' : '0 auto'}`};
`;

const StyledPolicyLink = styled.a`
  ${props => props.colour && `color:  ${props.colour}`};
  text-decoration: underline;
  cursor: pointer;
  padding-left: 5px;
`;

const StickyContainer = styled.div`
  ${({ isSticky }) =>
    isSticky &&
    `
    position: fixed;
    width: 100%;
    z-index: 99;
    bottom: 0px;
  `};
  ${media.min.medium`
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
  `};
  ${({ position }) => positionLayout(position)};
  @media print {
    display: none;
  }
`;

export interface Config {
  cookiesBodyFont: Font;
  cookiesBodyLinkFontColour: Option;
  translations: {
    bodyText: string,
    buttonText: string,
  };
  policyLink: LinkType;
  newTab: boolean;
  cookiesBackgroundColour: Option;
  backgroundColour: Option;
  isSticky: boolean;
}

type Props = {
  config: Config,
  dispatch: Function,
  globalStyling: Object,
  navigateIfInternal: (link: string) => boolean,
  shared: Object,
  preview: boolean,
};

export default function CookiePolicy(props: Props) {
  const domEl = document.getElementById('sticky-search');
  const { config, globalStyling, shared, dispatch, preview } = props;
  const sessionPreferences = preview ? {} : shared.sessionPreferences;
  const fullWidth = !!globalStyling.contentWidth;

  if (sessionPreferences.cookiesAccepted) {
    return null;
  }

  const CookieContent = () => {
    return (
      <ContentWrapper
        colour={config.backgroundColour && config.backgroundColour}
        contentWidth={globalStyling.contentWidth}
      >
        <Container fullWidth={fullWidth} data-nosnippet>
          <Paragraph
            styleOverride={() => fontStyleOverride(config.cookiesBodyFont)}
          >
            {config.translations.bodyText}
            {config.policyLink.on && (
              <StyledPolicyLink
                colour={
                  config.cookiesBodyLinkFontColour &&
                  config.cookiesBodyLinkFontColour.value
                }
                target={config.newTab && '_blank'}
                rel={config.newTab && 'noopener noreferrer'}
                href={config.policyLink.url}
              >
                {config.translations.policyLink}
              </StyledPolicyLink>
            )}
            {config.translations.secondBodyText}
          </Paragraph>
          <Button
            buttonStyle={
              globalStyling.uiElements.primaryButton &&
              globalStyling.uiElements.primaryButton.buttonStyle
            }
            applyStyle="primary"
            text={config.translations.buttonText}
            onClick={() =>
              dispatch(
                actions.updateSessionPreferences('cookiesAccepted', true),
              )
            }
          />
        </Container>
      </ContentWrapper>
    );
  };
  return domEl ? (
    ReactDOM.createPortal(<CookieContent />, domEl)
  ) : (
    <StickyContainer
      isSticky={config.isSticky}
      maxWidth={config.maxWidth}
      position={config.position}
    >
      <CookieContent />
    </StickyContainer>
  );
}
