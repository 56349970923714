import isEmpty from 'ramda/src/isEmpty';
import omit from 'ramda/src/omit';

export const constants = {
  INITIALISE_FILTERS: 'INITIALISE_FILTERS',
  LOAD_FILTERS_SUCCESS: 'LOAD_FILTERS_SUCCESS',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  UPDATE_FILTERS_STORE: 'UPDATE_FILTERS_STORE',
  RESET_FILTERS: 'RESET_FILTERS',
  SEARCH_WITH_FILTERS: 'SEARCH_WITH_FILTERS',
  GET_INVENTORY_SUCCESS: 'GET_INVENTORY_SUCCESS',
  GET_DEALER_SUCCESS: 'GET_DEALER_SUCCESS',
  CHANGE_LOCATION: 'CHANGE_LOCATION',
  RESET_PRICE_SLIDER_VALUES: 'RESET_PRICE_SLIDER_VALUES',
};

export const actions = {
  getInventorySuccess: payload => ({
    type: constants.GET_INVENTORY_SUCCESS,
    payload,
  }),
  getDealerSuccess: payload => ({
    type: constants.GET_DEALER_SUCCESS,
    payload,
  }),
  initialiseFilters: payload => ({
    type: constants.INITIALISE_FILTERS,
    payload,
  }),
  loadFiltersSuccess: payload => ({
    type: constants.LOAD_FILTERS_SUCCESS,
    payload,
  }),
  updateFilters: payload => ({
    type: constants.UPDATE_FILTERS,
    payload,
  }),
  updateFiltersStore: payload => ({
    type: constants.UPDATE_FILTERS_STORE,
    payload,
  }),
  resetFilters: payload => ({
    type: constants.RESET_FILTERS,
    payload,
  }),
  searchWithFilters: () => ({
    type: constants.SEARCH_WITH_FILTERS,
  }),
  changeLocation: payload => ({
    type: constants.CHANGE_LOCATION,
    payload,
  }),
  resetPriceSliderValues: payload => ({
    type: constants.RESET_PRICE_SLIDER_VALUES,
    payload,
  }),
};

const arrayFilters = [
  'bodystyle',
  'model',
  'variant',
  'vehicleTypes',
  'engine',
  'transmission',
  'exteriorColours',
  'interiorShades',
  'features',
  'fuelType',
  'subregions',
  'modelVariants',
];

const countFilters = selectedFilters => {
  const exclude = [
    'yearRangeMin',
    'minPriceRange',
    'latitude',
    'sortField',
    'sortDirection',
    'retailerId',
  ];
  return Object.keys(selectedFilters).filter(f => !exclude.includes(f)).length;
};

const transformFilters = ({ selectedFilters, availableFilterValues }) => {
  const transformedFilters = Object.entries(selectedFilters)
    .map(entry => {
      if (arrayFilters.some(arrayFilter => arrayFilter === entry[0])) {
        return typeof entry[1] === 'string' ? [entry[0], [entry[1]]] : entry;
      }
      return entry;
    })
    .reduce(
      (acc, curr) => ({
        ...acc,
        [curr[0]]: curr[1],
      }),
      {},
    );

  return {
    availableFilterValues,
    selectedFilters: {
      ...transformedFilters,
    },
    activeFiltersCount: countFilters(transformedFilters),
  };
};

export const initialState = {
  selectedFilters: null,
  availableFilterValues: null,
  activeFiltersCount: 0,
};

export function reducer(state = initialState, action) {
  const retailerId =
    state.shared &&
    state.shared.filters.selectedFilters &&
    state.shared.filters.selectedFilters.retailerId;
  const { payload, type } = action;
  switch (type) {
    case constants.GET_DEALER_SUCCESS:
      return {
        ...state,
        dealer: payload,
      };
    case constants.GET_INVENTORY_SUCCESS:
      return {
        ...state,
        total: payload,
      };
    case constants.LOAD_FILTERS_SUCCESS:
      return {
        ...state,
        ...transformFilters(payload),
      };
    case constants.UPDATE_FILTERS_STORE: {
      let updatedState = state;

      for (let idx = 0; idx < payload.length; idx += 1) {
        const data = payload[idx];
        updatedState =
          !data.value || isEmpty(data.value)
            ? {
                ...updatedState,
                selectedFilters: omit([data.key], {
                  ...updatedState.selectedFilters,
                }),
              }
            : {
                ...updatedState,
                selectedFilters: {
                  ...updatedState.selectedFilters,
                  [data.key]: data.value,
                },
              };
      }
      return {
        ...updatedState,
        activeFiltersCount: countFilters(updatedState.selectedFilters),
      };
    }
    case constants.RESET_FILTERS:
      return {
        ...state,
        selectedFilters: retailerId ? { retailerId } : {},
        activeFiltersCount: 0,
        location: '',
      };
    case constants.CHANGE_LOCATION:
      return {
        ...state,
        location: payload,
      };
    default:
      return state;
  }
}
