import { differenceInHours } from 'date-fns';

/* eslint-disable no-console */
const log = (key, error) => console.warn(`[${key}]: ${error}`);

// @TODO: rename authenticationDetails!!!
const keys = {
  customerAuth: 'authenticationDetails',
  customer: 'customerDetails',
  shortlist: 'customerShortlist',
  leadsAuth: 'leadsAuthentication',
  compare: 'customerCompare',
  vehicleValuation: 'vehicleValuation',
  locale: 'locale',
};

// try/catch in case user privacy mode doesn't allow the use of localStorage
function load(key) {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    log(key, error);
    return null;
  }
}

function save(key, data) {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    log(key, error);
  }
}

function clear(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    log(key, error);
  }
}

// Customer auth
export const loadCustomerAuth = () => load(keys.customerAuth);
export const saveCustomerAuth = token => save(keys.customerAuth, token);
export const clearCustomerAuth = () => clear(keys.customerAuth);

// Customer details
export const loadCustomerDetails = () => load(keys.customer);
export const saveCustomerDetails = customer => save(keys.customer, customer);
export const clearCustomerDetails = () => clear(keys.customer);

// Customer shortlist
export const loadCustomerShortlist = () => load(keys.shortlist);
export const saveCustomerShortlist = shortlist =>
  save(keys.shortlist, shortlist);
export const clearCustomerShortlist = () => clear(keys.shortlist);

// Customer compare
export const loadCustomerCompare = () => load(keys.compare);
export const saveCustomerCompare = compare => save(keys.compare, compare);
export const clearCustomerCompare = () => clear(keys.compare);

// Leads auth
export const loadLeadsAuth = () => load(keys.leadsAuth);
export const saveLeadsAuth = token => save(keys.leadsAuth, token);
export const clearLeadsAuth = () => clear(keys.leadsAuth);

export const saveVehicleValuation = valuation =>
  save(keys.vehicleValuation, valuation);
export const clearVehicleValuation = () => clear(keys.vehicleValuation);
// vehicle valuation
export const loadVehicleValuation = () => {
  const valuation = load(keys.vehicleValuation);
  const resultValuation =
    valuation &&
    valuation.created &&
    differenceInHours(new Date(), valuation.created) < 48
      ? valuation
      : null;

  if (!resultValuation) {
    clearVehicleValuation();
  }
  return resultValuation;
};

// Locale
export const loadLocale = () => load(keys.locale);
export const saveLocale = locale => save(keys.locale, locale);
export const clearLocale = () => clear(keys.locale);
