// @flow
import React from 'react';
import styled from 'styled-components';
import User from '../../components/JaguarIcons/Global/User';
import UtilityBar from '../../components/UtilityBar';
import BrandLogo from '../../components/BrandLogo';
import MenuBar from '../../components/MenuBar';
import LocationSettings from '../../components/LocationSettings';
import media from '../../theme';
import type { Option, Font, SectionStyles, Item } from '../../types';
import { actions as routerActions } from '../../actions/router';
import { getCustomerDetails } from '../../shared/CustomerLogin/reducer';
import { HeadingOne, fontStyleOverride } from '../../components/Global';
import ContentWrapper from '../../components/ContentWrapper';
import SocialShareButton from './SocialShareButton';
import { language, territory } from '../../actions';
import { actions as sessionActions } from '../../shared/sessionPreferences';
import { actions as currencyActions } from '../../shared/currencyConversion';
import { actions as filterActions } from '../../shared/filters';

import TemplateTwo from './Templates/TemplateTwo/TemplateTwo';
import TemplateThree from './Templates/TemplateThree/TemplateThree';
import CurrencyPopup from '../../components/CurrencyPopup';

type Text = {
  disabled: boolean,
  editing: boolean,
};

export interface Config {
  utilityBar: string[];
  utilityBarLinkFont: Font;
  utilityBarDropdownFont: Font;
  utilityBarLinkHoverColour: Option;
  utilityBarDropdownHoverColour: Option;
  utilityBarDropdownColour: Option;
  languageDropdownIconSize: Object;
  brandLogoSize: Object;
  utilityBarOptions: any[];
  utilityBarBGColour: Option;
  utilityBarItems: {
    items: Item[],
  };
  menuBar: string[];
  menuBarBorderColour: Option;
  menuBarMobileBorderColour: Option;
  menuBarLinkFont: Font;
  menuBarLinkHoverColour: Option;
  menuBarLinkBgHoverColour: Option;
  menuBarItems: {
    items: Item[],
  };
  menuBarBGColour: Option;
  brandLogo: string[];
  brandLogoBGColour: Option;
  brandLogoBorderColour: Option;
  brandLogoMobileBorderColour: Option;
  brandLogoLink: Option;
  brandLogoUrl: string;
  fallbackUrl: string;
  brandLogoVisibleDesktop: boolean;
  showSearchBar: boolean;
  stickyHeader: boolean;
  utilityBarHeight: number;
  menuBarHeight: number;
  logoAlign: string;
  utilityBarLanguageEnabled: boolean;
  utilityBarCurrencyEnabled: boolean;
  utilityBarAlign: string;
  utilityBarBorderBottomHeight: number;
  utilityBarBorderBottomColour: Option;
  actionsBarBorderBottomHeight: number;
  actionsBarBorderBottomColour: Option;
  menuBarBorderBottomHeight: number;
  menuBarBorderBottomColour: Option;
  showActionsBar: boolean;
  actionsBarHeight: number;
  actionsBarPageTitle: Text;
  actionsBarsAccountTitle: Text;
  socialShareEnabled: boolean;
  actionsBarPageFont: Font;
  actionsBarAccountFont: Font;
  actionsBarBGColour: Option;
  searchBGColour: Option;
  searchTextFont: Font;
  searchBarAlign: string;
  searchIconColour: Option;
  showSearchInputBorder: boolean;
  searchIconAlign: string;
  translations: Object;
  mobileMenuIconsColour: Option;
  accountIconWidth: number;
  accountIconHeight: number;
  mobileAccountIconWidth: number;
  mobileAccountIconHeight: number;
  brandLogoHorizontalPadding: number;
  socialShareFont: Font;
  opacity: number;
  socialShareTitle: Object;
}

type Props = {
  config: Config,
  preview: boolean,
  navigateIfInternal: (link: string) => boolean,
  pathByModule: (path: string) => void,
  dispatch: Function,
  shared: Object,
  globalStyling: Object,
  socialShare: Object,
  marketInfo: Object,
};

const justifyContent = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const UtilitySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ height }) => `${height && `height: ${height}px`}`};
  ${({ backgroundColour }) =>
    `${backgroundColour && `background-color: ${backgroundColour.value}`}`};
  ${({ borderBottomHeight, borderBottomColour }) =>
    borderBottomHeight &&
    borderBottomColour &&
    `border-bottom: ${borderBottomHeight}px solid ${borderBottomColour.value}`};
`;

const Section = styled.div`
  display: flex;
  ${({ contentWidth }) =>
    `width: ${contentWidth ? `${contentWidth}px` : '100%'}`};
  background-color: ${(props: SectionStyles) => props.backgroundColour};
  max-height: ${({ height }) => height && `${height}px`};
  justify-content: ${(props: SectionStyles) =>
    props.align ? justifyContent[props.align] : justifyContent.left};
  ${({ borderTop }) => `${borderTop && `border-top: ${borderTop}`}`};
  ${({ borderBottom }) =>
    `${borderBottom && `border-bottom: ${borderBottom}`}`};
  position: relative;
  z-index: ${({ zIndex }) => zIndex || 9};
`;

const SectionWithPageContainer = styled(Section)`
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0%;
  padding-left: 8px;
  ${({ direction }) => direction === 'rtl' && 'padding-right: 8px;'};
  box-sizing: border-box;
`;

const LogoContainer = styled.div`
  display: flex;
  background-color: ${(props: SectionStyles) => props.backgroundColour};
  padding: ${props => props.padding};
  ${(props: SectionStyles) =>
    props.borderColour && `border-right: 1px solid ${props.borderColour}`};
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  ${({ calculatedHeight }) =>
    calculatedHeight && `height: ${calculatedHeight}px;`}
  ${media.max.large`
    flex: 1 0 0%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `};
`;

const MenuContainer = styled.div`
  background-color: ${(props: SectionStyles) => props.backgroundColour};
  display: flex;
  border-top: ${({ borderTop }) => borderTop && borderTop};
  border-bottom: ${({ borderBottom }) => borderBottom && borderBottom};
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${media.max.small`flex: 1 0 0%`};
`;

const AccountContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const AccountTitle = styled.span`
  ${({ theme }: { theme: Font }) => fontStyleOverride(theme)};
  padding-right: 20px;
  letter-spacing: 1px;
`;

const LogoMenuContainer = styled.div`
  display: flex;
  margin: 0 auto;
  ${({ contentWidth }) =>
    `width: ${contentWidth ? `${contentWidth}px` : '100%'}`};
`;

const SectionWithLogoContainer = styled(Section)`
  flex-direction: ${({ logoOnRight }) => (logoOnRight ? 'row-reverse' : 'row')};
  z-index: 80;
`;

const ColumnRight = styled.div`
  width: 100%;
`;

const colourValue = colour => colour && colour.value;
const fontColourValueOrDefault = (font: Font) =>
  (font && colourValue(font.colour)) || '#FFF';
const textTitle = (text: { value: string, disabled: boolean }) =>
  text && !text.disabled && text.value;

const basicTheme = (font: Font) => ({
  fontFamily: font.typeface && font.typeface.label,
  fontSize: `${font.fontSize}px`,
  characterSpacing: `${font.kerning}px`,
  textTransform: font.transform,
});

// each option knows which config key tells it to show/hide
// this filters out hidden options
const filterUtilityBarOptions = ({ utilityBarOptions, ...config }) =>
  utilityBarOptions
    .filter(o => config[o.enabledKey])
    .map(options => ({
      ...options,
      options: [
        options.includePlaceholderOption && {
          value: '',
          label: config.translations[`${options.key.toLowerCase()}Placeholder`],
        },
        ...options.options,
      ].filter(Boolean),
      prefixLabel:
        config.translations[`${options.key.toLowerCase()}PrefixLabel`],
      placeholder:
        config.translations[`${options.key.toLowerCase()}Placeholder`],
    }));

const HeaderContainer = styled.div`
  position: ${({ stickyHeader }) => (stickyHeader ? 'fixed' : 'static')};
  width: 100%;
  z-index: 10;
  ${({ opacity, totalHeaderHeight, backgroundColour }) =>
    opacity &&
    `
    &:after {
      content: '';
      width: 100%;
      height: ${totalHeaderHeight}px;
      background-color: ${backgroundColour};
      opacity: ${opacity};
      position: absolute;
      top: 0;
      left: 0;
      z-index: 8;
    }
  `}
  ${media.max.large`display: none;`};
`;

const HeaderContainerMedium = styled(HeaderContainer)`
  display: none;
  ${media.max.large`display: flex;`};
  position: ${({ stickyHeader }) => (stickyHeader ? 'fixed' : 'static')};
  width: 100%;
  z-index: 10;
`;

const SocialShare = ({ config, socialShare }: Props) =>
  config.socialShareTitle && !config.socialShareTitle.disabled ? (
    <SocialShareButton config={config} socialShare={socialShare} />
  ) : null;

export default function Header(props: Props) {
  const {
    config,
    preview,
    navigateIfInternal,
    pathByModule,
    dispatch,
    shared,
    globalStyling,
    marketInfo: { locale },
    socialShare,
  } = props;
  const currentLanguage = shared.sessionPreferences.language || locale;
  const currentCurrency = shared.sessionPreferences.currency;
  const currentMarket = shared.sessionPreferences.market;

  const utilityBarActions = {
    language: option => {
      dispatch(language.actions.changeLanguage(option.value));
      dispatch(
        sessionActions.updateSessionPreferences('language', option.value),
      );
    },
    currency: option => {
      dispatch(
        sessionActions.updateSessionPreferences('currency', option.value),
      );
      dispatch(currencyActions.getExchangeRates(option.value));
      dispatch(filterActions.resetPriceSliderValues());
    },
    countries: option => {
      dispatch(sessionActions.updateSessionPreferences('market', option.value));
    },
    territory: option => dispatch(territory.actions.changeTerritory(option)),
  };

  const utilityBarInitialValues = {
    language: currentLanguage,
    currency: currentCurrency,
    countries: currentMarket,
  };

  const disableCurrencyPopup = () =>
    dispatch(
      sessionActions.updateSessionPreferences('disableCurrencyPopup', true),
    );

  const fnIfNotPreview = fn => (preview ? () => {} : fn);
  const onSearch = fnIfNotPreview(value =>
    dispatch(
      routerActions.navigate(`${pathByModule('SiteSearch')}?query=${value}`),
    ),
  );

  const pageBackgroundColour =
    globalStyling.colours.pageBackgroundColour &&
    globalStyling.colours.pageBackgroundColour.value;
  const sectionBackgroundColour = colour => colour || pageBackgroundColour;

  const globalSearchTextColour =
    globalStyling.colours.secondaryFontColour &&
    globalStyling.colours.secondaryFontColour.value;
  const searchTextColour = colour => colour || globalSearchTextColour;

  const SearchBarTheme = {
    ...basicTheme(
      config.searchTextFont || {
        typeface: {
          ...globalStyling.fonts.secondaryFont,
        },
        kerning: 0,
        fontSize: 14,
        transform: 'uppercase',
      },
    ),
    colour: searchTextColour(
      colourValue(config.searchTextFont && config.searchTextFont.colour),
    ),
    backgroundColour: config.opacity
      ? 'transparent'
      : sectionBackgroundColour(colourValue(config.searchBGColour)),
    iconColour: searchTextColour(colourValue(config.searchIconColour)),
    showSearchInputBorder: config.showSearchInputBorder,
  };

  const noUser = {
    firstName: '',
    lastName: '',
  };

  const { firstName, lastName } = preview
    ? noUser
    : getCustomerDetails(shared) || noUser;
  const accountTitle = firstName
    ? { value: `${firstName} ${lastName}`, disabled: false }
    : {
        ...config.actionsBarsAccountTitle,
        value: config.translations.actionsBarsAccountTitle,
      };
  const onSignInClick = fnIfNotPreview(() =>
    dispatch(
      routerActions.navigate(pathByModule(firstName ? 'MyAccount' : 'SignIn')),
    ),
  );

  const navigateOnClick = fnIfNotPreview(() =>
    dispatch(routerActions.navigate(config.brandLogoLink.value)),
  );

  const menuBarItems = (config.menuBarItems || { items: [] }).items.map(
    (value, idx) => ({
      ...value,
      label: config.translations[`menuBarItems-${idx}`],
    }),
  );

  const utilityBarItems = (config.utilityBarItems || { items: [] }).items.map(
    (value, idx) => ({
      ...value,
      label: config.translations[`utilityBarItems-${idx}`],
    }),
  );

  const filteredOptions = filterUtilityBarOptions(config);
  const actionsBarHeightCalc = configuration => {
    let height = configuration.menuBarHeight;
    // Add 1 pixel when border exists
    if (config.menuBarBorderColour) height += 1;
    if (configuration.showActionsBar) height += configuration.actionsBarHeight;
    return height;
  };
  const totalHeaderHeight =
    actionsBarHeightCalc(config) + config.utilityBarHeight;

  const onChangeLanguage = languageCode => {
    dispatch(sessionActions.updateSessionPreferences('language', languageCode));
    dispatch(language.actions.changeLanguage(languageCode));
  };

  switch (config.headerTemplate) {
    case 'TemplateTwo':
      return (
        <TemplateTwo
          {...props}
          links={menuBarItems}
          navigate={path => dispatch(routerActions.navigate(path))}
          currentLanguage={currentLanguage}
          currentCurrency={currentCurrency}
          utilityBarActions={{
            ...utilityBarActions,
            disableCurrencyPopup,
          }}
          globalStyling={globalStyling}
        />
      );
    case 'TemplateThree':
      return (
        <TemplateThree
          {...props}
          globalStyling={globalStyling}
          links={menuBarItems}
          utilityBarInitialValues={utilityBarInitialValues}
          filteredOptions={filteredOptions}
          navigateIfInternal={navigateIfInternal}
          navigateOnClick={navigateOnClick}
          utilityBarActions={utilityBarActions}
          socialShare={socialShare}
          navigate={path => dispatch(routerActions.navigate(path))}
          currentLanguage={currentLanguage}
          currentCurrency={currentCurrency}
          changeLanguage={onChangeLanguage}
          disableCurrencyPopup={disableCurrencyPopup}
          shared={shared}
        />
      );
    default:
      return (
        <div
          data-nosnippet
          style={
            config.menuBarBGColour && {
              backgroundColor: config.menuBarBGColour.value,
            }
          }
        >
          <CurrencyPopup
            translations={config.translations}
            globalStyling={globalStyling}
            disableCurrencyPopup={disableCurrencyPopup}
            shared={shared}
          />
          <HeaderContainer
            stickyHeader={config.stickyHeader}
            opacity={config.opacity}
            totalHeaderHeight={totalHeaderHeight}
            backgroundColour={sectionBackgroundColour(
              colourValue(config.utilityBarBGColour),
            )}
            globalStyling={globalStyling}
          >
            <UtilitySection
              height={config.utilityBarHeight}
              backgroundColour={
                config.opacity < 1 ? 'transparent' : config.utilityBarBGColour
              }
              borderBottomHeight={config.utilityBarBorderBottomHeight}
              borderBottomColour={config.utilityBarBorderBottomColour}
              globalStyling={globalStyling}
            >
              <ContentWrapper contentWidth={globalStyling.contentWidth}>
                <Section
                  backgroundColour={
                    config.opacity
                      ? ''
                      : sectionBackgroundColour(
                          colourValue(config.utilityBarBGColour),
                        )
                  }
                  align={config.utilityBarAlign}
                  zIndex={(config.opacity && 85) || 84}
                >
                  <UtilityBar
                    config={config}
                    theme={{
                      link: {
                        font: config.utilityBarLinkFont,
                        hoverColour: config.utilityBarDropdownHoverColour,
                      },
                      dropdown: {
                        font: config.utilityBarDropdownFont,
                        hoverColour: config.utilityBarLinkHoverColour,
                        backgroundColour: config.utilityBarDropdownColour,
                        dropdownIconSize: config.languageDropdownIconSize,
                        iconColour: config.dropdownIconColour,
                      },
                    }}
                    items={utilityBarItems}
                    utilityBarOptions={filteredOptions}
                    preview={preview}
                    dropDownActions={utilityBarActions}
                    initialValues={utilityBarInitialValues}
                    socialShare={socialShare}
                    direction={globalStyling.direction}
                  />
                </Section>
              </ContentWrapper>
            </UtilitySection>
            <ContentWrapper
              contentWidth={
                !config.menuWrapperFull && globalStyling.contentWidth
              }
            >
              <SectionWithLogoContainer
                backgroundColour={
                  config.opacity
                    ? ''
                    : sectionBackgroundColour(
                        colourValue(config.brandLogoBGColour),
                      )
                }
                logoOnRight={config.logoAlign === 'right'}
                borderBottom={`${
                  config.menuBarBorderBottomHeight
                }px solid ${colourValue(config.menuBarBorderBottomColour)}`}
              >
                <LogoMenuContainer contentWidth={globalStyling.contentWidth}>
                  <LogoContainer
                    borderColour={colourValue(config.brandLogoBorderColour)}
                    backgroundColour={
                      config.opacity
                        ? ''
                        : sectionBackgroundColour(
                            colourValue(config.brandLogoBGColour),
                          )
                    }
                    padding={`0 ${config.brandLogoHorizontalPadding}px`}
                  >
                    <BrandLogo
                      logoUrl={config.brandLogoUrl}
                      fallbackUrl={config.fallbackUrl}
                      brandLogoVisibleDesktop={config.brandLogoVisibleDesktop}
                      preview={preview}
                      navigateOnClick={navigateOnClick}
                    />
                  </LogoContainer>
                  <ColumnRight>
                    <ActionsContainer
                      calculatedHeight={actionsBarHeightCalc(config)}
                    >
                      {config.showActionsBar && (
                        <SectionWithPageContainer
                          height={config.actionsBarHeight}
                          backgroundColour={
                            config.opacity
                              ? ''
                              : sectionBackgroundColour(
                                  colourValue(config.actionsBarBGColour),
                                )
                          }
                          borderBottom={`${
                            config.actionsBarBorderBottomHeight
                          }px solid ${colourValue(
                            config.actionsBarBorderBottomColour,
                          )}`}
                          direction={globalStyling.direction}
                        >
                          <HeadingOne
                            styleOverride={() => `
                              display: flex;
                              ${fontStyleOverride(config.actionsBarPageFont)}
                            `}
                            mobileStyleOverride={() => [
                              {
                                queryPath: 'max.small',
                                template: 'display:none;',
                              },
                              {
                                queryPath: 'max.medium',
                                template: 'justify-content: center;',
                              },
                            ]}
                          >
                            {textTitle({
                              ...config.actionsBarPageTitle,
                              value: config.translations.actionsBarPageTitle,
                            })}
                          </HeadingOne>
                          <Right>
                            <SocialShare
                              config={props.config}
                              socialShare={props.socialShare}
                              {...props}
                            />
                            {!config.actionsBarsAccountTitle.disabled && (
                              <AccountContainer onClick={onSignInClick}>
                                <User
                                  width={`${config.accountIconWidth}px`}
                                  height={`${config.accountIconHeight}px`}
                                  colour={fontColourValueOrDefault(
                                    config.actionsBarAccountFont,
                                  )}
                                />
                                <AccountTitle
                                  theme={config.actionsBarAccountFont}
                                >
                                  {textTitle(accountTitle)}
                                </AccountTitle>
                              </AccountContainer>
                            )}
                          </Right>
                        </SectionWithPageContainer>
                      )}
                      <MenuContainer
                        backgroundColour={
                          config.opacity
                            ? ''
                            : sectionBackgroundColour(
                                colourValue(config.actionsBarBGColour),
                              )
                        }
                      >
                        <MenuBar
                          height={config.menuBarHeight}
                          theme={{
                            font: config.menuBarLinkFont,
                            hoverColour: config.menuBarLinkHoverColour,
                            underLineColor: config.menuBarUnderlineColor,
                            backgroundHoverColour:
                              config.menuBarLinkBgHoverColour,
                          }}
                          items={menuBarItems}
                          preview={preview}
                          backgroundColour={
                            config.opacity
                              ? ''
                              : colourValue(config.menuBarBGColour)
                          }
                          borderColour={colourValue(config.menuBarBorderColour)}
                          navigateIfInternal={navigateIfInternal}
                          withSearch={config.showSearchBar}
                          onSearch={onSearch}
                          placeholder={config.translations.searchBarPlaceholder}
                          searchBarTheme={SearchBarTheme}
                          searchBarAlign={config.searchBarAlign}
                          searchIconAlign={config.searchIconAlign}
                          mobileMenuIconsColour={colourValue(
                            config.mobileMenuIconsColour,
                          )}
                        />
                      </MenuContainer>
                    </ActionsContainer>
                  </ColumnRight>
                </LogoMenuContainer>
              </SectionWithLogoContainer>
            </ContentWrapper>
          </HeaderContainer>
          <HeaderContainerMedium stickyHeader={config.stickyHeader}>
            <SectionWithLogoContainer
              backgroundColour={sectionBackgroundColour(
                colourValue(config.menuBarBGColour),
              )}
              logoOnRight={config.logoAlign === 'right'}
              borderBottom={`${
                config.menuBarBorderBottomHeight
              }px solid ${colourValue(config.menuBarBorderBottomColour)}`}
            >
              <LogoContainer
                borderColour={colourValue(config.brandLogoMobileBorderColour)}
                backgroundColour={sectionBackgroundColour(
                  colourValue(config.brandLogoBGColour),
                )}
              >
                <BrandLogo
                  pageLink={config.brandLogoLink.value}
                  logoUrl={config.brandLogoUrl}
                  brandLogoVisibleDesktop={config.brandLogoVisibleDesktop}
                  preview={preview}
                  navigateOnClick={navigateOnClick}
                />
              </LogoContainer>
              <ActionsContainer>
                <HeadingOne
                  styleOverride={() => `
                    display: flex;
                    ${fontStyleOverride(config.actionsBarPageFont)}
                  `}
                  mobileStyleOverride={() => [
                    { queryPath: 'max.small', template: 'display:none;' },
                    {
                      queryPath: 'max.medium',
                      template: 'justify-content: center;',
                    },
                  ]}
                >
                  {textTitle({
                    ...config.actionsBarPageTitle,
                    value: config.translations.actionsBarPageTitle,
                  })}
                </HeadingOne>
                <Right>
                  <SocialShare
                    config={props.config}
                    socialShare={props.socialShare}
                    {...props}
                  />
                  {filteredOptions.length > 0 && (
                    <LocationSettings
                      title={config.translations.locationSettingsTitle}
                      confirm={config.translations.confirm}
                      iconColour={fontColourValueOrDefault(
                        config.actionsBarAccountFont,
                      )}
                      locationOptions={filteredOptions}
                      actions={utilityBarActions}
                      initialValues={utilityBarInitialValues}
                      globalStyling={globalStyling}
                    />
                  )}
                  {!config.actionsBarsAccountTitle.disabled && (
                    <div role="button" tabIndex={0} onClick={onSignInClick}>
                      <User
                        width={`${config.mobileAccountIconWidth}px`}
                        height={`${config.mobileAccountIconHeight}px`}
                        colour={fontColourValueOrDefault(
                          config.actionsBarAccountFont,
                        )}
                      />
                    </div>
                  )}
                  <MenuBar
                    theme={{
                      font: config.menuBarLinkFont,
                      hoverColour: config.menuBarLinkHoverColour,
                      backgroundHoverColour: config.menuBarLinkBgHoverColour,
                      underLineColor: config.menuBarUnderlineColor,
                    }}
                    items={menuBarItems}
                    preview={preview}
                    backgroundColour={sectionBackgroundColour(
                      colourValue(config.menuBarBGColour),
                    )}
                    borderColour={colourValue(config.menuBarMobileBorderColour)}
                    navigateIfInternal={navigateIfInternal}
                    withSearch={config.showSearchBar}
                    onSearch={onSearch}
                    searchBarTheme={SearchBarTheme}
                    searchBarAlign={config.searchBarAlign}
                    searchIconAlign={config.searchIconAlign}
                    mobileMenuIconsColour={colourValue(
                      config.mobileMenuIconsColour,
                    )}
                    burgerMenuItemBorder={config.burgerMenuItemBorder}
                    socialShareEnabled={config.socialShareEnabled}
                    utilityBarLanguageEnabled={
                      config.utilityBarLanguageEnabled &&
                      !config.mobileLanguageInHeader
                    }
                    utilityBarCurrencyEnabled={
                      config.utilityBarCurrencyEnabled &&
                      !config.mobileLanguageInHeader
                    }
                    socialIcon={config.socialIcon}
                    languageIcon={config.languageIcon}
                    currencyIcon={config.currencyIcon}
                    translations={config.translations}
                    socialShareProps={socialShare}
                    utilityBarOptions={filteredOptions}
                    languageActions={utilityBarActions.language}
                    burgerMenuLanguageFont={config.burgerMenuLanguageFont}
                    languageChevron={config.burgerMenuLanguageChevron}
                    hideChevronOnMobile={config.hideChevronOnMobile}
                    currentLanguage={currentLanguage}
                    currentCurrency={currentCurrency}
                  />
                </Right>
              </ActionsContainer>
            </SectionWithLogoContainer>
          </HeaderContainerMedium>
        </div>
      );
  }
}
