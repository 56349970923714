/* eslint-disable import/no-unresolved, import/extensions  */
import { combineReducers } from 'redux';
import { shared, bands, pods } from 'cms-modules';

import routerReducer from './reducers/router';
import configReducer from './reducers/config';
import pagesReducer from './reducers/pages';
import vehicleInContextReducer from './reducers/vehicleInContext';
import indexedPagesReducer from './reducers/indexedPages';
import flagsReducer from './reducers/flags';

// Map over 'shared' state by name
const sharedReducers = Object.keys(shared).reduce(
  (rs, key) => ({ ...rs, [key]: shared[key].reducer }),
  {},
);

export default combineReducers({
  router: routerReducer,
  config: configReducer,
  pages: pagesReducer,
  indexedPages: indexedPagesReducer,
  vehicleInContext: vehicleInContextReducer,
  shared: combineReducers(sharedReducers),
  flags: flagsReducer,
  dataProvider: bands.DataProvider.reducer,
  modelSearch: pods.podReducers.modelSearchReducer.reducer,
  featuredVehicles: pods.podReducers.featuredVehicleReducer.reducer,
  dealerLocator: pods.podReducers.dealerLocatorReducer.reducer,
});
