// @flow
/* eslint-disable react/prop-types */
/* eslint-env browser */
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import pathOr from 'ramda/src/pathOr';

import { InputField, SelectField, TextAreaField } from './FormFields';
import PersonalDataConsent from './PersonalDataConsent';
import RRStyle1 from './DataConsent/RRStyle1';
import JLRMarketingAndDistributionConsent from './DataConsent/JLRMarketingAndDistributionConsent';
import JLRKoreaSpecificConsent from './DataConsent/JLRKoreaSpecificConsent';
import ConfirmationWindow from './ConfirmationWindow';
import { Button } from './Global';
import media from '../theme';
import type { Font, Option } from '../types';
import { type SubmissionStatus } from '../modules/Enquiry/reducer';
import { required, validEmail } from '../helpers/validation';
import { titleOptions } from '../helpers/formTitleOptions';
import { countryOptions } from '../helpers/countryOptions';
import { preferredTimeOptions } from '../helpers/preferredTimeOptions';
import DatePickerWrapper from './DatePickerWrapper';
import { localiseCurrency } from '../shared/localisation/numbers';
import LabelledCheckBox from './LabelledCheckBox';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const EnquiryFormContainer = styled.div`
  ${media.max.large`
  ${({ width }) => `width: ${width ? `${width}px` : '90%'}`};
    padding: 0 5%;
  `};
  width: 40%;
  ${({ width }) => `width: ${width ? `${width}px` : '40%'}`};
  height: auto;
  margin: 40px 0;
`;

const DoubleRow = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.max.large`
    display: none;
  `};
`;

const MobileSingleRow = styled.div`
  display: none;
  ${media.max.large`
    display: block;
  `};
`;

const LineBreak = styled.div`
  border-bottom: 1px solid #dedede;
  margin-bottom: 30px;
`;

const LineBreakExtended = styled(LineBreak)`
  margin: 10px 0 20px 0;
`;

const ActionsContainer = styled.div`
  ${media.max.large`
    display: block;
  `};
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ActionButtonWrapper = styled.div`
  ${media.max.large`
    padding: 10px 0;
  `};
`;

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #9e1b32;
  margin-top: 10px;
  ${media.max.large`
    justify-content: center;
  `};
`;

const EnquiryFormDatePickerWrapper = styled.div`
  width: 48%;
  ${media.max.large`
    width: 100%;
    margin-bottom: 20px;
  `};
`;

const DatePickerLabel = styled.label`
  text-align: left;
  margin-bottom: 10px;
  display: block;
`;

const BookingArrangementText = styled.p`
  text-align: center;
  font-size: 12px;
`;

type Props = {
  preview: boolean,
  vehicle: Object,
  history: Object,
  translations: Object,
  error: string,
  submitMessage: (form: Object) => void,
  submissionStatus: SubmissionStatus,
  locale: string,
  enquiryFormGoBack: () => void,
  userDetails: Object,
  backgroundImage: string,
  globalStyling: Object,
  defaultHeaderFont: Font,
  defaultHeaderBackgroundColour: Option,
  largeHeaderFont: Font,
  largeHeaderBackgroundColour: Option,
  enquiryTypeDropdownVisible: boolean,
  showPostCodeField: boolean,
  showAddressFields: boolean,
  showBookingFields: boolean,
  useTimeOfDay: boolean,
  showVehicleOfInterestSingle: boolean,
  showVehicleOfInterest: boolean,
  showDualCountyPostCodeRow: boolean,
  showAddressFields: boolean,
  rrVehicleOfInterest: boolean,
  showAddressRows: boolean,
  toggleEnquiryCalendar: () => void,
  date: Date,
  selected: Date,
  calendarStyles: Object,
  enquiryTypes: Object,
  marketingLink: string,
  showUserCommunication?: boolean,
  formWidth: number,
  dataConsentFont: Font,
  showRRCustomDataConsentSection: boolean,
  showJLRMarketingAndDistributionConsent: boolean,
  showJLRKoreaSpecificConsent: boolean,
  showTradeInFields: boolean,
  enquiryFormStyles: Object,
  checkboxLabelStyles: Object,
};

type State = {
  enquiryType: string,
  title: Object,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  postCode: string,
  vehicleOfInterest: string,
  comments: string,
  phoneConsent: boolean,
  emailConsent: boolean,
  smsConsent: boolean,
  calendarFocused: boolean,
  selectedDate: Date,
  preferredTime: Object,
  errors: Object,
  enquiryTypeDropdownVisible: boolean,
  enquiryTypes: Object,
  address1: string,
  address2: string,
  city: string,
  county: string,
  country: Object,
  hideYear: boolean,
  marketingConsent: boolean,
  distributionListConsent: boolean,
  consentToAll: null | string,
  dataCollectionConsent: string,
  promotionConsent: string,
  thirdPartyConsent: string,
  personalInfoHandling: string,
};

export default class EnquiryForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      title: {
        label: props.userDetails.title || props.translations.formTitleSelect,
        value: props.userDetails.title || '',
      },
      preferredTime: {
        label: props.translations.formTitleSelect,
        value: props.translations.formPreferredTimeEmpty || '',
      },
      enquiryType:
        props.enquiryTypeLabel && props.enquiryTypeValue
          ? {
              label: props.enquiryTypeLabel,
              value: props.enquiryTypeValue,
            }
          : {
              label:
                props.translations.initialEnquiryTypeLabel ||
                props.translations.generalEnquiry ||
                'General Enquiry',
              value:
                (props.enquiryTypes && props.enquiryTypes.default) ||
                'general_enquiry',
            },
      firstName: props.userDetails.firstName,
      lastName: props.userDetails.lastName,
      email: props.userDetails.email,
      phone: props.userDetails.phoneNumber,
      postCode: '',
      comments: '',
      phoneConsent: false,
      emailConsent: false,
      smsConsent: false,
      marketingConsent: '',
      customisedServicesConsent: '',
      errors: {},
      address1: '',
      address2: '',
      city: '',
      county: '',
      country: {
        label:
          props.userDetails.country || props.translations.formCountrySelect,
        value: props.userDetails.country || '',
      },
      tradeInVisible: false,
      vehicleBrand: '',
      vehicleModel: '',
      vehicleVersion: '',
      selectedDate: null,
      // Korea Specific consents
      consentToAll: null,
      dataCollectionConsent: '0',
      promotionConsent: '0',
      thirdPartyConsent: '0',
      personalInfoHandling: '0',
    };
  }

  onSelectChange = ({
    keyValue,
    value,
    label,
  }: {
    keyValue: string,
    value: any,
    label: string,
  }) => {
    const error = this.validate(
      keyValue,
      { value, label },
      this.props.translations,
      this.props.applyRRValidations,
      this.props.onDemandValidations,
    );
    this.setState(prevState => ({
      [keyValue]: { label, value },
      errors: {
        ...prevState.errors,
        ...error,
      },
    }));
  };

  preferenceToBinary = value => (value ? '1' : '0');

  onInputChange = (formKey: string, value: string | boolean) => {
    const error = this.validate(
      formKey,
      value,
      this.props.translations,
      this.props.applyRRValidations,
      this.props.onDemandValidations,
    );

    this.setState(prevState => ({
      [formKey]: value,
      errors: {
        ...prevState.errors,
        ...error,
      },
    }));
  };

  onRadioSelect = event => {
    const {
      target: { name, value },
    } = event;
    const error = this.validate(name, value);
    this.setState(prevState => ({
      [name]: value,
      errors: {
        ...prevState.errors,
        ...error,
      },
    }));
  };

  onRadioSelectMultiple = (event: Event) => {
    const {
      target: { value },
    } = event;
    this.setState(() => ({
      dataCollectionConsent: value,
      promotionConsent: value,
      thirdPartyConsent: value,
      personalInfoHandling: value,
      consentToAll: value,
    }));
  };

  onSubmitMessage = () => {
    let errors = Object.entries(this.state)
      .map(entry =>
        this.validate(
          entry[0],
          entry[1],
          this.props.translations,
          this.props.applyRRValidations,
          this.props.showJLRKoreaSpecificConsent,
          this.props.onDemandValidations,
        ),
      )
      .filter(entry => entry !== undefined)
      .reduce((acc, currVal) => ({ ...acc, ...currVal }), {});

    if (this.props.applyRRValidations) {
      const { phoneConsent, emailConsent, marketingConsent } = this.state;
      if (marketingConsent === 'yes' && !phoneConsent && !emailConsent) {
        errors = {
          ...errors,
          checkBoxError: this.props.translations.formValidationRequired,
        };
      }
    }

    if (this.props.showJLRKoreaSpecificConsent) {
      const {
        dataCollectionConsent,
        promotionConsent,
        thirdPartyConsent,
        personalInfoHandling,
      } = this.state;
      if (
        dataCollectionConsent === '0' ||
        promotionConsent === '0' ||
        thirdPartyConsent === '0' ||
        personalInfoHandling === '0'
      ) {
        errors = {
          ...errors,
          dataCollectionConsent: this.props.translations.serviceWarning,
          promotionConsent: this.props.translations.serviceWarning,
          thirdPartyConsent: this.props.translations.serviceWarning,
          personalInfoHandling: this.props.translations.serviceWarning,
        };
      }
    }

    if (Object.values(errors).filter(error => error !== undefined).length > 0) {
      window.scroll(0, 0);
      this.setState(prevState => ({
        errors: { ...prevState.errors, ...errors },
      }));
      return;
    }

    const removeconsents = ({
      smsConsent,
      emailConsent,
      phoneConsent,
      marketingConsent,
      customisedServicesConsent,
      ...rest
    }) => rest;

    const leadData =
      !this.props.showUserCommunication &&
      !this.props.showRRCustomDataConsentSection
        ? removeconsents(this.state)
        : this.state;

    this.props.submitMessage({
      ...{
        ...this.props.vehicle,
        ...leadData,
        title: this.state.title.value,
        preferredTime: this.state.preferredTime.value,
        enquiryType: this.state.enquiryType.value,
        country: this.state.country.value,
        selectedDate: this.props.showBookingFields
          ? this.state.selectedDate
          : null,
        consents: {
          ...(this.props.showJLRMarketingAndDistributionConsent
            ? {
                'Distribution list consent': this.preferenceToBinary(
                  this.state.distributionListConsent,
                ),
                'Marketing consent': this.preferenceToBinary(
                  this.state.marketingConsent,
                ),
              }
            : {}),
          ...(this.props.showJLRKoreaSpecificConsent
            ? {
                [this.props.translations.dataCollectionConsent]: this.state
                  .dataCollectionConsent,
                [this.props.translations.marketingConsent]: this.state
                  .promotionConsent,
                [this.props.translations.thirdPartyProvision]: this.state
                  .thirdPartyConsent,
                [this.props.translations.personalInfoHandling]: this.state
                  .personalInfoHandling,
              }
            : {}),
          ...(!this.props.showUserCommunication &&
          !this.props.showRRCustomDataConsentSection
            ? {
                'Customised Services': this.preferenceToBinary(
                  this.state.customisedServicesConsent,
                ),
                Marketing: this.preferenceToBinary(this.state.marketingConsent),
              }
            : {}),
        },
      },
    });
  };

  formatVehicleOfInterest = (vehicle, keys) =>
    pathOr('', keys, vehicle) !== '' ? `${pathOr('', keys, vehicle)} | ` : '';

  validate = (formKey: string, value: any) => {
    const {
      applyRRValidations,
      onDemandValidations,
      translations,
      showUserCommunication,
      showJLRMarketingAndDistributionConsent,
    } = this.props;
    const excludeRRKeys = ['address1', 'city', 'county', 'country', 'postCode'];
    const excludeRRWhenOnDemandKeys = ['city', 'county', 'country'];
    const excludeNewPrefKeys = [
      'marketingConsent',
      'customisedServicesConsent',
    ];
    const excludeLamboKeys = ['vehicleBrand', 'vehicleModel'];

    const nonValidatedKeys = [
      'enquiryType',
      'vehicleOfInterest',
      'phoneConsent',
      'smsConsent',
      'emailConsent',
      'errors',
      'calendarFocused',
      'selectedDate',
      'preferredTime',
      'address2',
      'tradeInVisible',
      'vehicleVersion',
      'distributionListConsent',
      'dataCollectionConsent',
      'promotionConsent',
      'thirdPartyConsent',
      'personalInfoHandling',
      'consentToAll',
    ];
    let actualValidationKeys = [...nonValidatedKeys];

    if (!applyRRValidations) {
      actualValidationKeys = onDemandValidations
        ? [...actualValidationKeys, ...excludeRRWhenOnDemandKeys]
        : [...actualValidationKeys, ...excludeRRKeys];
    }

    if (applyRRValidations) {
      actualValidationKeys = [
        ...actualValidationKeys,
        'customisedServicesConsent',
      ];
    }
    if (!this.state.tradeInVisible) {
      actualValidationKeys = [...actualValidationKeys, ...excludeLamboKeys];
    }

    if (showUserCommunication || showJLRMarketingAndDistributionConsent) {
      actualValidationKeys = [...actualValidationKeys, ...excludeNewPrefKeys];
    }
    if (actualValidationKeys.includes(formKey)) return undefined;

    let rollsRoyceValidations;
    if (applyRRValidations) {
      rollsRoyceValidations = {
        city: required,
        address1: required,
        county: required,
        country: required,
        postCode: required,
      };
    }
    let lamborghiniValidations;
    if (this.state.tradeInVisible) {
      lamborghiniValidations = {
        vehicleBrand: required,
        vehicleModel: required,
      };
    }

    let onDemandRequired;
    if (onDemandValidations) {
      onDemandRequired = {
        address1: required,
        postCode: required,
      };
    }

    const validations = {
      title: required,
      firstName: required,
      lastName: required,
      email: validEmail,
      phone: required,
      comments: required,
      marketingConsent: required,
      customisedServicesConsent: required,
    };
    let actualValidations = {
      ...validations,
    };
    if (applyRRValidations) {
      actualValidations = {
        ...actualValidations,
        ...rollsRoyceValidations,
      };
    }

    if (this.state.tradeInVisible) {
      actualValidations = {
        ...actualValidations,
        ...lamborghiniValidations,
      };
    }

    if (onDemandValidations) {
      actualValidations = {
        ...actualValidations,
        ...onDemandRequired,
      };
    }
    if (formKey === 'title' || (formKey === 'country' && applyRRValidations)) {
      return {
        [formKey]: actualValidations[formKey](value.value, translations),
      };
    }
    return { [formKey]: actualValidations[formKey](value, translations) };
  };

  toggleEnquiryCalendar = () => {
    this.setState(prevState => ({
      calendarFocused: !prevState.calendarFocused,
    }));
  };

  closeEnquiryCalendar = e => {
    if (!this.props.showBookingFields) return;
    if (!e.target.classList[0]) return;
    if (!e.target.classList[0].includes('DatePickerWrapper')) {
      this.setState({
        calendarFocused: false,
      });
    }
  };

  handleOnDateSelected = (date, selectedDate) => {
    if (date === 'date') {
      this.setState({
        selectedDate,
      });
    }
  };

  getVehicleOfInterest = () => {
    if (this.props.vehicle === null) {
      return '';
    }

    const vehicleOfInterest = this.props.rrVehicleOfInterest
      ? this.props.vehicle &&
        `${this.formatVehicleOfInterest(this.props.vehicle, ['name'])}` +
          `${this.formatVehicleOfInterest(this.props.vehicle, ['variant'])}` +
          `${
            this.props.vehicle.specification
              ? this.formatVehicleOfInterest(this.props.vehicle, [
                  'specification',
                  'exterior',
                ])
              : this.formatVehicleOfInterest(this.props.vehicle, ['exterior'])
          }` +
          `${
            this.props.vehicle.price.value !== 0
              ? `${localiseCurrency(
                  this.props.vehicle.price.value,
                  this.props.vehicle.locale,
                  this.props.vehicle.price.currency,
                  2,
                )} | `
              : ''
          }` +
          `${this.props.vehicle.vin}`
      : (this.props.vehicle &&
          (this.props.hideYear
            ? this.props.vehicle.description
            : `${this.props.vehicle.registration} ${
                this.props.vehicle.description
              }`)) ||
        '';

    return vehicleOfInterest;
  };

  render() {
    const {
      vehicle,
      history,
      translations,
      error,
      submissionStatus,
      preview,
      globalStyling,
      enquiryTypeDropdownVisible = true,
      showPostCodeField = false,
      showAddressFields = false,
      showVehicleOfInterest = false,
      showVehicleOfInterestSingle = true,
      showBookingFields = false,
      showDualCountyPostCodeRow = false,
      showAddressRows = false,
      useTimeOfDay = false,
      enquiryTypes,
      calendarStyles,
      marketingLink,
      showUserCommunication,
      formWidth,
      dataConsentFont,
      showRRCustomDataConsentSection,
      showJLRMarketingAndDistributionConsent,
      showJLRKoreaSpecificConsent,
      showTradeInFields,
      locale,
      enquiryFormStyles = {},
      checkboxLabelStyles,
    } = this.props;

    const firstSelectable = new Date();

    const {
      enquiryType,
      title,
      firstName,
      lastName,
      email,
      phone,
      postCode,
      comments,
      phoneConsent,
      emailConsent,
      smsConsent,
      preferredTime,
      address1,
      address2,
      county,
      city,
      country,
      marketingConsent,
      customisedServicesConsent,
      tradeInVisible,
      vehicleBrand,
      vehicleModel,
      vehicleVersion,
      distributionListConsent,

      dataCollectionConsent,
      promotionConsent,
      thirdPartyConsent,
      personalInfoHandling,
      consentToAll,
    } = this.state;

    const vehicleOfInterest = this.getVehicleOfInterest();

    const {
      generalEnquiryValue = 'general_enquiry',
      reserveValue,
      testDriveValue,
      financeValue,
      onDemandValue,
    } = enquiryTypes && enquiryTypes.options ? enquiryTypes.options : '';

    const {
      generalEnquiry: generalEnquiryLabel,
      reserve: reserveEnquiryLabel,
      testDrive: testDriveEnquiryLabel,
      finance: financeEnquiryLabel,
      onDemand: onDemandEnquiryLabel,
    } = translations;

    const enquiryTypeOptions = () => [
      {
        label: generalEnquiryLabel,
        value: generalEnquiryValue,
      },
      {
        label: reserveEnquiryLabel,
        value: reserveValue,
      },
      {
        label: testDriveEnquiryLabel,
        value: testDriveValue,
      },
      {
        label: financeEnquiryLabel,
        value: financeValue,
      },
      {
        label: onDemandEnquiryLabel,
        value: onDemandValue,
      },
    ];

    const { morning, afternoon, evening } = translations;

    const preferredTimeOptionsAll = () => {
      const label = this.props.translations.formTitleSelect;
      const timeOptions = useTimeOfDay
        ? [
            {
              label: morning,
              value: morning,
            },
            {
              label: afternoon,
              value: afternoon,
            },
            {
              label: evening,
              value: evening,
            },
          ]
        : preferredTimeOptions();
      return [
        {
          label,
          value: '',
        },
        ...timeOptions,
      ];
    };

    const enquiryTypesFiltered = () => {
      const options = enquiryTypeOptions();
      const filteredOptions = options.filter(
        option => option.label && option.value,
      );
      return filteredOptions;
    };

    const enquiryTypeLength = () => {
      const options = enquiryTypesFiltered();
      return options.length;
    };

    const hasError = field =>
      this.state.errors[field] && this.state.errors[field][0];
    const fnIfNotPreview = fn => (preview ? () => {} : fn);

    const showPersonalDataConsent =
      !showRRCustomDataConsentSection &&
      !showJLRMarketingAndDistributionConsent &&
      !showJLRKoreaSpecificConsent;

    return submissionStatus === 'Successful' ? (
      <Container>
        <ConfirmationWindow
          translations={translations}
          circleTickColour={
            globalStyling.colours.primaryBrandColour &&
            globalStyling.colours.primaryBrandColour.value
          }
          onButtonClick={fnIfNotPreview(history.goBack)}
        />
      </Container>
    ) : (
      <Container>
        <EnquiryFormContainer
          width={formWidth}
          globalStyling={globalStyling}
          onClick={this.closeEnquiryCalendar}
        >
          {(enquiryTypeDropdownVisible || enquiryTypeLength() > 1) && (
            <SelectField
              keyValue="enquiryType"
              value={enquiryType.value}
              onChange={fnIfNotPreview(this.onSelectChange)}
              label={translations.enquiryTypeLabel}
              options={enquiryTypeOptions()}
              disabled={enquiryTypeLength() === 1}
              enquiryTypes={enquiryTypes}
              doubleRow
              required
              globalStyling={globalStyling}
            />
          )}
          <SelectField
            type="text"
            keyValue="title"
            value={title.value}
            onChange={fnIfNotPreview(this.onSelectChange)}
            label={translations.titleLabel}
            options={titleOptions(translations)}
            error={hasError('title')}
            doubleRow
            required
            globalStyling={globalStyling}
          />
          <DoubleRow>
            <InputField
              type="text"
              label={translations.firstNameLabel}
              value={firstName}
              onChange={fnIfNotPreview(this.onInputChange)}
              keyValue="firstName"
              error={hasError('firstName')}
              doubleRow
              required
              globalStyling={globalStyling}
            />
            <InputField
              type="text"
              label={translations.lastNameLabel}
              value={lastName}
              onChange={fnIfNotPreview(this.onInputChange)}
              keyValue="lastName"
              error={hasError('lastName')}
              doubleRow
              required
              globalStyling={globalStyling}
            />
          </DoubleRow>
          <MobileSingleRow>
            <InputField
              type="text"
              label={translations.firstNameLabel}
              value={firstName}
              onChange={fnIfNotPreview(this.onInputChange)}
              keyValue="firstName"
              error={hasError('firstName')}
              required
              globalStyling={globalStyling}
            />
            <InputField
              type="text"
              label={translations.lastNameLabel}
              value={lastName}
              onChange={fnIfNotPreview(this.onInputChange)}
              keyValue="lastName"
              error={hasError('lastName')}
              required
              globalStyling={globalStyling}
            />
          </MobileSingleRow>
          {showAddressRows && (
            <DoubleRow>
              <InputField
                type="text"
                label={translations.address1Label}
                value={address1}
                onChange={fnIfNotPreview(this.onInputChange)}
                keyValue="address1"
                error={hasError('address1')}
                doubleRow
                required
                globalStyling={globalStyling}
              />
              <InputField
                type="text"
                label={translations.cityLabel}
                value={city}
                onChange={fnIfNotPreview(this.onInputChange)}
                keyValue="city"
                error={hasError('city')}
                doubleRow
                required
                globalStyling={globalStyling}
              />
            </DoubleRow>
          )}
          {showAddressRows && (
            <MobileSingleRow>
              <InputField
                type="text"
                label={translations.address1Label}
                value={address1}
                onChange={fnIfNotPreview(this.onInputChange)}
                keyValue="address1"
                error={hasError('address1')}
                doubleRow
                required
                globalStyling={globalStyling}
              />
              <InputField
                type="text"
                label={translations.cityLabel}
                value={city}
                onChange={fnIfNotPreview(this.onInputChange)}
                keyValue="city"
                error={hasError('city')}
                doubleRow
                required
                globalStyling={globalStyling}
              />
            </MobileSingleRow>
          )}
          {showDualCountyPostCodeRow && (
            <DoubleRow>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '48%',
                }}
              >
                <InputField
                  type="text"
                  label={translations.countyLabel}
                  value={county}
                  onChange={fnIfNotPreview(this.onInputChange)}
                  keyValue="county"
                  error={hasError('county')}
                  doubleRow
                  required
                  globalStyling={globalStyling}
                />
                <InputField
                  type="text"
                  label={translations.postCodeLabel}
                  value={postCode}
                  onChange={fnIfNotPreview(this.onInputChange)}
                  keyValue="postCode"
                  error={hasError('postCode')}
                  doubleRow
                  required
                  globalStyling={globalStyling}
                />
              </div>
              <SelectField
                type="text"
                keyValue="country"
                value={country.value}
                onChange={fnIfNotPreview(this.onSelectChange)}
                label={translations.countryLabel}
                options={countryOptions}
                error={hasError('country')}
                doubleRow
                required
                globalStyling={globalStyling}
              />
            </DoubleRow>
          )}
          {showDualCountyPostCodeRow && (
            <MobileSingleRow>
              <InputField
                type="text"
                label={translations.countyLabel}
                value={county}
                onChange={fnIfNotPreview(this.onInputChange)}
                keyValue="county"
                error={hasError('county')}
                doubleRow
                required
                globalStyling={globalStyling}
              />
              <InputField
                type="text"
                label={translations.postCodeLabel}
                value={postCode}
                onChange={fnIfNotPreview(this.onInputChange)}
                keyValue="postCode"
                error={hasError('postCode')}
                doubleRow
                required
                globalStyling={globalStyling}
              />
              <SelectField
                type="text"
                keyValue="country"
                value={country.value}
                onChange={fnIfNotPreview(this.onSelectChange)}
                label={translations.countryLabel}
                options={countryOptions}
                error={hasError('country')}
                doubleRow
                required
                globalStyling={globalStyling}
              />
            </MobileSingleRow>
          )}
          <DoubleRow>
            <InputField
              type="text"
              label={translations.emailLabel}
              value={email}
              onChange={fnIfNotPreview(this.onInputChange)}
              keyValue="email"
              error={hasError('email')}
              doubleRow
              required
              globalStyling={globalStyling}
            />
            <InputField
              type="text"
              label={translations.phoneLabel}
              value={phone}
              onChange={fnIfNotPreview(this.onInputChange)}
              keyValue="phone"
              error={hasError('phone')}
              doubleRow
              required
              globalStyling={globalStyling}
            />
          </DoubleRow>
          <MobileSingleRow>
            <InputField
              type="text"
              label={translations.emailLabel}
              value={email}
              onChange={fnIfNotPreview(this.onInputChange)}
              error={hasError('email')}
              keyValue="email"
              required
              globalStyling={globalStyling}
            />
            <InputField
              type="text"
              label={translations.phoneLabel}
              value={phone}
              onChange={fnIfNotPreview(this.onInputChange)}
              keyValue="phone"
              error={hasError('phone')}
              required
              globalStyling={globalStyling}
            />
          </MobileSingleRow>

          {showAddressFields && (
            <Fragment>
              <DoubleRow>
                <InputField
                  type="text"
                  label={translations.address1Label}
                  value={address1}
                  onChange={fnIfNotPreview(this.onInputChange)}
                  keyValue="address1"
                  doubleRow
                  required
                  globalStyling={globalStyling}
                  error={hasError('address1')}
                />
                <InputField
                  type="text"
                  label={translations.address2Label}
                  value={address2}
                  onChange={fnIfNotPreview(this.onInputChange)}
                  keyValue="address2"
                  doubleRow
                  globalStyling={globalStyling}
                />
              </DoubleRow>
              <DoubleRow>
                <InputField
                  type="text"
                  label={translations.postCode}
                  value={postCode}
                  onChange={fnIfNotPreview(this.onInputChange)}
                  keyValue="postCode"
                  doubleRow
                  globalStyling={globalStyling}
                  required
                  error={hasError('postCode')}
                />
                <InputField
                  type="text"
                  label={translations.cityLabel}
                  value={city}
                  onChange={fnIfNotPreview(this.onInputChange)}
                  keyValue="city"
                  error={hasError('city')}
                  doubleRow
                  globalStyling={globalStyling}
                />
              </DoubleRow>

              <MobileSingleRow>
                <InputField
                  type="text"
                  label={translations.address1Label}
                  value={address1}
                  onChange={fnIfNotPreview(this.onInputChange)}
                  keyValue="address1"
                  doubleRow
                  required
                  globalStyling={globalStyling}
                  error={hasError('address1')}
                />
                <InputField
                  type="text"
                  label={translations.address2Label}
                  value={address2}
                  onChange={fnIfNotPreview(this.onInputChange)}
                  keyValue="address2"
                  doubleRow
                  globalStyling={globalStyling}
                />
                <InputField
                  type="text"
                  label={translations.postCode}
                  value={postCode}
                  onChange={fnIfNotPreview(this.onInputChange)}
                  keyValue="postCode"
                  doubleRow
                  globalStyling={globalStyling}
                  required
                  error={hasError('postCode')}
                />
                <InputField
                  type="text"
                  label={translations.cityLabel}
                  value={city}
                  onChange={fnIfNotPreview(this.onInputChange)}
                  keyValue="city"
                  error={hasError('city')}
                  doubleRow
                  globalStyling={globalStyling}
                />
              </MobileSingleRow>
            </Fragment>
          )}

          {showPostCodeField && showVehicleOfInterest && (
            <Fragment>
              <DoubleRow>
                <InputField
                  type="text"
                  label={translations.postCode}
                  value={postCode}
                  onChange={fnIfNotPreview(this.onInputChange)}
                  keyValue="postCode"
                  doubleRow
                  globalStyling={globalStyling}
                  required
                  error={hasError('postCode')}
                />
                <InputField
                  type="text"
                  label={translations.vehicleOfInterestLabel}
                  value={vehicleOfInterest}
                  onChange={fnIfNotPreview(this.onInputChange)}
                  keyValue="vehicleOfInterest"
                  disabled={!!vehicle}
                  doubleRow
                  globalStyling={globalStyling}
                />
              </DoubleRow>
              <MobileSingleRow>
                <InputField
                  type="text"
                  label={translations.postCode}
                  value={postCode}
                  onChange={fnIfNotPreview(this.onInputChange)}
                  keyValue="postCode"
                  doubleRow
                  globalStyling={globalStyling}
                  required
                  error={hasError('postCode')}
                />
                <InputField
                  type="text"
                  label={translations.vehicleOfInterestLabel}
                  value={vehicleOfInterest}
                  onChange={fnIfNotPreview(this.onInputChange)}
                  keyValue="vehicleOfInterest"
                  disabled={!!vehicle}
                  doubleRow
                  globalStyling={globalStyling}
                />
              </MobileSingleRow>
            </Fragment>
          )}
          {showVehicleOfInterestSingle && (
            <InputField
              type="text"
              label={translations.vehicleOfInterestLabel}
              value={vehicleOfInterest}
              onChange={fnIfNotPreview(this.onInputChange)}
              keyValue="vehicleOfInterest"
              disabled={!!vehicle}
              truncateChars
              globalStyling={globalStyling}
            />
          )}
          {showBookingFields && (
            <Fragment>
              <LineBreakExtended />
              <DoubleRow>
                <EnquiryFormDatePickerWrapper>
                  <DatePickerLabel>
                    {translations.datePickerLabel}
                  </DatePickerLabel>
                  <DatePickerWrapper
                    open={this.state.calendarFocused}
                    date={this.state.selectedDate || firstSelectable}
                    selected={this.state.selectedDate}
                    value={this.state.selectedDate}
                    onSelectDate={this.handleOnDateSelected}
                    toggleCalendar={this.toggleEnquiryCalendar}
                    globalStyling={globalStyling}
                    doubleRow
                    calendarStyles={calendarStyles}
                    translations={translations}
                    locale={locale}
                  />
                </EnquiryFormDatePickerWrapper>
                <SelectField
                  type="text"
                  keyValue="preferredTime"
                  value={preferredTime.value}
                  onChange={fnIfNotPreview(this.onSelectChange)}
                  label={translations.preferredTimeLabel}
                  options={preferredTimeOptionsAll()}
                  doubleRow
                  calendarStyles={calendarStyles}
                  translations={translations}
                  globalStyling={globalStyling}
                />
              </DoubleRow>
              <MobileSingleRow>
                <EnquiryFormDatePickerWrapper>
                  <DatePickerLabel>
                    {translations.datePickerLabel}
                  </DatePickerLabel>
                  <DatePickerWrapper
                    open={this.state.calendarFocused}
                    date={this.state.selectedDate || firstSelectable}
                    selected={this.state.selectedDate}
                    value={this.state.selectedDate}
                    onSelectDate={this.handleOnDateSelected}
                    toggleCalendar={this.toggleEnquiryCalendar}
                    globalStyling={globalStyling}
                    doubleRow
                    calendarStyles={calendarStyles}
                    translations={translations}
                    locale={locale}
                    mobileWidth={90}
                  />
                </EnquiryFormDatePickerWrapper>
                <SelectField
                  type="text"
                  keyValue="preferredTime"
                  value={preferredTime.value}
                  onChange={fnIfNotPreview(this.onSelectChange)}
                  label={translations.preferredTimeLabel}
                  options={preferredTimeOptionsAll()}
                  doubleRow
                  calendarStyles={calendarStyles}
                  translations={translations}
                  globalStyling={globalStyling}
                />
              </MobileSingleRow>
              <BookingArrangementText>
                {translations.bookingArrangementText}
              </BookingArrangementText>
            </Fragment>
          )}
          <TextAreaField
            type="text"
            label={translations.commentsLabel}
            value={comments}
            onChange={fnIfNotPreview(this.onInputChange)}
            keyValue="comments"
            error={hasError('comments')}
            required
            globalStyling={globalStyling}
          />
          {showTradeInFields && (
            <Fragment>
              <DoubleRow style={{ marginBottom: '16px' }}>
                <LabelledCheckBox
                  onClick={() => {
                    this.setState(() => ({ tradeInVisible: !tradeInVisible }));
                  }}
                  checked={tradeInVisible}
                  label={translations.requestTradeInLabel}
                  onColour="#DFB400"
                  globalStyling={globalStyling}
                />
              </DoubleRow>
              {tradeInVisible && (
                <Fragment>
                  <DoubleRow>
                    <InputField
                      type="text"
                      label={translations.vehicleBrandLabel}
                      value={vehicleBrand}
                      onChange={fnIfNotPreview(this.onInputChange)}
                      keyValue="vehicleBrand"
                      error={hasError('vehicleBrand')}
                      doubleRow
                      required
                      globalStyling={globalStyling}
                    />
                    <InputField
                      type="text"
                      label={translations.vehicleModelLabel}
                      value={vehicleModel}
                      onChange={fnIfNotPreview(this.onInputChange)}
                      keyValue="vehicleModel"
                      error={hasError('vehicleModel')}
                      disabled={!vehicleBrand}
                      doubleRow
                      required
                      globalStyling={globalStyling}
                    />
                  </DoubleRow>
                  <DoubleRow>
                    <InputField
                      type="text"
                      label={translations.vehicleVersionLabel}
                      value={vehicleVersion}
                      onChange={fnIfNotPreview(this.onInputChange)}
                      keyValue="vehicleVersion"
                      disabled={!vehicleBrand}
                      doubleRow
                      globalStyling={globalStyling}
                    />
                  </DoubleRow>
                </Fragment>
              )}
            </Fragment>
          )}
          {showTradeInFields && (
            <div style={{ marginBottom: '16px' }}>
              <MobileSingleRow>
                <LabelledCheckBox
                  onClick={() => {
                    this.setState(() => ({ tradeInVisible: !tradeInVisible }));
                  }}
                  checked={tradeInVisible}
                  label={translations.requestTradeInLabel}
                  onColour="#DFB400"
                  globalStyling={globalStyling}
                />
              </MobileSingleRow>
              {tradeInVisible && (
                <Fragment>
                  <MobileSingleRow>
                    <InputField
                      type="text"
                      label={translations.vehicleBrandLabel}
                      value={vehicleBrand}
                      onChange={fnIfNotPreview(this.onInputChange)}
                      keyValue="vehicleBrand"
                      required
                      globalStyling={globalStyling}
                    />
                    <InputField
                      type="text"
                      label={translations.vehicleModelLabel}
                      value={vehicleModel}
                      onChange={fnIfNotPreview(this.onInputChange)}
                      keyValue="vehicleModel"
                      disabled={!vehicleBrand}
                      required
                      globalStyling={globalStyling}
                    />
                  </MobileSingleRow>
                  <MobileSingleRow>
                    <InputField
                      type="text"
                      label={translations.vehicleVersionLabel}
                      value={vehicleVersion}
                      onChange={fnIfNotPreview(this.onInputChange)}
                      keyValue="vehicleVersion"
                      disabled={!vehicleBrand}
                      globalStyling={globalStyling}
                    />
                  </MobileSingleRow>
                </Fragment>
              )}
            </div>
          )}
          <div
            style={{ fontSize: '13px', color: '#444444', marginBottom: '30px' }}
          >
            {translations.asteriskFormRules}
          </div>
          <LineBreak />
          {showRRCustomDataConsentSection && (
            <RRStyle1
              translations={translations}
              onCheckBoxClick={fnIfNotPreview(this.onInputChange)}
              onRadioSelect={this.onRadioSelect}
              marketing={marketingConsent}
              marketingKey="marketingConsent"
              marketingError={hasError('marketingConsent')}
              phoneChecked={phoneConsent}
              phoneKey="phoneConsent"
              emailChecked={emailConsent}
              emailKey="emailConsent"
              onColour={
                globalStyling.colours.primaryBrandColour &&
                globalStyling.colours.primaryBrandColour.value
              }
              linkColour={globalStyling.colours.primaryBrandColour}
              marketingLink={marketingLink}
              showUserCommunication={showUserCommunication}
              globalStyling={globalStyling}
              dataConsentFont={dataConsentFont}
              checkBoxError={hasError('checkBoxError')}
            />
          )}
          {showJLRMarketingAndDistributionConsent && (
            <JLRMarketingAndDistributionConsent
              translations={translations}
              onCheckBoxClick={fnIfNotPreview(this.onInputChange)}
              onRadioSelect={this.onRadioSelect}
              marketingChecked={marketingConsent}
              marketingKey="marketingConsent"
              distributionListConsentChecked={distributionListConsent}
              distributionListConsentKey="distributionListConsent"
              onColour={
                globalStyling.colours.primaryBrandColour &&
                globalStyling.colours.primaryBrandColour.value
              }
              linkColour={globalStyling.colours.primaryBrandColour}
              marketingLink={marketingLink}
              showUserCommunication={showUserCommunication}
              globalStyling={globalStyling}
              dataConsentFont={dataConsentFont}
              checkBoxError={hasError('checkBoxError')}
              labelFontSize={
                checkboxLabelStyles && checkboxLabelStyles.fontSize
              }
              enquiryFormStyles={enquiryFormStyles}
            />
          )}
          {showJLRKoreaSpecificConsent && (
            <JLRKoreaSpecificConsent
              translations={translations}
              onRadioSelect={this.onRadioSelect}
              onRadioSelectMultiple={this.onRadioSelectMultiple}
              consentToAll={consentToAll}
              consentToAllKey="consentToAll"
              dataCollectionConsent={dataCollectionConsent}
              dataCollectionConsentKey="dataCollectionConsent"
              dataCollectionConsentError={hasError('dataCollectionConsent')}
              promotionConsent={promotionConsent}
              promotionConsentKey="promotionConsent"
              promotionConsentError={hasError('promotionConsent')}
              thirdPartyConsent={thirdPartyConsent}
              thirdPartyConsentKey="thirdPartyConsent"
              thirdPartyConsentError={hasError('thirdPartyConsent')}
              personalInfoHandling={personalInfoHandling}
              personalInfoHandlingKey="personalInfoHandling"
              personalInfoHandlingError={hasError('personalInfoHandling')}
              onColour={
                globalStyling.colours.primaryBrandColour &&
                globalStyling.colours.primaryBrandColour.value
              }
              linkColour={globalStyling.colours.primaryBrandColour}
              marketingLink={marketingLink}
              showUserCommunication={showUserCommunication}
              globalStyling={globalStyling}
              labelFontSize={
                checkboxLabelStyles && checkboxLabelStyles.fontSize
              }
              enquiryFormStyles={enquiryFormStyles}
            />
          )}
          {showPersonalDataConsent && (
            <PersonalDataConsent
              translations={translations}
              onCheckBoxClick={fnIfNotPreview(this.onInputChange)}
              onRadioSelect={this.onRadioSelect}
              customisedServices={customisedServicesConsent}
              customisedServicesKey="customisedServicesConsent"
              customisedServicesError={hasError('customisedServicesConsent')}
              marketing={marketingConsent}
              marketingKey="marketingConsent"
              marketingError={hasError('marketingConsent')}
              phoneChecked={phoneConsent}
              phoneKey="phoneConsent"
              emailChecked={emailConsent}
              emailKey="emailConsent"
              smsChecked={smsConsent}
              smsKey="smsConsent"
              onColour={
                globalStyling.colours.primaryBrandColour &&
                globalStyling.colours.primaryBrandColour.value
              }
              linkColour={globalStyling.colours.primaryBrandColour}
              marketingLink={marketingLink}
              showUserCommunication={showUserCommunication}
              enquiryFormStyles={enquiryFormStyles}
              globalStyling={globalStyling}
            />
          )}
          <ActionsContainer>
            <ActionButtonWrapper>
              <Button
                applyStyle="secondary"
                buttonStyle={
                  globalStyling.uiElements.secondaryButton &&
                  globalStyling.uiElements.secondaryButton.buttonStyle
                }
                text={translations.cancelActionButton}
                onClick={history.goBack}
              />
            </ActionButtonWrapper>
            <ActionButtonWrapper>
              <Button
                applyStyle="primary"
                buttonStyle={
                  globalStyling.uiElements.primaryButton &&
                  globalStyling.uiElements.primaryButton.buttonStyle
                }
                text={translations.submitMessageActionButton}
                onClick={this.onSubmitMessage}
              />
            </ActionButtonWrapper>
          </ActionsContainer>
          {error && <Error>{translations.somethingWentWrong}</Error>}
        </EnquiryFormContainer>
      </Container>
    );
  }
}
EnquiryForm.defaultProps = {
  showUserCommunication: false,
};
