// @flow
import React, { useState, useEffect, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from './Spinner';
import { actions } from '../shared/printing';
import { translateFromTemplate } from '../shared/localisation/translateFromTemplate';

const efficiencyColours = {
  'A+': '#00A550',
  A: '#00A550',
  B: '#50B948',
  C: '#BED632',
  D: '#FEF003',
  E: '#FBB714',
  F: '#F36E22',
  G: '#ED1E23',
};

const Icon = styled.img`
  width: 22px;
  cursor: pointer;
`;

const Arrow = styled(Icon)`
  transform: rotate(0deg);
  transition: transform 0.2s ease-out;

  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}
`;

const AccordianContainer = styled.div`
  width: 100%;
`;

const EfficiencyBadgeContainer = styled.div`
  display: flex;
`;

export const BadgeLabel = styled.div`
  display: flex;
  height: 24px;
  padding: 0 10px;
  align-items: center;
  color: white;
  font-size: 12px;
  padding-top: 2px;
  font-weight: bold;
  background-color: ${({ emissionsClass }) =>
    efficiencyColours[emissionsClass]};
`;

const BadgeArrow = styled.div`
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 13px solid
    ${({ emissionsClass }) => efficiencyColours[emissionsClass] || '#FFFFFF'};
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => width || '100%'};
`;

const Label = styled.span`
  font-size: 16px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
  padding: 10px 10px 0px 10px;
`;

export const PdfButton = ({ vehicle, globalStyling }) => {
  const dispatch = useDispatch();
  const isPrinting = useSelector(state => state.shared.printing.isPrinting);
  const [isSpinning, setIsSpinning] = useState(false);

  useEffect(() => {
    if (isSpinning && !isPrinting) setIsSpinning(false);
  }, [isPrinting, isSpinning]);

  return isSpinning ? (
    <Spinner colour={globalStyling.colours.primaryBrandColour} size={18} />
  ) : (
    <Icon
      src="https://res.cloudinary.com/motortrak/image/upload/c_fit,w_28/v1583338975/cms/global/icons/pdf.svg"
      onClick={() => {
        setIsSpinning(true);
        dispatch(actions.printEmissionInfo(vehicle));
      }}
    />
  );
};

const getTranslationsFromTemplate = (engineDetails, translate) => {
  const consumptionInTown = translate('consumptionInTown', {
    COMBINEDURBAN: engineDetails.consumptionUrban,
  });
  const consumptionOutside = translate('consumptionOutside', {
    COMBINEDEXTRAURBAN: engineDetails.consumptionExtraurban,
  });
  const consumptionCombined = translate('consumptionCombined', {
    COMBINEDCONSUMPTION: engineDetails.combinedConsumption,
  });
  const co2EmissionsCombined = translate('co2EmissionsCombined', {
    EMISSIONS: engineDetails.co2EmissionsCombined,
  });

  return {
    consumptionInTown,
    consumptionOutside,
    consumptionCombined,
    co2EmissionsCombined,
  };
};

const SearchCo2Accordian = ({
  vehicle,
  translations,
  globalStyling,
}: Object) => {
  const [open, setOpen] = useState(false);
  const { emissionsClass } = vehicle.engineDetails;

  const {
    consumptionInTown,
    consumptionOutside,
    consumptionCombined,
    co2EmissionsCombined,
  } = getTranslationsFromTemplate(vehicle.engineDetails, (key, placeholder) =>
    translateFromTemplate(key, placeholder, translations),
  );

  return (
    <AccordianContainer>
      {emissionsClass ? (
        <Fragment>
          <FlexRow>
            <FlexRow width="200px">
              <EfficiencyBadgeContainer>
                <BadgeLabel emissionsClass={emissionsClass}>
                  {emissionsClass}
                </BadgeLabel>
                <BadgeArrow emissionsClass={emissionsClass} />
              </EfficiencyBadgeContainer>
              <Label>{translations.efficiencyClass}</Label>
              <PdfButton vehicle={vehicle} globalStyling={globalStyling} />
            </FlexRow>
            <Arrow
              src="https://res.cloudinary.com/motortrak/image/upload/v1553256851/cms/jaguar/global/icons/arrow-down.svg"
              onClick={() => setOpen(!open)}
              open={open}
            />
          </FlexRow>
          {open && (
            <FlexColumn>
              <Label>{consumptionInTown}</Label>
              <Label>{consumptionOutside}</Label>
              <Label>{consumptionCombined}</Label>
              <Label>{co2EmissionsCombined}</Label>
            </FlexColumn>
          )}
        </Fragment>
      ) : (
        <Label>{translations.noEmissions}</Label>
      )}
    </AccordianContainer>
  );
};
export default SearchCo2Accordian;
