// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';

import FilterSection from './FilterSection';
import MultiSelectFilter from './MultiSelectFilter';
import RegionalFilter from './RegionalFilter';
import LocationDistanceFilter from './LocationDistanceFilter';
import OneHandledRangeFilter from './RangeFilters/OneHandledRangeFilter';
import DualHandledRangeFilter from './RangeFilters/DualHandledRangeFilter';
import CheckBox from '../../components/CheckBox';
import {
  Paragraph,
  HeadingThree,
  fontStyleOverride,
} from '../../components/Global';
import {
  localiseNumber,
  localiseCurrency,
} from '../../shared/localisation/numbers';
import { convertPriceCurrency } from '../../shared/currencyConversion/helpers';
import { translateFromTemplate } from '../../shared/localisation/translateFromTemplate';
import media from '../../theme';

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  min-height: 50px;
  border-top: 1px solid #d5d5d5;
  &:last-child {
    border-bottom: 1px solid #d5d5d5;
  }
`;

const MessageOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  padding: 16px;
  width: 100%;
  height: 90px;
  z-index: 1;
  ${media.min.medium`
    width: 278px;
  `};
`;

export const optionComponents = {
  subregions: (filterKey, props) => (
    <RegionalFilter
      key={filterKey}
      filterKey={filterKey}
      selectedData={props.selectedFilters.subregions}
      filtersStyling={props.config.stylingConfig}
      updateFilters={props.updateFilters}
      globalStyling={props.globalStyling}
      availableFilterValues={props.availableFilterValues}
      translations={props.config.translations}
    />
  ),
  distance: (filterKey, props, ref) => (
    <LocationDistanceFilter
      updateSessionPreferences={props.updateSessionPreferences}
      availableFilterValues={props.availableFilterValues}
      selectedFilters={props.selectedFilters}
      selectedData={props.selectedFilters}
      updateFilters={props.updateFilters}
      globalStyling={props.globalStyling}
      filtersStyling={props.config.stylingConfig}
      translations={props.config.translations}
      changeLocation={props.changeLocation}
      location={props.location}
      searchCountryCode={props.searchCountryCode}
      ref={ref}
      key={filterKey}
    />
  ),
  exteriorColours: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.exteriorColoursFilterTitle}
      active={!!props.selectedFilters.exteriorColours}
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <MultiSelectFilter
        filterKey={filterKey}
        data={props.availableFilterValues.exteriorColours}
        selectedData={props.selectedFilters.exteriorColours}
        updateFilters={props.updateFilters}
        globalStyling={props.globalStyling}
        stylingConfig={props.config.stylingConfig}
        viewAllLabel={props.config.translations.viewAllLabel}
        getDisabledValue={value => !value.selectedCount}
      />
    </FilterSection>
  ),
  interiorShades: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.interiorShadeFilterTitle}
      active={!!props.selectedFilters.interiorShades}
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <MultiSelectFilter
        filterKey={filterKey}
        data={props.availableFilterValues.interiorShades}
        selectedData={props.selectedFilters.interiorShades}
        updateFilters={props.updateFilters}
        globalStyling={props.globalStyling}
        stylingConfig={props.config.stylingConfig}
        viewAllLabel={props.config.translations.viewAllLabel}
        getDisabledValue={value => !value.selectedCount}
      />
    </FilterSection>
  ),
  bodystyle: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.bodystyleFilterTitle}
      active={!!props.selectedFilters.bodystyle}
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <MultiSelectFilter
        filterKey={filterKey}
        data={props.availableFilterValues.bodystyle}
        selectedData={props.selectedFilters.bodystyle}
        updateFilters={props.updateFilters}
        globalStyling={props.globalStyling}
        stylingConfig={props.config.stylingConfig}
        viewAllLabel={props.config.translations.viewAllLabel}
        getDisabledValue={value => !value.selectedCount}
      />
    </FilterSection>
  ),
  model: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.modelFilterTitle}
      active={!!props.selectedFilters.model}
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <MultiSelectFilter
        filterKey={filterKey}
        data={props.availableFilterValues.model}
        selectedData={props.selectedFilters.model}
        updateFilters={props.updateFilters}
        globalStyling={props.globalStyling}
        stylingConfig={props.config.stylingConfig}
        viewAllLabel={props.config.translations.viewAllLabel}
        getDisabledValue={() => false}
      />
    </FilterSection>
  ),
  variant: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.variantFilterTitle}
      active={!!props.selectedFilters.variant}
      disabled={
        !props.selectedFilters.model ||
        !props.availableFilterValues.variant.length
      }
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <MultiSelectFilter
        filterKey={filterKey}
        data={props.availableFilterValues.variant}
        selectedData={props.selectedFilters.variant}
        updateFilters={props.updateFilters}
        globalStyling={props.globalStyling}
        stylingConfig={props.config.stylingConfig}
        viewAllLabel={props.config.translations.viewAllLabel}
        getDisabledValue={() => false}
      />
    </FilterSection>
  ),
  modelVariants: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.variantFilterTitle}
      active={!!props.selectedFilters.modelVariants}
      disabled={
        !props.selectedFilters.model ||
        !props.availableFilterValues.modelVariants.length
      }
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <MultiSelectFilter
        filterKey={filterKey}
        data={props.availableFilterValues.modelVariants}
        selectedData={props.selectedFilters.modelVariants}
        updateFilters={props.updateFilters}
        globalStyling={props.globalStyling}
        stylingConfig={props.config.stylingConfig}
        viewAllLabel={props.config.translations.viewAllLabel}
        getDisabledValue={() => false}
      />
    </FilterSection>
  ),
  vehicleTypes: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.vehicleTypes}
      active={!!props.selectedFilters.vehicleTypes}
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <MultiSelectFilter
        filterKey={filterKey}
        data={props.availableFilterValues.vehicleTypes}
        selectedData={props.selectedFilters.vehicleTypes}
        updateFilters={props.updateFilters}
        globalStyling={props.globalStyling}
        stylingConfig={props.config.stylingConfig}
        viewAllLabel={props.config.translations.viewAllLabel}
        getDisabledValue={() => false}
      />
    </FilterSection>
  ),
  engine: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.engineFilterTitle}
      active={!!props.selectedFilters.engine}
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <MultiSelectFilter
        filterKey={filterKey}
        data={props.availableFilterValues.engine}
        selectedData={props.selectedFilters.engine}
        updateFilters={props.updateFilters}
        globalStyling={props.globalStyling}
        stylingConfig={props.config.stylingConfig}
        viewAllLabel={props.config.translations.viewAllLabel}
        applyOrdering={(a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        }}
        getDisabledValue={value => !value.selectedCount}
      />
    </FilterSection>
  ),
  transmission: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.transmissionFilterTitle}
      active={!!props.selectedFilters.transmission}
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <MultiSelectFilter
        filterKey={filterKey}
        data={props.availableFilterValues.transmission}
        selectedData={props.selectedFilters.transmission}
        updateFilters={props.updateFilters}
        globalStyling={props.globalStyling}
        stylingConfig={props.config.stylingConfig}
        viewAllLabel={props.config.translations.viewAllLabel}
        getDisabledValue={value => !value.selectedCount}
      />
    </FilterSection>
  ),
  yearRange: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.ageFilterTitle}
      active={
        !!props.selectedFilters.yearRangeMin ||
        !!props.selectedFilters.yearRangeMax
      }
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <DualHandledRangeFilter
        filterKeyMin="yearRangeMin"
        filterKeyMax="yearRangeMax"
        filterLabel={props.config.translations.ageFilterLabel}
        availableValues={{
          min: props.availableFilterValues.yearRange.min,
          max: props.availableFilterValues.yearRange.max,
        }}
        selectedValues={{
          min:
            props.selectedFilters.yearRangeMin ||
            props.availableFilterValues.yearRange.min,
          max:
            props.selectedFilters.yearRangeMax ||
            props.availableFilterValues.yearRange.max,
        }}
        globalStyling={props.globalStyling}
        updateFilters={props.updateFilters}
        filtersStyling={props.config.stylingConfig}
      />
    </FilterSection>
  ),
  maxOdometer: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.odometerFilterTitle}
      active={!!props.selectedFilters.maxOdometer}
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <OneHandledRangeFilter
        filterKey={filterKey}
        filterLabel={props.config.translations.maxMilesFilterTitle}
        step={500}
        selectedValue={
          props.selectedFilters.maxOdometer ||
          props.availableFilterValues.maxOdometer
        }
        maxValue={props.availableFilterValues.maxOdometer}
        globalStyling={props.globalStyling}
        updateFilters={props.updateFilters}
        stylingConfig={props.config.stylingConfig}
        filtersStyling={props.config.stylingConfig}
        locale={props.locale}
        formatter={value => localiseNumber(value, props.locale)}
      />
    </FilterSection>
  ),
  features: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.equipmentFilterTitle}
      active={!!props.selectedFilters.features}
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <MultiSelectFilter
        filterKey={filterKey}
        data={props.availableFilterValues.features}
        selectedData={props.selectedFilters.features}
        updateFilters={props.updateFilters}
        globalStyling={props.globalStyling}
        stylingConfig={props.config.stylingConfig}
        viewAllLabel={props.config.translations.viewAllLabel}
        getDisabledValue={value => !value.selectedCount}
      />
    </FilterSection>
  ),
  fuelType: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.fuelTypeFilterTitle}
      active={!!props.selectedFilters.fuelType}
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <MultiSelectFilter
        filterKey={filterKey}
        data={props.availableFilterValues.fuelType}
        selectedData={props.selectedFilters.fuelType}
        updateFilters={props.updateFilters}
        globalStyling={props.globalStyling}
        stylingConfig={props.config.stylingConfig}
        viewAllLabel={props.config.translations.viewAllLabel}
        getDisabledValue={value => !value.selectedCount}
      />
    </FilterSection>
  ),
  co2Emissions: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.co2EmissionsFilterTitle}
      active={!!props.selectedFilters.co2Emissions}
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <OneHandledRangeFilter
        filterKey={filterKey}
        filterLabel={props.config.translations.co2EmissionsFilterSubTitle}
        step={1}
        selectedValue={
          props.selectedFilters.co2Emissions ||
          props.availableFilterValues.maxCo2Emission
        }
        maxValue={props.availableFilterValues.maxCo2Emission}
        globalStyling={props.globalStyling}
        updateFilters={props.updateFilters}
        stylingConfig={props.config.stylingConfig}
        filtersStyling={props.config.stylingConfig}
        locale={props.locale}
        unit={props.config.translations.co2Unit}
        formatter={value => {
          const co2Value = localiseNumber(value, props.locale);
          return translateFromTemplate(
            'co2UnitDisplayText',
            {
              VALUE: co2Value,
            },
            props.config.translations,
          );
        }}
      />
    </FilterSection>
  ),
  onlyApproved: (filterKey, props) => (
    <CheckboxWrapper key={filterKey}>
      <CheckBox
        checked={props.selectedFilters.onlyApproved === 'true'}
        onClick={() =>
          props.updateFilters([
            {
              key: filterKey,
              value:
                props.selectedFilters.onlyApproved === 'true'
                  ? 'false'
                  : 'true',
            },
          ])
        }
        onColour={props.globalStyling.colours.primaryBrandColour.value}
      />
      <Paragraph
        styleOverride={() => `
          margin: 0px 16px;
            ${fontStyleOverride(props.config.stylingConfig.filterHeadingFont)}
          `}
      >
        {props.config.translations.onlyApprovedTitle}
      </Paragraph>
    </CheckboxWrapper>
  ),
  maxFuelConsumption: (filterKey, props, ref) => (
    <FilterSection
      key={filterKey}
      title={props.config.translations.fuelconsumptionFilterTitle}
      active={!!props.selectedFilters.maxFuelConsumption}
      globalStyling={props.globalStyling}
      stylingConfig={props.config.stylingConfig}
      ref={ref}
    >
      <OneHandledRangeFilter
        filterKey={filterKey}
        filterLabel={props.config.translations.fuelconsumptionFilterSubTitle}
        step={2}
        selectedValue={
          props.selectedFilters.maxFuelConsumption ||
          props.availableFilterValues.fuelConsumption[0].value
        }
        maxValue={props.availableFilterValues.fuelConsumption[0].value}
        minValue={
          props.availableFilterValues.fuelConsumption[
            props.availableFilterValues.fuelConsumption.length - 1
          ].value
        }
        globalStyling={props.globalStyling}
        updateFilters={props.updateFilters}
        stylingConfig={props.config.stylingConfig}
        filtersStyling={props.config.stylingConfig}
        locale={props.locale}
        formatter={value => {
          const fuelConsumptionValue = localiseNumber(value, props.locale);

          return translateFromTemplate(
            'fuelConsumptionText',
            {
              VALUE: fuelConsumptionValue,
            },
            props.config.translations,
          );
        }}
      />
    </FilterSection>
  ),
  defaultPriceRange: (filterKey, props) => {
    const currentCurrency = props.shared.sessionPreferences.currency;

    const exchangeRates =
      props.shared.currencyConversion.exchangeRates &&
      props.shared.currencyConversion.exchangeRates[currentCurrency] &&
      props.shared.currencyConversion.exchangeRates[currentCurrency].rates;

    const rangeSliderSteps = 500;

    const getMinAvailableValue = () =>
      convertPriceCurrency(
        props.availableFilterValues.minDefaultPrice.value,
        exchangeRates,
        currentCurrency,
        props.availableFilterValues.minDefaultPrice.currency,
        rangeSliderSteps,
        'min',
      );

    const getMaxAvailableValue = () =>
      convertPriceCurrency(
        props.availableFilterValues.maxDefaultPrice.value,
        exchangeRates,
        currentCurrency,
        props.availableFilterValues.maxDefaultPrice.currency,
        rangeSliderSteps,
      );

    return (
      <Fragment key={filterKey}>
        {!currentCurrency && (
          <MessageOverlay>
            <HeadingThree
              styleOverride={() =>
                'text-align: center; font-size: 12px; width: 60%;'
              }
            >
              {props.config.translations.currencyUnavailable}
            </HeadingThree>
          </MessageOverlay>
        )}
        <DualHandledRangeFilter
          filterKeyMin="minDefaultPrice"
          filterKeyMax="maxDefaultPrice"
          filterLabel={props.config.translations.priceFilterTitle}
          step={rangeSliderSteps}
          availableValues={{
            min: getMinAvailableValue(),
            max: getMaxAvailableValue(),
          }}
          selectedValues={{
            min:
              props.selectedFilters.minDefaultPrice || getMinAvailableValue(),
            max:
              props.selectedFilters.maxDefaultPrice || getMaxAvailableValue(),
          }}
          globalStyling={props.globalStyling}
          updateFilters={rangeFilterValues => {
            const updatedFilterValues = rangeFilterValues.map(rv => ({
              ...rv,
              value: rv.value,
            }));
            props.updateFilters(updatedFilterValues);
          }}
          filtersStyling={props.config.stylingConfig}
          hideValues={!currentCurrency}
          formatter={value =>
            localiseCurrency(Number(value), props.locale, currentCurrency, 0)
          }
        />
      </Fragment>
    );
  },
  priceRange: (filterKey, props) => {
    const step = 500;

    const getMinAvailableValue = () => {
      const { minPriceRange } = props.availableFilterValues;
      const min = Math.floor(minPriceRange / step) * step;
      return min;
    };

    const getMaxAvailableValue = () => {
      const { maxPriceRange } = props.availableFilterValues;
      return Math.ceil(maxPriceRange / step) * step;
    };

    return (
      <DualHandledRangeFilter
        key={filterKey}
        filterKeyMin="minPriceRange"
        filterKeyMax="maxPriceRange"
        filterLabel={props.config.translations.priceFilterTitle}
        step={step}
        availableValues={{
          min: getMinAvailableValue(),
          max: getMaxAvailableValue(),
        }}
        selectedValues={{
          min: props.selectedFilters.minPriceRange || getMinAvailableValue(),
          max: props.selectedFilters.maxPriceRange || getMaxAvailableValue(),
        }}
        globalStyling={props.globalStyling}
        updateFilters={props.updateFilters}
        filtersStyling={props.config.stylingConfig}
        formatter={value =>
          localiseCurrency(Number(value), props.locale, props.currencyCode, 0)
        }
      />
    );
  },
};
