// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import media from '../theme';
import SearchResultGridTile from './SearchResultGridTile';
import SearchResultGridBlock from './SearchResultGridBlock';
import SearchResultBlock from './SearchResultBlock';
import SearchResultTile from './SearchResultTile';
import CloseIcon from './JaguarIcons/Global/Close';
import CompareIcon from './JaguarIcons/Global/Compare';
import HeartIcon from './JaguarIcons/Global/Heart';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
`;

const IconWrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const SearchResultTileWrapper = styled(SearchResultTile)`
  padding-right: 10px;
`;

const CloseIconButton = onCloseClick => () => (
  <IconWrapper onClick={onCloseClick}>
    <CloseIcon width="1.5em" height="1.3125em" colour="#ACACAC" />
  </IconWrapper>
);

const CompareIconButton = (
  onCompareClick: Function,
  compareIconColour: string,
) => () => (
  <IconWrapper onClick={onCompareClick}>
    <CompareIcon width="2em" height="1.75em" colour={compareIconColour} />
  </IconWrapper>
);

const HeartIconButton = (
  onHeartClick: Function,
  heartIconColour: string,
) => () => (
  <IconWrapper onClick={onHeartClick}>
    <HeartIcon width="2em" height="1.75em" colour={heartIconColour} />
  </IconWrapper>
);

const ModelGridTileWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  ${({ numberOfColumns }) =>
    `${numberOfColumns && `width: ${100 / numberOfColumns - 1}%`}`};
  ${media.max.medium`
    min-width: 49%;
  `};
  ${media.max.small`
    min-width: 100%;
  `};
`;

const GridViewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ useLegacyTiles }) =>
    useLegacyTiles ? 'center' : 'space-between'};
  flex: 1 0 0%;
`;

const DoubleEmptyTile = () => (
  <Fragment>
    <ModelGridTileWrapper numberOfColumns={4} />
    <ModelGridTileWrapper numberOfColumns={4} />
  </Fragment>
);

type Props = {
  config: Object,
  vehicles: Vehicle[],
  onHeartClick: Function,
  onCloseClick: Function,
  onCompareClick: Function,
  compareVehicle: Function,
  shortlistVehicle: Function,
  onMoreInfoClick: Function,
  getCompareIconColour: Function,
  getShortListedIconColour: Function,
  selectedView: 'GRID' | 'LIST',
  sortValue: string,
  sortValueDictionary: string,
  placeholdingImage: string,
  globalStyling: Object,
  locale: string,
  pricingSectionImage: string,
  handleZeroPriceAsText: boolean,
};

export default function VehicleGridShortlist({
  config,
  vehicles,
  placeholdingImage,
  onCloseClick,
  compareVehicle,
  shortlistVehicle,
  onMoreInfoClick,
  getCompareIconColour,
  getShortListedIconColour,
  selectedView,
  sortValue,
  sortValueDictionary,
  globalStyling,
  locale,
  handleZeroPriceAsText,
}: Props) {
  return (
    <Fragment>
      {vehicles && vehicles.length ? (
        <Container length={vehicles.length}>
          {selectedView === 'GRID' ? (
            <GridViewWrapper useLegacyTiles={config.useLegacyTiles}>
              {Array.from(new Set(vehicles.map(v => v.id)))
                .map(id => ({
                  ...vehicles.find(v => v.id === id),
                }))
                .sort(sortValueDictionary[sortValue])
                .map(vehicle =>
                  config.useLegacyTiles ? (
                    <SearchResultTileWrapper
                      key={vehicle.id}
                      align="left"
                      onHeartClick={() => {}}
                      carResult={vehicle}
                      placeholdingImage={placeholdingImage}
                      checkLength={vehicles.length < 3}
                      onMoreInfoClicked={() => onMoreInfoClick('VDP', vehicle)}
                      translations={config.translations}
                      filtersExpanded={false}
                      renderTopRightIcon={CloseIconButton(() =>
                        onCloseClick(vehicle),
                      )}
                      renderBottomIcon1={CompareIconButton(
                        () => compareVehicle(vehicle),
                        getCompareIconColour(vehicle.id),
                      )}
                      renderBottomIcon2={HeartIconButton(
                        () => shortlistVehicle(vehicle),
                        getShortListedIconColour(vehicle.id),
                      )}
                      globalStyling={globalStyling}
                      visibility={config.visibility && config.visibility.tiles}
                      locale={locale}
                      headerItemFont={config.headerItemFont}
                      badgeStyling={config.badgeStyling}
                      pricingSectionImage={config.pricingSectionImage}
                      VehicleTitleFont={config.vehicleTitleFont}
                      vehicleSubTitleFont={config.vehicleSubTitleFont}
                      optionalVehicleTitle={config.optionalVehicleTitle}
                      backgroundColour={config.backgroundColour}
                      actionRowStyles={config.actionRowStyles}
                      moreInfoButtonRightAlign={config.moreInfoButtonRightAlign}
                      vehicleSpecContainer={config.vehicleSpecContainer}
                      countDivMargin={config.countDivMargin}
                      compareShortlistIconWidth={
                        config.compareShortlistIconWidth
                      }
                    />
                  ) : (
                    <ModelGridTileWrapper
                      numberOfColumns={config.gridViewColumns}
                      key={vehicle.id}
                    >
                      <SearchResultGridTile
                        config={config}
                        shortList
                        compareVehicle={compareVehicle}
                        shortlistVehicle={shortlistVehicle}
                        placeholdingImage={placeholdingImage}
                        translations={config.translations}
                        align="left"
                        vehicle={vehicle}
                        onMoreInfoClicked={() =>
                          onMoreInfoClick('VDP', vehicle)
                        }
                        shortListedIconColour={getShortListedIconColour(
                          vehicle.id,
                        )}
                        compareIconColour={getCompareIconColour(vehicle.id)}
                        globalStyling={globalStyling}
                        visibility={
                          config.visibility && config.visibility.tiles
                        }
                        locale={locale}
                        tileBackgroundColor={config.tileBackgroundColor}
                        showLinearBackground={config.showLinearBackground}
                        headerItemFont={config.headerItemFont}
                        badgeStyling={config.badgeStyling}
                        handleZeroPriceAsText={handleZeroPriceAsText}
                        showNumberOfImagesInsteadOfViews={
                          config.showNumberOfImagesInsteadOfViews
                        }
                        countDivMargin={config.countDivMargin}
                        compareShortlistIconWidth={
                          config.compareShortlistIconWidth
                        }
                        icon360={config.icon360}
                      />
                    </ModelGridTileWrapper>
                  ),
                )}
              {config.gridViewColumns === 3 && vehicles.length % 3 === 2 && (
                <ModelGridTileWrapper numberOfColumns={3} />
              )}
              {config.gridViewColumns === 4 && vehicles.length % 4 === 3 && (
                <ModelGridTileWrapper numberOfColumns={4} />
              )}
              {config.gridViewColumns === 4 && vehicles.length % 4 === 2 && (
                <DoubleEmptyTile />
              )}
            </GridViewWrapper>
          ) : null}
          {selectedView === 'LIST'
            ? Array.from(new Set(vehicles.map(v => v.id)))
                .map(id => ({
                  ...vehicles.find(v => v.id === id),
                }))
                .sort(sortValueDictionary[sortValue])
                .map(vehicle =>
                  config.useLegacyTiles ? (
                    <SearchResultBlock
                      key={vehicle.id}
                      vehicle={vehicle}
                      placeholdingImage={placeholdingImage}
                      translations={config.translations}
                      onMoreInfoClick={onMoreInfoClick}
                      vehiclePriceSectionWidth={25}
                      displayImage
                      compareVehicle={compareVehicle}
                      shortlistVehicle={shortlistVehicle}
                      renderTopRightIcon={CloseIconButton(() =>
                        onCloseClick(vehicle),
                      )}
                      shortListedIconColour={getShortListedIconColour(
                        vehicle.id,
                      )}
                      compareIconColour={getCompareIconColour(vehicle.id)}
                      globalStyling={globalStyling}
                      visibility={config.visibility && config.visibility.tiles}
                      locale={locale}
                      badgeStyling={config.badgeStyling}
                      headerItemFont={config.headerItemFont}
                      pricingSectionImage={config.pricingSectionImage}
                      financeHeadingFont={config.financeHeadingFont}
                      financeSubHeadingFont={config.financeSubHeadingFont}
                      vehicleTitleFont={config.vehicleTitleFont}
                      vehicleSubTitleFont={config.vehicleSubTitleFont}
                      optionalVehicleTitle={config.optionalVehicleTitle}
                      backgroundColour={config.backgroundColour}
                      financeWidthOverride={config.financeWidthOverride}
                      financePaddingOverride={config.financePaddingOverride}
                      actionRowStyles={config.actionRowStyles}
                      moreInfoButtonRightAlign={config.moreInfoButtonRightAlign}
                      vehicleSpecContainer={config.vehicleSpecContainer}
                      icon360={config.icon360}
                    />
                  ) : (
                    <SearchResultGridBlock
                      config={config}
                      key={vehicle.id}
                      translations={config.translations}
                      vehicle={vehicle}
                      placeholdingImage={placeholdingImage}
                      onMoreInfoClick={onMoreInfoClick}
                      compareVehicle={compareVehicle}
                      shortlistVehicle={shortlistVehicle}
                      compareIconColour={getCompareIconColour(vehicle.id)}
                      shortListedIconColour={getShortListedIconColour(
                        vehicle.id,
                      )}
                      renderTopRightIcon={CloseIconButton(() =>
                        onCloseClick(vehicle),
                      )}
                      globalStyling={globalStyling}
                      visibility={config.visibility && config.visibility.tiles}
                      locale={locale}
                      tileBackgroundColor={config.tileBackgroundColor}
                      headerItemFont={config.headerItemFont}
                      badgeStyling={config.badgeStyling}
                      listViewImageWidth={config.listViewImageWidth}
                      handleZeroPriceAsText={handleZeroPriceAsText}
                      showNumberOfImagesInsteadOfViews={
                        config.showNumberOfImagesInsteadOfViews
                      }
                      imageWidth="30"
                      icon360={config.icon360}
                    />
                  ),
                )
            : null}
        </Container>
      ) : null}
    </Fragment>
  );
}
