import path from 'ramda/src/path';
import propOr from 'ramda/src/propOr';

import { localeFormattedDate } from '../shared/localisation/dates';
import {
  localiseNumber,
  localiseCurrency,
} from '../shared/localisation/numbers';

import { Rating } from './Rating';
import { Emissions } from './Emission';

const formatters = {
  integer: (d, locale) => localiseNumber(d, locale, 0),
  decimal: (d, locale) => localiseNumber(d, locale, 2),
  date: (d, locale, { dateFormat }) =>
    localeFormattedDate(
      d,
      locale,
      dateFormat || {
        month: '2-digit',
        year: 'numeric',
      },
    ),
  capitalise: d => d && d.charAt(0).toUpperCase() + d.slice(1).toLowerCase(),
  endstring: (d, _, { length }) => d && d.slice(-length),
  price: (d, locale, { currency, decimalPlaces = 0 }) =>
    localiseCurrency(d, locale, currency, decimalPlaces),
  unit: (d, _, { unit }) => d && `${d}${unit}`,
  rating: (d, _, { max, baseUrl, fill, half, empty }) =>
    d && Rating({ rating: d, max, baseUrl, fill, half, empty }),
  concat: (d, locale, requiredData, vehicle) =>
    // eslint-disable-next-line no-use-before-define
    d && `${d} ${vehicleDataFormatter(requiredData, vehicle, locale)}`,
  join: (d, _, { separator }) => d.join(separator),
  emissions: (d, _, __, vehicle) =>
    vehicle.specification.engineDetails.emissionsClass && Emissions(vehicle),
};

export const vehiclePropFormatter = (data, vehicle, locale) => {
  const value = path(data.prop.split('.'), vehicle);
  const formatter = propOr(d => d, data.format, formatters);
  return formatter(value, locale, data.formatArgs || {}, vehicle);
};

export const vehicleDataFormatter = (
  requiredData,
  vehicle,
  locale,
  separator = ' ',
) =>
  requiredData.props
    .map(p => vehiclePropFormatter(p, vehicle, locale))
    .filter(Boolean)
    .join(separator);

export const urlTemplateFormatter = (properties, vehicle, locale, template) =>
  properties.reduce((url, prop) => {
    const value = vehiclePropFormatter(prop, vehicle, locale);
    const regexGlobal = new RegExp(`{${prop.prop}}`, 'g');

    return url.replace(regexGlobal, value);
  }, template);

export const vanityUrlFormatter = (config, vehicle, locale) =>
  vehicleDataFormatter(config.vdpUrlMetaInfo, vehicle, locale, '-').replace(
    /\s/g,
    '-',
  );
