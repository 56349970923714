// @flow
/* eslint-disable no-shadow, max-len */
import React, { type Node, Fragment } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Eye from './JaguarIcons/VDP/Eye';
import ImagesIcon from './JaguarIcons/Global/Images';
import Pin from './JaguarIcons/Global/Pin';
import CO2 from './JaguarIcons/Global/Co2';
import {
  Button,
  HeadingTwo,
  HeadingThree,
  Paragraph,
  fontStyleOverride,
} from './Global';
import media from '../theme';
import {
  localiseCurrency,
  localiseNumber,
} from '../shared/localisation/numbers';
import { localeFormattedDate } from '../shared/localisation/dates';
import FinancePopup from './FinancePopup';
import WarrantyBadge from './WarrantyBadge';
import OnDemandBadge from './OnDemandBadge';
import { vehicleDataFormatter } from '../helpers/vehicle';
import { getWarrantyLabel } from '../helpers/getWarrantyLabel';
import { convertSecondaryPriceCurrency } from '../shared/currencyConversion/helpers';
import { translateFromTemplate } from '../shared/localisation/translateFromTemplate';
import Spinner from './Spinner';
import SearchCo2Accordian from './SearchCo2Accordian';

import type { Theme } from '../types';

const TileItemContainer = styled.div`
  ${({ backgroundColour }) =>
    backgroundColour && `background-color: ${backgroundColour.value}`};
  display: flex;
  flex-direction: column;
  flex-basis: 48%;
  max-width: 100%;
  border: 1px solid #cccccc;
  margin: 20px 0 0;
  margin-right: ${({ checkLength }) => (checkLength ? '10px' : 'unset')};
  ${media.max.medium`
    flex-basis: 48%;
    margin-right: ${({ checkLength }) => (checkLength ? '10px' : 'unset')};
  `};
  ${media.min.large`
    flex-basis: ${props => (props.filtersExpanded ? '48%' : '32%')}
    margin-right: ${({ checkLength }) => (checkLength ? '10px' : 'unset')};
  `};
  ${media.max.small`
    flex-basis: 100%;
  `};
  ${media.max.large`
    ${props =>
      props.inSimilarVehicles &&
      `width: 100%;
      display: ${props.mobileSelectedForSimVehicle ? 'flex' : 'none'}
      margin: auto;
      `}
  `};
`;

const TopContainer = styled.div`
  background: ${({ backgroundColour }) =>
    backgroundColour
      ? `${backgroundColour.value}`
      : 'linear-gradient(0deg, rgba(238, 238, 238, 0) 0%, #eeeeee 100%);'};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 12px;
`;

const ModelName = styled.div`
  font-size: 18px;
  letter-spacing: ${({ noLetterSpacing }) =>
    noLetterSpacing ? '0px;' : '3px;'};
  text-transform: uppercase;
  line-height: 20px;
  font-weight: ${({ fontWeight }) => fontWeight || 600};
`;

const SubHeadingWrapper = styled.div`
  font-size: 14px;
  color: #444444;
  line-height: 20px;
  margin-top: 5px;
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  &:hover {
    cursor: pointer;
  }
`;

const ApprovedImage = styled.img`
  width: 50%;
  height: 70%;
`;

const Image = styled.img`
  display: flex;
  align-self: center;
  max-width: 100%;
  width: 100%;
`;

const ImageOverlay = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ffffff;
  background-color: rgb(0, 0, 0, 0.5);
  cursor: default;
`;

const VisibleFooter = styled.div`
  width: calc(100% - 35px);
  height: 30px;
  position: absolute;
  bottom: 0;
  background: #000000;
  display: flex;
  justify-content: space-between;
  padding: 0 25px 0 10px;
  opacity: 0.7;
  align-items: center;
  ${({ direction }) => direction === 'rtl' && 'width: calc(100% - 29px);'};
  ${({ direction }) => direction === 'rtl' && 'padding: 0 13px 0 16px;'};
`;

const ViewsContainer = styled.div`
  align-items: center;
  top: 18px;
  left: 20px;
  width: 150px;
  opacity: 1;
  display: flex;
  color: #fff;
`;

const Views = styled.span`
  padding-top: 2px;
  ${({ direction }) => direction === 'rtl' && 'margin-right: 5px;'};
`;

const Counter = styled.div`
  text-align: center;
  display: flex;
  color: #fff;
`;

const CountDiv = styled.div`
  margin-top: ${({ margin }) => (margin ? '0' : '2px')};
  margin-right: 10px;
  ${({ direction }) => direction === 'rtl' && 'margin-left: 5px;'};
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding || '0.5em 1em 1em 1em'};
`;

const HorizontalLine = styled.hr`
  display: ${({ visible }) => (visible === true ? 'block' : 'none')};
  height: 1px;
  border: 0;
  border-top: ${({ showRightBorder }) =>
    showRightBorder === true ? '1px solid #dedede' : '0'};
  margin: 0;
  padding: 0;
  justify-content: center;
`;

const VerticalLine = styled.div`
  display: ${({ visible }) => (visible === true ? 'flex' : 'none')};
  border-left: ${({ showRightBorder }) =>
    showRightBorder === true ? '1px solid #dedede' : '0'};
  height: inherit;
`;

const IconRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: ${({ vehicleSpecPadding }) =>
    vehicleSpecPadding ? '10px 20px' : '10px 5px'};
  ${({ gridBorder }) => gridBorder && 'border-top: 1px solid #cccccc'};
`;

const MoreInfoRow = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  height: 65px;
  justify-content: space-between;
  ${({ actionRowStyles }) =>
    actionRowStyles &&
    actionRowStyles.gridViewPadding &&
    `padding: ${actionRowStyles.gridViewPadding}`};
`;

const IconText = styled.div`
  padding-left: 12px;
  color: ${({ valueFontStyle }) =>
    valueFontStyle ? valueFontStyle.color : '#444444;'};
  font-size: ${({ valueFontStyle }) =>
    valueFontStyle ? valueFontStyle.fontSize : '15px'};
`;

const FinanceCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ noFinance }) => (noFinance ? 'initial' : 'center')};
  width: 60%;
  &:last-child {
    width: 40%;
  }
`;

const FinanceRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  max-width: 100%;
  margin-bottom: 0.5em;
  min-height: ${({ financeCellHeight }) => financeCellHeight || '110px'};
`;

const VatRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  max-width: 100%;
`;

const Co2ConsumptionInformationSection = styled.div`
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  display: flex;
  margin-top: auto;
  min-height: 26px;
  padding: 10px 0px;
`;

const Cell = styled.div`
  display: ${({ visible }) => (visible === true ? 'flex' : 'none')};
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5em 0;
  &:nth-child(1) {
    padding-left: ${({ paddingLeft }) => paddingLeft || '20px'};
  }
  &:nth-child(3) {
    padding-left: ${({ paddingLeft }) => paddingLeft || '20px'};
  }
`;

const IconPadding = styled.div`
  display: flex;
  height: 25px;
`;

const IconSpaceContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  height: 41px;
`;

const DesktopOnly = styled.div`
  display: block;
  ${media.max.large`
    display: none;
  `};
`;

const SoldHeading = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const SoldBody = styled.div`
  font-size: 14px;
  max-width: 165px;
`;

const FinanceCellWithPopup = styled.div`
  display: flex;
  align-items: center;
  display: ${({ visible }) => (visible === true ? 'flex' : 'none')};
`;

const LocationPin = styled.div`
  display: flex;
  align-items: center;
  height: 20%;
  padding: ${({ paddingLeft }) =>
    paddingLeft ? `8px 0 8px ${paddingLeft}px` : '8px 0'};
`;

const ThreeSixtyImage = styled.div`
  height: 35px;
  width: 24px;
  position: absolute;
  z-index: 20;
  top: 5px;
  ${({ direction }) => (direction === 'rtl' ? 'left: 5px;' : 'right: 5px')};
`;

const FinanceSpinnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  width: 100%;
  margin-top: 4px;
`;

const FinanceLoadingWrapper = styled.div`
  margin: 10px;
`;

type IconProps = {
  icon: Function,
};

export type ItemTheme = {
  height?: number,
  align: string,
};

type CellProps = {
  headerText: string,
  secondaryHeaderText?: string,
  subHeaderText: string,
  visible: boolean,
  paddingLeft: string,
  colHeaderColour: string,
  financeHeadingFont: Object,
  financeSubHeadingFont: Object,
  hideBorders: boolean,
};

function IconSpace({ icon }: IconProps) {
  return icon ? (
    <IconSpaceContainer>
      <IconPadding>
        {icon()}
        <VerticalLine />
      </IconPadding>
    </IconSpaceContainer>
  ) : null;
}

function FinanceCell({
  headerText,
  secondaryHeaderText,
  subHeaderText,
  visible,
  paddingLeft,
  financeHeadingFont,
  financeSubHeadingFont,
  hideBorders,
}: CellProps) {
  return (
    <Cell
      visible={visible}
      paddingLeft={paddingLeft}
      showRightBorder={hideBorders}
    >
      <HeadingTwo
        styleOverride={() => `
          width: 100%;
          ${fontStyleOverride(financeHeadingFont)};
        `}
      >
        {headerText}
      </HeadingTwo>
      <HeadingTwo
        styleOverride={() => `
          width: 100%;
          ${fontStyleOverride(financeHeadingFont)};
        `}
      >
        {secondaryHeaderText}
      </HeadingTwo>
      <HeadingThree
        styleOverride={() => `
        font-size: 14px;
        color: #999999;
        ${fontStyleOverride(financeSubHeadingFont)};
        `}
      >
        {subHeaderText}
      </HeadingThree>
    </Cell>
  );
}

const DistanceFromRetailer = ({ carResult, actionRowStyles }) => {
  const { locationPinPadding = {} } = actionRowStyles || {};
  return (
    <LocationPin paddingLeft={locationPinPadding.left}>
      <Pin width="27px" height="24px" colour="#acacac" />
      <span style={{ color: '#444', fontSize: '15px', marginLeft: '10px' }}>
        {`${carResult.distance}km - ${carResult.retailerName}`}
      </span>
    </LocationPin>
  );
};

const formattedLocale = locale => locale.replace('_', '-');

export type CarResult = {
  id: string,
  name: string,
  odometer: Object,
  engine: string,
  transmission: string,
  fuel: string,
  priceText: string,
  monthlyText: string,
  priceApr: string,
  aprText: string,
  locale: string,
  currencyType: string,
  co2EmissionsCombined: string,
  distanceFromRetailer: ?number,
  retailerName: string,
  images: string[],
  numberAvailable: number,
  selected: boolean,
  modelTheme: Theme,
  priceTheme: Theme,
  numberAvailableTheme: Theme,
  financeTextTheme: Theme,
  align: string,
  views: number,
  price: Object,
  status: 'retail' | 'reserved' | 'sold',
  registration: string,
};

type Props = {
  align: string,
  carResult: CarResult,
  placeholdingImage: string,
  checkLength: boolean,
  onMoreInfoClicked: Function,
  translations: Object,
  shortList: boolean,
  filtersExpanded: boolean,
  renderTopRightIcon: () => Node,
  renderBottomIcon1: () => Node,
  renderBottomIcon2: () => Node,
  mobileSelectedForSimVehicle?: boolean,
  inSimilarVehicles?: boolean,
  globalStyling: Object,
  visibility: Object,
  locale: string,
  vehicleModelTitleFontWeight: number,
  pricingSectionImage: string,
  financeHeadingFont: Object,
  financeSubHeadingFont: Object,
  showFinancePopup: boolean,
  badgeWithStripUrl: string,
  badgeWithoutStripUrl: string,
  warrantyBadgeMonths: Number,
  warrantyBadgeMaxMileage: Number,
  showFinanceCellBorder: boolean,
  backgroundColour: Option,
  optionalVehicleTitle: boolean,
  vehicleTitleFont: Object,
  vehicleSubTitleFont: Object,
  vehicleSpecContainer: Object,
  actionRowStyles: Object,
  countDivMargin: boolean,
  compareShortlistIconWidth: number,
  showOnDemand?: Object,
  icon360: string,
};

const IconWithInfo = ({
  label,
  value,
  children,
}: {
  label: string,
  value: string,
  children: Node,
}) => {
  if (!value) return null;
  const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 0 0 50%;
    box-sizing: border-box;
    padding-top: 5px;
  `;
  return (
    <Wrapper>
      {children}
      <div style={{ marginLeft: '9px' }}>
        <div style={{ lineHeight: '18px', color: '#7e7e7e', fontSize: '13px' }}>
          {label}
        </div>
        <div
          style={{
            lineHeight: '18px',
            color: '#444',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          {value}
        </div>
      </div>
    </Wrapper>
  );
};

const IconSectionWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  img {
    padding-left: 2px;
    max-width: 25px;
    max-height: 25px;
  }
`;

const LamborghiniInfoSection = ({
  vehicle,
  priceLabel,
  translations,
  showApprovedVehicles,
}) => (
  <Fragment>
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent:
          showApprovedVehicles && vehicle.approved
            ? 'space-between'
            : 'flex-end',
        borderBottom: '1px solid #d5d5d5',
        padding: '10px 0',
        marginBottom: '5px',
      }}
    >
      {showApprovedVehicles && vehicle.approved && (
        <ApprovedImage
          src={vehicle.approvedImage}
          alt="approved vehicle image"
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            fontSize: '20px',
            fontFamily: 'RobotoBold',
            color: '#1F1F1F',
          }}
        >
          {vehicle.price}
        </div>
        <div style={{ fontSize: '12px', color: '#9B9B9B', textAlign: 'right' }}>
          {priceLabel}
        </div>
      </div>
    </div>
    <IconSectionWrapper>
      {vehicle.registrationDate && (
        <IconWithInfo
          label={translations.registrationDate}
          value={`${localeFormattedDate(
            vehicle.registrationDate,
            formattedLocale(vehicle.locale),
            {
              month: '2-digit',
              year: 'numeric',
            },
          )} `}
        >
          <img
            src="https://res.cloudinary.com/motortrak/image/upload/v1556030118/cms/lamborghini/content/Reg_Date_2x.png"
            alt={translations.registrationDate}
          />
        </IconWithInfo>
      )}
      {vehicle.odometer && (
        <IconWithInfo
          label={translations.searchResultsOdometerLabel}
          value={`${localiseNumber(vehicle.odometer.reading, vehicle.locale)} ${
            vehicle.odometer.units
          }`}
        >
          <img
            src="https://res.cloudinary.com/motortrak/image/upload/v1556030118/cms/lamborghini/content/Mileage_2x.png"
            alt={translations.searchResultsOdometerLabel}
          />
        </IconWithInfo>
      )}
      {vehicle.registration && (
        <IconWithInfo
          label={translations.registered}
          value={vehicle.registration}
        >
          <img
            src="https://res.cloudinary.com/motortrak/image/upload/v1556030118/cms/lamborghini/content/Model_Year_2x.png"
            alt={translations.registered}
          />
        </IconWithInfo>
      )}
      {vehicle.exterior && (
        <IconWithInfo label={translations.exterior} value={vehicle.exterior}>
          <img
            src="https://res.cloudinary.com/motortrak/image/upload/v1556030118/cms/lamborghini/content/Exterior_2x.png"
            alt={translations.exterior}
          />
        </IconWithInfo>
      )}
      {vehicle.interior && (
        <IconWithInfo label={translations.interior} value={vehicle.interior}>
          <img
            src="https://res.cloudinary.com/motortrak/image/upload/v1556030118/cms/lamborghini/content/Interior_2x.png"
            alt={translations.interior}
          />
        </IconWithInfo>
      )}
      {vehicle.transmission && (
        <IconWithInfo
          label={translations.searchResultsTransmissionLabel}
          value={vehicle.transmission}
        >
          <img
            src="https://res.cloudinary.com/motortrak/image/upload/v1556285220/cms/lamborghini/content/Transmission.svg"
            alt={translations.searchResultsTransmissionLabel}
          />
        </IconWithInfo>
      )}
    </IconSectionWrapper>
    <div
      style={{
        boxSizing: 'border-box',
        borderTop: '1px solid #d5d5d5',
        margin: '5px 0',
      }}
    />
  </Fragment>
);

const carbonTemplate = (vehicle, translations) => {
  const fuelConsumption = translateFromTemplate(
    'fuelConsumptionTemplate',
    {
      MINFUEL: vehicle.engineDetails.consumptionExtraurban,
      MAXFUEL: vehicle.engineDetails.consumptionUrban,
    },
    translations,
  );

  const co2 = translateFromTemplate(
    'co2ConsumptionTemplate',
    {
      MINCO2: vehicle.engineDetails.co2EmissionsMin,
      MAXCO2: vehicle.engineDetails.co2EmissionsMax,
    },
    translations,
  );

  return `${fuelConsumption}\n ${co2}`;
};

const electricTemplate = (vehicle, translations) =>
  translateFromTemplate(
    'electricVehicleConsumptionTemplate',
    {
      MAXDISTANCE: vehicle.engineDetails.maxRange,
      MINPOWER: vehicle.engineDetails.minPowerKw,
      MAXPOWER: vehicle.engineDetails.maxPowerKw,
    },
    translations,
  );

const co2LegalInfo = (vehicle, translations) =>
  vehicle.engine === 'EV'
    ? electricTemplate(vehicle, translations)
    : carbonTemplate(vehicle, translations);

export default function SearchResultTile({
  align,
  carResult,
  placeholdingImage,
  checkLength,
  translations,
  renderTopRightIcon,
  renderBottomIcon1,
  renderBottomIcon2,
  onMoreInfoClicked,
  filtersExpanded,
  mobileSelectedForSimVehicle,
  inSimilarVehicles,
  globalStyling,
  visibility,
  locale,
  vehicleModelTitleFontWeight,
  pricingSectionImage,
  financeHeadingFont,
  financeSubHeadingFont,
  showFinancePopup,
  badgeWithStripUrl,
  badgeWithoutStripUrl,
  warrantyBadgeMonths,
  warrantyBadgeMaxMileage,
  showFinanceCellBorder = true,
  backgroundColour,
  optionalVehicleTitle,
  vehicleTitleFont,
  vehicleSubTitleFont,
  vehicleSpecContainer,
  actionRowStyles,
  countDivMargin,
  compareShortlistIconWidth,
  showOnDemand,
  onDemandFont,
  icon360,
  showEmissions,
}: Props) {
  const {
    odometer,
    transmission,
    fuel,
    co2EmissionsCombined,
    financeTextTheme,
    images,
    views,
    status,
    price = { monthly: '', currency: '', apr: '', value: '' },
    powerOutput,
    registrationDate,
  } = carResult;

  const { rates, secondaryCurrency } = useSelector(state => ({
    rates: state.shared.currencyConversion.exchangeRates[price.currency]
      ? state.shared.currencyConversion.exchangeRates[price.currency].rates
      : null,
    secondaryCurrency:
      state.config.config.global.inventory.secondaryCurrency || null,
  }));

  const { financeFeatureEnabled } = useSelector(state => ({
    financeFeatureEnabled:
      state.config.config.global.featureFlags &&
      state.config.config.global.featureFlags.finance,
  }));

  const financeCellText = priceEntry =>
    localiseCurrency(priceEntry, locale, price.currency, 2) ||
    translations.searchResultsNotAvailableLabel;

  const financeCellTextNoDigits = (priceEntry, currency) =>
    localiseCurrency(priceEntry, locale, currency, 0) ||
    translations.searchResultsNotAvailableLabel;

  const hasFinance = !!carResult.finance;
  const { financeLoaded } = carResult;

  const renderSubHeaderDate = () =>
    localeFormattedDate(registrationDate, formattedLocale(locale), {
      month: '2-digit',
      year: 'numeric',
    });

  const SubHeading = ({ children }: { children: Node }) => (
    <SubHeadingWrapper>
      {children.filter(Boolean).join(' | ')}
    </SubHeadingWrapper>
  );

  const generateSubTitle = (vehicle, subtitleData, locale) => {
    if (subtitleData) {
      return vehicleDataFormatter(subtitleData, vehicle, locale, ' | ');
    }
    const { exterior, odometer, transmission, fuel } = vehicle;
    return (visibility.shortVehicleTitles
      ? [fuel, exterior]
      : powerOutput
      ? [
          visibility.showOdometer && odometer && odometer.display,
          transmission,
          fuel,
          powerOutput,
          registrationDate && renderSubHeaderDate(),
        ]
      : [exterior, odometer.display, transmission, fuel]
    )
      .filter(Boolean)
      .join(' | ');
  };

  const FinanceLoadingText = () => (
    <FinanceSpinnerWrapper>
      <Spinner colour={globalStyling.colours.primaryBrandColour} />
      <FinanceLoadingWrapper>
        {translations.financeLoadingText}
      </FinanceLoadingWrapper>
    </FinanceSpinnerWrapper>
  );

  const { hideYear } = visibility;

  const { approvedVehicles } = useSelector(
    state => state.config.config.global.featureFlags,
  );

  return (
    <TileItemContainer
      data-qa-hook="vehicle-tile"
      filtersExpanded={filtersExpanded}
      mobileSelectedForSimVehicle={mobileSelectedForSimVehicle}
      inSimilarVehicles={inSimilarVehicles}
      checkLength={checkLength}
      backgroundColour={backgroundColour}
      compareShortlistIconWidth={compareShortlistIconWidth}
    >
      <TopContainer
        backgroundColour={backgroundColour}
        optionalVehicleTitle={optionalVehicleTitle}
      >
        <div data-qa-hook="vehicle-title-description">
          {optionalVehicleTitle ? (
            <Fragment>
              <HeadingTwo
                styleOverride={() => `
               ${fontStyleOverride(vehicleTitleFont)}
               padding-top: 0;
               padding-left: 0;
               padding-right: 0;
             `}
              >
                {hideYear
                  ? carResult.description
                  : `${carResult.registration} ${carResult.description} `}
              </HeadingTwo>
              <HeadingThree
                styleOverride={() => `
                  ${fontStyleOverride(vehicleSubTitleFont)}
                    ${media.max.extraLarge`
                      font-size: 13px;
                    `};
                    padding-left: 0;
                    padding-right:0;
                    padding-bottom: 0;
                `}
              >
                {generateSubTitle(
                  carResult,
                  visibility.gridSubtitleData,
                  locale,
                )}
              </HeadingThree>
            </Fragment>
          ) : (
            <Fragment>
              <ModelName
                noLetterSpacing={!!pricingSectionImage}
                fontWeight={vehicleModelTitleFontWeight}
              >
                {hideYear
                  ? carResult.description
                  : `${carResult.registration} ${carResult.description} `}
              </ModelName>

              <SubHeading>
                {[
                  visibility.showOdometer && odometer && odometer.display,
                  transmission,
                  fuel,
                  powerOutput,
                  registrationDate && renderSubHeaderDate(),
                ]}
              </SubHeading>
            </Fragment>
          )}

          <WarrantyBadge
            vehicle={carResult}
            badgeWithStripUrl={badgeWithStripUrl}
            badgeWithoutStripUrl={badgeWithoutStripUrl}
            warrantyOdometer={carResult.odometer.reading}
            warrantyBadgeMonths={warrantyBadgeMonths}
            warrantyBadgeMaxMileage={warrantyBadgeMaxMileage}
          />
        </div>
        {status === 'sold' && renderTopRightIcon()}
      </TopContainer>
      <ImageContainer onClick={status !== 'sold' && onMoreInfoClicked}>
        {showOnDemand && (
          <OnDemandBadge
            font={onDemandFont}
            backgroundColor={globalStyling.colours.primaryBrandColour}
            foregroundColor={globalStyling.colours.primaryForegroundColour}
            text={translations.onDemandBadge}
          />
        )}
        {images.length > 0 ? (
          <Image data-qa-hook="vehicle-image" src={images[0]} />
        ) : (
          <Image src={placeholdingImage} alt={placeholdingImage} />
        )}
        {status === 'sold' && (
          <ImageOverlay>
            <SoldHeading>{translations.sold}</SoldHeading>
            <SoldBody>{translations.similarVehiclesAvailable}</SoldBody>
          </ImageOverlay>
        )}
        {carResult.panoramicViews &&
          (carResult.panoramicViews.exterior ||
            carResult.panoramicViews.interior) && (
            <ThreeSixtyImage direction={globalStyling.direction}>
              <img src={icon360} alt="360" />
            </ThreeSixtyImage>
          )}
        <VisibleFooter direction={globalStyling.direction}>
          <ViewsContainer>
            <Eye width="1.5em" height="1.25em" colour="#FFFFFF" />
            <Views direction={globalStyling.direction}>{views}</Views>
          </ViewsContainer>
          <Counter>
            <CountDiv
              margin={countDivMargin}
              direction={globalStyling.direction}
            >
              {' '}
              {`${images.length}`}
            </CountDiv>
            <ImagesIcon width="1.25em" height="1.25em" colour="#FFFFFF" />
          </Counter>
        </VisibleFooter>
      </ImageContainer>
      <BottomContainer
        align={align}
        padding={visibility.bottomContainerPadding}
      >
        {pricingSectionImage ? (
          <LamborghiniInfoSection
            vehicle={{
              ...carResult,
              price: financeCellTextNoDigits(
                price.value && Number(price.value),
                price.currency,
              ),
              approvedImage: pricingSectionImage,
              approved: carResult.approved,
              locale,
            }}
            priceLabel={translations.searchResultsPriceLabel}
            translations={translations}
            showApprovedVehicles={approvedVehicles}
          />
        ) : (
          <Fragment>
            <FinanceRow
              data-qa-hook="vehicle-finance"
              financeCellHeight={visibility.financeCellHeight}
            >
              <FinanceCol noFinance={!financeLoaded || !hasFinance}>
                <FinanceCell
                  headerText={financeCellTextNoDigits(
                    price.value && Number(price.value),
                    price.currency,
                  )}
                  secondaryHeaderText={
                    secondaryCurrency &&
                    rates &&
                    financeCellTextNoDigits(
                      convertSecondaryPriceCurrency(
                        price.value,
                        rates,
                        price.currency,
                        secondaryCurrency,
                      ),
                      secondaryCurrency,
                    )
                  }
                  subHeaderText={
                    secondaryCurrency && rates
                      ? translateFromTemplate(
                          'exchangeRate',
                          {
                            EXCHANGERATE: rates[secondaryCurrency].toFixed(2),
                          },
                          translations,
                        )
                      : translations.searchResultsPriceLabel
                  }
                  visible={visibility.showPrice}
                  paddingLeft={visibility.gridTilePricePaddingLeft}
                  financeHeadingFont={financeHeadingFont}
                  financeSubHeadingFont={financeSubHeadingFont}
                />
                {financeFeatureEnabled && !financeLoaded ? (
                  <FinanceLoadingText />
                ) : (
                  '\u00A0'
                )}
                <HorizontalLine
                  visible={
                    hasFinance && visibility.showMonthly && financeLoaded
                  }
                  showRightBorder={
                    showFinanceCellBorder && visibility.showMonthly
                  }
                />
                <FinanceCellWithPopup
                  visible={
                    hasFinance && visibility.showMonthly && financeLoaded
                  }
                >
                  <FinanceCell
                    headerText={
                      hasFinance
                        ? financeCellText(
                            parseFloat(
                              carResult.finance.monthlyPrice
                                .replace('.', '')
                                .replace(',', '.'),
                            ),
                          )
                        : translations.searchResultsNotAvailableLabel
                    }
                    subHeaderText={translations.searchResultsMonthlyLabel}
                    financeHeadingFont={financeHeadingFont}
                    financeSubHeadingFont={financeSubHeadingFont}
                    visible
                    paddingLeft={visibility.gridTilePricePaddingLeft}
                  />
                  <div style={{ paddingLeft: '10px' }}>
                    {hasFinance &&
                      (status !== 'sold' &&
                        carResult.finance &&
                        showFinancePopup && (
                          <FinancePopup
                            translations={translations}
                            data={carResult.finance}
                            locale={locale}
                          />
                        ))}
                  </div>
                </FinanceCellWithPopup>
              </FinanceCol>
              <VerticalLine
                visible={hasFinance && visibility.showApr && financeLoaded}
                showRightBorder={
                  showFinanceCellBorder && visibility.showMonthly
                }
              />
              <FinanceCol>
                <FinanceCell
                  headerText={`${(hasFinance && carResult.finance.apr) ||
                    translations.searchResultsNotAvailableLabel}%`}
                  subHeaderText={translations.searchResultsAprLabel}
                  visible={hasFinance && visibility.showApr && financeLoaded}
                  financeHeadingFont={financeHeadingFont}
                  financeSubHeadingFont={financeSubHeadingFont}
                  paddingLeft={visibility.gridTilePricePaddingLeft}
                />
                <HorizontalLine
                  visible={
                    hasFinance && visibility.showInterestRate && financeLoaded
                  }
                  showRightBorder={
                    showFinanceCellBorder && visibility.showMonthly
                  }
                />
                <FinanceCell
                  headerText={`${(hasFinance &&
                    carResult.finance.interestRate) ||
                    translations.searchResultsNotAvailableLabel}%`}
                  subHeaderText={translations.searchResultsInterestRateLabel}
                  visible={
                    hasFinance && visibility.showInterestRate && financeLoaded
                  }
                  financeHeadingFont={financeHeadingFont}
                  financeSubHeadingFont={financeSubHeadingFont}
                  paddingLeft={visibility.gridTilePricePaddingLeft}
                />
              </FinanceCol>
            </FinanceRow>
            {translations.vatDisclaimer && (
              <VatRow>
                <Paragraph
                  styleOverride={() => 'font-size: 11px; line-height: 1.2em;'}
                >
                  {translations.vatDisclaimer}
                </Paragraph>
              </VatRow>
            )}
          </Fragment>
        )}
        {translations.co2ConsumptionTemplate && (
          <Co2ConsumptionInformationSection>
            <Paragraph
              styleOverride={() =>
                'font-size: 13px; padding-left: 20px; white-space: pre-line'
              }
            >
              {co2LegalInfo(carResult, translations)}
            </Paragraph>
          </Co2ConsumptionInformationSection>
        )}
        {carResult.distance !== undefined && carResult.distance !== null && (
          <div>
            <HorizontalLine visible />
            <DistanceFromRetailer
              actionRowStyles={actionRowStyles}
              carResult={carResult}
            />
          </div>
        )}
        {vehicleSpecContainer && (
          <div>
            {carResult.warranty.remainingWarrantyWholeYears > 0 && (
              <IconRow gridBorder vehicleSpecPadding>
                <img
                  style={{
                    maxWidth: vehicleSpecContainer.icons.iconWidth,
                    maxHeight: vehicleSpecContainer.icons.iconHeight,
                  }}
                  src={vehicleSpecContainer.icons.warrantyYears}
                  alt={translations.warrantyLabel}
                />
                <IconText valueFontStyle={vehicleSpecContainer.valueFont}>
                  {`${translations.warrantyLabel} `}
                  {`${getWarrantyLabel(
                    carResult.warranty.remainingWarrantyWholeYears,
                    translations.warrantyYearsLabel,
                    translations.warrantyYearsLabel2to4,
                  )}`}
                </IconText>
              </IconRow>
            )}

            {parseInt(co2EmissionsCombined, 10) > 0 && (
              <IconRow gridBorder vehicleSpecPadding>
                <img
                  style={{
                    maxWidth: vehicleSpecContainer.icons.iconWidth,
                    maxHeight: vehicleSpecContainer.icons.iconHeight,
                  }}
                  src={vehicleSpecContainer.icons.efficiencyIcon}
                  alt={translations.efficiencyIcon}
                />
                <IconText valueFontStyle={vehicleSpecContainer.valueFont}>
                  {`${
                    translations.searchResultsEmissionsLabel
                  }: ${co2EmissionsCombined ||
                    translations.searchResultsNotAvailableLabel}`}{' '}
                  {translations.co2Unit}
                </IconText>
              </IconRow>
            )}

            {!!carResult.engineDetails.combinedConsumption &&
              translations.mpgLabel && (
                <IconRow gridBorder vehicleSpecPadding>
                  <img
                    style={{
                      maxWidth: vehicleSpecContainer.icons.iconWidth,
                      maxHeight: vehicleSpecContainer.icons.iconHeight,
                    }}
                    src={vehicleSpecContainer.icons.fuelTypeIcon}
                    alt={translations.fuelTypeIcon}
                  />
                  <IconText valueFontStyle={vehicleSpecContainer.valueFont}>
                    {`${translations.mpgLabel}: ${carResult.engineDetails
                      .combinedConsumption ||
                      translations.searchResultsNotAvailableLabel}`}{' '}
                  </IconText>
                </IconRow>
              )}
          </div>
        )}
        {/*  eslint-disable-next-line max-len */
        parseInt(co2EmissionsCombined, 10) > 0 &&
          !pricingSectionImage &&
          !vehicleSpecContainer && (
            <div>
              <HorizontalLine visible />
              <IconRow theme={financeTextTheme}>
                <CO2
                  width="1.87em"
                  height="1.75em"
                  colour={visibility.co2IconColour}
                />
                <IconText>
                  {`${
                    translations.searchResultsEmissionsCombinedLabel
                  }: ${co2EmissionsCombined ||
                    translations.searchResultsNotAvailableLabel}`}{' '}
                </IconText>
              </IconRow>
            </div>
          )}
        <MoreInfoRow actionRowStyles={actionRowStyles}>
          {status !== 'sold' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <IconRow>
                <DesktopOnly>
                  <IconSpace icon={renderBottomIcon1} />
                </DesktopOnly>
                <IconSpace icon={renderBottomIcon2} />
              </IconRow>
              <Button
                data-qa-hook="more-info-button"
                onClick={status !== 'sold' && onMoreInfoClicked}
                text={translations.searchResultTileMoreInfo}
                buttonStyle={
                  globalStyling.uiElements.primaryButton &&
                  globalStyling.uiElements.primaryButton.buttonStyle
                }
                applyStyle="primary"
              />
            </div>
          )}
        </MoreInfoRow>
        {showEmissions && (
          <SearchCo2Accordian
            vehicle={carResult}
            translations={translations}
            translateFromTemplate={translateFromTemplate}
            globalStyling={globalStyling}
          />
        )}
      </BottomContainer>
    </TileItemContainer>
  );
}

SearchResultTile.defaultProps = {
  onMoreInfoClicked: () => {},
  modelTheme: {},
  numberAvailableTheme: {},
  financeTextTheme: {},
  images: [],
  renderTopRightIcon: () => {},
  price: {},
  mobileSelectedForSimVehicle: false,
  inSimilarVehicles: false,
  buttonTheme: {
    fontFamily: 'inherit',
  },
};
