import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { PdfButton, BadgeLabel } from '../components/SearchCo2Accordian';

const EmissionsDataValue = styled.div`
  display: flex;
  align-items: center;
`;
const StyledBadgeLabel = styled(BadgeLabel)`
  margin-right: 3px;
`;
const Emissions = vehicleInfo => {
  const globalStyling = useSelector(
    state => state.config.config.global.globalStyling,
  );
  return (
    <EmissionsDataValue>
      <StyledBadgeLabel
        emissionsClass={vehicleInfo.specification.engineDetails.emissionsClass}
      >
        {vehicleInfo.specification.engineDetails.emissionsClass}
      </StyledBadgeLabel>
      <PdfButton vehicle={vehicleInfo} globalStyling={globalStyling} />
    </EmissionsDataValue>
  );
};

export { Emissions };
