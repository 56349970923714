import React from 'react';
import 'url-polyfill';
import 'unorm';

import { Provider, connect } from 'react-redux';
import { renderer } from 'cms-modules';

import Startup from './startup';
import store from './state/store';

const { Site } = renderer;

const ConnectedStartup = connect(state => ({
  initialised: state.config.initialised,
}))(Startup);

const ConnectedSite = connect(state => ({
  globalStyling: state.config.config.global.globalStyling,
  path: state.router.path,
}))(Site);

export default (
  <Provider store={store}>
    <ConnectedStartup>
      <ConnectedSite />
    </ConnectedStartup>
  </Provider>
);
