/* eslint-env browser */
import { put, call, select, takeLatest, all } from 'redux-saga/effects';
import axios from 'axios';
import { actions as moduleActions, shared, modules } from 'cms-modules';
import { sortOptions } from 'cms-modules/src/shared/sortOptions/settings';

import { actions } from '../actions/config';
import { actions as flagActions } from '../actions/flags';
import { actions as indexedPagesActions } from '../actions/indexedPages';
import appSettings from '../../settings';
import staticConfig from '../../config';
import staticSettings from '../../avl.json';

const isModuleSearchableAndIndexable = mod =>
  mod.searchable &&
  mod.getIndexContent &&
  typeof mod.getIndexContent === 'function';

const indexSearchablePageModules = ({ pages }) =>
  pages
    .reduce(
      (list, { modules: mod = [], relativePath }) => [
        ...list,
        ...mod
          .filter(m => isModuleSearchableAndIndexable(modules[m.id] || {}))
          .map(({ config: cf, instanceId, id }) =>
            modules[id].getIndexContent({
              config: cf,
              relativePath,
              instanceId,
            }),
          ),
      ],
      [],
    )
    .reduce((list, rest) => [...list, ...rest], []);

function* resetIfExpired() {
  const {
    customerLogin: { actions: authenticationActions, selectors },
  } = shared;
  const loggedIn = yield select(state => selectors.tokenExists(state.shared));
  const isTokenValid = yield select(state =>
    selectors.isTokenValid(state.shared),
  );
  // need logged in check or site goes haywire!
  if (loggedIn && !isTokenValid) {
    yield put(authenticationActions.signOut());
  }
}

const injectSiteIcon = siteConfig => {
  const linkTag = document.createElement('link');
  const siteIconUrl = siteConfig.global && siteConfig.global.siteIcon;
  linkTag.setAttribute('rel', 'icon');
  linkTag.setAttribute('href', siteIconUrl);
  linkTag.setAttribute('type', 'image/x-icon');
  document.head.appendChild(linkTag);
};

const addGoogleAnalytics = siteConfig => {
  const {
    global: { analytics },
  } = siteConfig;
  if (analytics && analytics.googleAnalyticsTrackingId) {
    const globalSiteTagManagerScript = document.createElement('script');
    globalSiteTagManagerScript.async = true;
    globalSiteTagManagerScript.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', analytics.googleAnalyticsTrackingId);
    };
    globalSiteTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${
      analytics.googleAnalyticsTrackingId
    }`;
    document.head.appendChild(globalSiteTagManagerScript);
  }
};

function* loadConfigFromFile() {
  const indexedPagesContent = yield call(
    indexSearchablePageModules,
    staticConfig,
  );
  yield put(actions.loadConfig(staticConfig));
  yield put(indexedPagesActions.loadIndexedPages(indexedPagesContent));
}

function* loadConfigFromApi(url) {
  const result = yield call(axios.get, url);
  const config = result.data;

  const hasDualCurrency =
    config.global.inventory.currencyCode &&
    config.global.inventory.secondaryCurrency;

  yield put(
    shared.sessionPreferences.actions.updateMultipleSessionPreferences(
      [
        {
          key: 'searchSort',
          value: sortOptions[config.global.inventory.defaultSortKey],
        },
        {
          key: 'shortlistSort',
          value:
            sortOptions[config.global.inventory.defaultShortlistSortKey] ||
            null,
        },
        {
          key: 'language',
          value: config.global.inventory.locale,
        },
        hasDualCurrency && {
          key: 'currency',
          value: config.global.inventory.currencyCode,
        },
        {
          key: 'disableCurrencyPopup',
          value: config.global.inventory.disableCurrencyPopup,
        },
      ].filter(item => item && item.value),
    ),
  );
  yield put(actions.loadConfig(config));
  yield call(injectSiteIcon, config);
  yield call(addGoogleAnalytics, config);
  const indexedPagesContent = yield call(indexSearchablePageModules, config);
  yield put(indexedPagesActions.loadIndexedPages(indexedPagesContent));
  yield hasDualCurrency &&
    put(
      shared.currencyConversion.actions.getExchangeRates(
        config.global.inventory.currencyCode,
      ),
    );
}

const injectFontsCss = fontsCssUrl => {
  const fontsCss = document.createElement('link');
  fontsCss.setAttribute('href', fontsCssUrl);
  fontsCss.setAttribute('rel', 'stylesheet');
  fontsCss.setAttribute('type', 'text/css');
  document.head.appendChild(fontsCss);
};

function* loadLocalSettings() {
  const settings = {
    siteId: appSettings.localSiteId,
    ...staticSettings,
  };

  yield put(actions.loadSettings(settings));
  const fontsCssUrl = staticSettings.staticCss;
  yield call(injectFontsCss, fontsCssUrl);
  yield call(injectSiteIcon, staticConfig);
  yield call(addGoogleAnalytics, staticConfig);
  yield loadConfigFromFile();
}

function* loadSortSettings() {
  const { defaultSortKey } = staticConfig.global.inventory;
  yield put(
    shared.sessionPreferences.actions.updateSessionPreferences(
      'searchSort',
      sortOptions[defaultSortKey],
    ),
  );
}

function* loadRemoteConfig(siteId, locale, defaultLocale) {
  try {
    const territory = yield select(state => state.config.settings.territory);
    const configUrlForLanguage = territory
      ? `${appSettings.configBaseUrl}/${territory}/${siteId}/${locale}.json`
      : `${appSettings.configBaseUrl}/${siteId}/${locale}.json`;
    yield call(loadConfigFromApi, configUrlForLanguage);
  } catch (error) {
    /* eslint-disable no-console */
    yield call(
      console.warn,
      `Failed to load settings for site - ${error.message}`,
    );
    /* eslint-enable no-console */
    if (defaultLocale && defaultLocale !== locale) {
      yield put(
        shared.sessionPreferences.actions.updateSessionPreferences(
          'language',
          defaultLocale,
        ),
      );

      // eslint-disable-next-line no-use-before-define
      yield changeLanguage({ payload: defaultLocale });
    }
  }
}

function* loadPrintedSettings() {
  let domain;

  if (appSettings.configMarketByUrl) {
    if (window.location.pathname !== '') {
      const urlParts = window.location.pathname
        .replace(/^\/|\/$/g, '')
        .split('/');

      if (urlParts && urlParts.length) {
        const urlMarket = urlParts[0];
        domain = `${urlMarket}.${window.location.hostname.replace('www.', '')}`;
      }
    }
  } else {
    domain = window.location.hostname;
  }

  const settingsUrl = `${appSettings.configBaseUrl}/${domain}/avl.json`;
  try {
    const result = yield call(axios.get, settingsUrl);
    const { siteId, locale, staticCss } = result.data;

    const previousLocale = yield select(state => state.config.settings.locale);

    yield put(
      actions.loadSettings({
        ...result.data,
        locale: previousLocale || locale,
        defaultLocale: locale,
      }),
    );

    yield all([
      call(injectFontsCss, staticCss),
      call(loadRemoteConfig, siteId, previousLocale || locale, locale),
    ]);
  } catch (error) {
    /* eslint-disable no-console */
    yield call(
      console.warn,
      `Failed to load settings for site - ${error.message}`,
    );
    /* eslint-enable no-console */
  }
}

function* loadPreviewSettings() {
  const urlParams = new URLSearchParams(window.location.search);
  const languageFromQueryOrCache =
    urlParams.get('defaultLanguage') ||
    sessionStorage.getItem('defaultLanguage');
  const environmentFromQueryOrCache =
    urlParams.get('environment') || sessionStorage.getItem('environment');
  const siteIdFromQueryOrCache =
    urlParams.get('siteId') || sessionStorage.getItem('siteId');

  sessionStorage.setItem('defaultLanguage', languageFromQueryOrCache);
  sessionStorage.setItem('environment', environmentFromQueryOrCache);
  sessionStorage.setItem('siteId', siteIdFromQueryOrCache);

  const settingsUrl = `${
    appSettings.configBaseUrl
  }/${siteIdFromQueryOrCache}/avl.json`;
  try {
    const result = yield call(axios.get, settingsUrl);
    yield put(actions.loadSettings(result.data));
    const fontsCssUrl = `${
      appSettings.configBaseUrl
    }/${siteIdFromQueryOrCache}/fonts.css`;
    yield call(injectFontsCss, fontsCssUrl);
    const configUrlForLanguage = `${
      appSettings.configBaseUrl
    }/${siteIdFromQueryOrCache}/${languageFromQueryOrCache}.json`;
    yield call(loadConfigFromApi, configUrlForLanguage);

    if (!sessionStorage.getItem('initialNavigateCompleted')) {
      yield put(
        moduleActions.router.actions.navigate(window.location.pathname),
      );
      sessionStorage.setItem('initialNavigateCompleted', true);
    }
  } catch (error) {
    /* eslint-disable no-console */
    yield call(
      console.warn,
      `Failed to load settings for site - ${error.message}`,
    );
    /* eslint-enable no-console */
  }
}

function* setFlags() {
  yield put(flagActions.setFlags(appSettings.flags || {}));
}

function* changeLanguage({ payload: locale }) {
  const siteId = yield select(state => state.config.settings.siteId);
  const configSettings = yield select(state => state.config.settings);
  yield put(
    actions.loadSettings({
      ...configSettings,
      locale,
    }),
  );
  yield call(loadRemoteConfig, siteId, locale);
}

function* changeTerritory({ payload }) {
  const siteId = yield select(state => state.config.settings.siteId);
  const configSettings = yield select(state => state.config.settings);
  yield put(actions.loadSettings(configSettings));
  try {
    const newTerritoryUrl = `${appSettings.configBaseUrl}/${
      payload.territory
    }/${siteId}/${payload.language}.json`;
    yield call(loadConfigFromApi, newTerritoryUrl);
  } catch (error) {
    /* eslint-disable no-console */
    yield call(
      console.warn,
      `Failed to load settings for site - ${error.message}`,
    );
    /* eslint-enable no-console */
  }
}

function* loadSettings() {
  switch (appSettings.configSource) {
    case 'local':
      yield loadLocalSettings();
      yield setFlags();
      yield resetIfExpired();
      yield loadSortSettings();
      break;
    case 'printed':
      yield loadPrintedSettings();
      yield setFlags();
      yield resetIfExpired();
      break;
    case 'preview':
      yield loadPreviewSettings();
      break;
    default:
      /* eslint-disable no-console */
      yield call(console.warn, 'unknown settings source');
      /* eslint-enable no-console */
      break;
  }
}

export default function* configLoaderSaga() {
  yield call(loadSettings);
  yield takeLatest(
    moduleActions.language.constants.changeLanguage,
    changeLanguage,
  );
  yield takeLatest(
    moduleActions.territory.constants.changeTerritory,
    changeTerritory,
  );
}
