// @flow
import React from 'react';
import type { Node } from 'react';
import { Spinner } from 'cms-modules';

type Props = {
  initialised: boolean,
  children: Node,
};

export default function Startup({ initialised, children }: Props) {
  return initialised ? (
    children
  ) : (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
      }}
    >
      <Spinner size={50} colour={{ value: '#333333' }} />
    </div>
  );
}
