import { resolve } from 'url';
import {
  put,
  call,
  takeLatest,
  takeEvery,
  select,
  all,
} from 'redux-saga/effects';
import querystring from 'querystring';
import { actions, constants, matchGroup } from './reducer';
import { actions as filterActions } from '../../shared/filters/reducer';
import { get, localeConfigSerializer } from '../../helpers/http';
import { mapGroupAndFinance } from '../../shared/financeCalculator/effects';
import { convertPriceCurrency } from '../../shared/currencyConversion/helpers';

import {
  getModelGroupsBaseUrl,
  getAVLConfig,
  inventorySearchServiceUrl,
  getPlaceholderBrandImageUrl,
  appendLocaleQueryString,
  getFinanceStatus,
  getSearchMarket,
  getInventoryStatus,
} from '../../shared/selectors/settings';
import { actions as errorActions } from '../../shared/errors';
import { adjustPriceRangeFilterValues } from '../../helpers/filters';

const modelGroupsCache = {};

const down = ({ imageDomain, modelGroupName, position, modelSubTitle }) => ({
  modelGroupName: modelGroupName.trim(),
  imageUrl: imageDomain,
  modelSubTitle,
  position,
});

function* getInventoryGroup(action) {
  const {
    payload: filtersQuery,
    module: { page, instance },
  } = action;

  const currency = yield select(
    state => state.shared.sessionPreferences.currency,
  );
  const exchange = yield select(
    state => state.shared.currencyConversion.exchangeRates[currency],
  );
  const location = yield select(
    state => state.shared.sessionPreferences.location || {},
  );
  const sessionMarket = yield select(
    state => state.shared.sessionPreferences.market,
  );

  let filters = Object.keys(location)
    ? `${filtersQuery}&${querystring.stringify(location)}`
    : filtersQuery;

  filters = querystring.stringify(
    adjustPriceRangeFilterValues(querystring.parse(filters), exchange),
  );

  const { make, market, region, locale } = yield select(getAVLConfig);
  const searchMarket = yield select(getSearchMarket);
  const withLocaleQueryString = yield select(appendLocaleQueryString);
  const baseUrl = yield select(inventorySearchServiceUrl);
  const inventoryStatus = yield select(getInventoryStatus);
  const url =
    region && !sessionMarket
      ? `${baseUrl}groups/make/${make}/region/${region}?${filters}`
      : `${baseUrl}groups/make/${make}/market/${sessionMarket ||
          market}?${filters}`;

  try {
    const response = yield call(get, {
      url,
      config: localeConfigSerializer(
        withLocaleQueryString && locale,
        searchMarket
          ? { market: searchMarket, status: inventoryStatus }
          : { status: inventoryStatus },
      ),
    });

    const inventory = response.data.contents;

    const { total } = response.data;
    // we need to get current state in case of loading finance
    const modelGroupsInState = yield select(
      state => state.pages[page][instance].modelGroups,
    );

    yield put(actions.getInventoryGroupSuccess(page, instance, inventory));
    yield put(filterActions.getInventorySuccess(total));
    yield put(actions.convertPrices(page, instance));

    const financeStatus = yield select(getFinanceStatus);

    if (financeStatus) {
      yield put(
        actions.getFinance(page, instance, { inventory, modelGroupsInState }),
      );
    }
  } catch (error) {
    yield put(errorActions.setError(error));
  }
}

function* convertVehiclePrices(action) {
  const {
    module: { page, instance },
  } = action;
  let inventory = yield select(
    state => state.pages[page][instance].modelGroups,
  );

  const currency = yield select(
    state => state.shared.sessionPreferences.currency,
  );
  const exchange = yield select(
    state => state.shared.currencyConversion.exchangeRates[currency],
  );

  if (currency && exchange) {
    const exchangeRates = exchange.rates;

    inventory = inventory.map(m => ({
      ...m,
      minimumPrice: convertPriceCurrency(
        (m.originalPrice && m.originalPrice.minimumPrice) || m.minimumPrice,
        exchangeRates,
        currency,
        (m.originalPrice && m.originalPrice.currency) || m.currency,
      ),
      currency,
      originalPrice: m.originalPrice || {
        minimumPrice: m.minimumPrice,
        currency: m.currency,
      },
    }));

    yield put(actions.convertPricesSuccess(page, instance, inventory));
  }
}

function* getModelGroups(action) {
  const {
    module: { page, instance },
  } = action;
  const { locale: defaultLocale, make, country } = yield select(getAVLConfig);
  const placeholdingImage = yield select(getPlaceholderBrandImageUrl);
  const languageCode = yield select(
    state => state.shared.sessionPreferences.language,
  );
  const locale = languageCode || defaultLocale;

  const modelGroupsUrl = yield call(
    resolve,
    yield select(getModelGroupsBaseUrl),
    `${make.replace(' ', '')}/${country}/${locale}/model_search`,
  );

  const modelGroupsData =
    modelGroupsCache && modelGroupsCache[locale]
      ? [yield { ...modelGroupsCache[locale] }]
      : [
          call(get, {
            url: modelGroupsUrl,
          }),
        ];

  try {
    const [modelGroups] = yield all([...modelGroupsData]);

    if (!modelGroupsCache[locale]) {
      modelGroupsCache[locale] = modelGroups;
    }

    yield put(
      actions.getModelGroupsSuccess(page, instance, {
        modelGroups: modelGroups.data.map(down),
        placeholdingImage,
      }),
    );
  } catch (error) {
    // no-op
  }
}

function* getGroupFinance(group, page, instance) {
  try {
    const groupWithFinance = yield mapGroupAndFinance(group);
    yield put(actions.getFinanceSuccess(page, instance, groupWithFinance));
  } catch (error) {
    // noop leave group as is
  }
}

function* getFinance(action) {
  const {
    payload: { inventory, modelGroupsInState },
    module: { page, instance },
  } = action;

  const inventoryWithChangedFinanceModel = inventory.filter(i => {
    const existing = modelGroupsInState.find(g => matchGroup(g, i)) || {};
    return (
      !existing.finance || existing.financeVehicleId !== i.financeVehicleId
    );
  });

  yield all(
    inventoryWithChangedFinanceModel.map(group =>
      call(getGroupFinance, group, page, instance),
    ),
  );
}

export default function* SearchSaga() {
  yield takeLatest(constants.GET_INVENTORY_GROUP, getInventoryGroup);
  yield takeLatest(constants.GET_MODEL_GROUPS, getModelGroups);
  yield takeLatest(constants.CONVERT_PRICES, convertVehiclePrices);
  yield takeEvery(constants.GET_FINANCE, getFinance);
}
