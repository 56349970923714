// @flow
export type Range = {
  max: number,
  min: number,
};

type RangeFilters = {
  mileageRange: Range,
  yearRange: Range,
  priceRange: Range,
  monthlyPaymentRange: Range,
};

export type Filters = {
  ...RangeFilters,
  models: string[],
  variants: string[],
  bodystyles: string[],
  equipment: string[],
  colours: string[],
  transmissionTypes: string[],
  fuelTypes: string[],
  interiorShades: string[],
  interiorMaterials: string[],
  vehicleTypes: string[],
};

export type CheckboxFilter = {
  isDisabled: boolean,
  isChecked: boolean,
  value: string,
  id: string,
};

const formatCheckboxProps = (
  availableFilters,
  selectedFilters,
  id,
  filter,
) => ({
  isDisabled:
    !availableFilters.includes(filter) && !selectedFilters.includes(filter),
  isChecked: selectedFilters.includes(filter),
  value: filter,
  id,
});

const formatFilters = (filters, id) => {
  const {
    allFilters = {},
    availableFilters = {},
    selectedFilters = {},
  } = filters;
  const available = availableFilters[id] || [];
  const selected = selectedFilters[id] || [];
  const all = allFilters[id] || [];
  return all.map(filter =>
    formatCheckboxProps(available, selected, id, filter),
  );
};

// round ranges to nearest step value for slider compatibility
export const getInitialRangeValues = (
  step: number,
  range: Range = { min: 0, max: 0 },
) => [Math.round(range.min / step) * step, Math.round(range.max / step) * step];

// round ranges to nearest step value for slider compatibility
export const getFullRangeValues = (
  step: number,
  range: Range = { min: 0, max: 0 },
) => ({
  min: Math.round(range.min / step) * step,
  max: Math.round(range.max / step) * step,
});

export const calculateAge = (manufacturedYear: number) =>
  new Date().getFullYear() - manufacturedYear;
export const calculateYearRegistered = (age: number) =>
  new Date().getFullYear() - age;

// get age from registration year
export const getAgeFullRangeValues = (
  step: number,
  range: Range = { min: 0, max: 0 },
) => ({
  min: calculateAge(range.max),
  max: calculateAge(range.min),
});

// get age from registration year
export const getAgeInitialRangeValues = (
  step: number,
  range: Range = { min: 0, max: 0 },
) => [calculateAge(range.max), calculateAge(range.min)];

// Transforms required for the api
// The api request is filters?Model=XF&Model=XSF
// The api response is "models": ["XF", "XSF"]
export const transformCheckboxProps = (
  allFilters: Filters,
  availableFilters: Filters,
  selectedFilters: Filters,
) => ({
  models: {
    titleKey: 'searchSubHeaderModel',
    checkboxes: formatFilters(
      { allFilters, availableFilters: allFilters, selectedFilters },
      'models',
    ),
    id: 'models',
  },
  variants: {
    titleKey: 'searchSubHeaderVariant',
    checkboxes: formatFilters(
      { allFilters, availableFilters, selectedFilters },
      'variants',
    ),
    id: 'variants',
  },
  vehicleTypes: {
    checkboxes: formatFilters(
      { allFilters, availableFilters, selectedFilters },
      'vehicleTypes',
    ),
    id: 'vehicleTypes',
  },
  transmissions: {
    titleKey: 'searchSubHeaderTransmission',
    checkboxes: formatFilters(
      { allFilters, availableFilters, selectedFilters },
      'transmissionTypes',
    ),
    id: 'transmissionTypes',
  },
  engineSizes: {
    titleKey: 'searchSubHeaderEngineSize',
    checkboxes: formatFilters(
      { allFilters, availableFilters, selectedFilters },
      'engineSizes',
    ),
    id: 'engineSizes',
  },
  engine: {
    titleKey: 'searchSubHeaderEngine',
    checkboxes: formatFilters(
      { allFilters, availableFilters, selectedFilters },
      'fuelTypes',
    ),
    id: 'fuelTypes',
  },
  bodyStyles: {
    checkboxes: formatFilters(
      { allFilters, availableFilters, selectedFilters },
      'bodystyles',
    ),
    id: 'bodystyles',
  },
  exteriorColours: {
    titleKey: 'searchSubHeaderExteriorColours',
    checkboxes: formatFilters(
      { allFilters, availableFilters, selectedFilters },
      'colours',
    ),
    id: 'colours',
  },
  interiorShades: {
    titleKey: 'searchSubHeaderInteriorShades',
    checkboxes: formatFilters(
      { allFilters, availableFilters, selectedFilters },
      'interiorShades',
    ),
    id: 'interiorShades',
  },
  features: {
    checkboxes: formatFilters(
      { allFilters, availableFilters, selectedFilters },
      'equipment',
    ),
    id: 'equipment',
  },
});

// Transforms required for the api
// The api request is filters?MaxMileage=50444&MinMileage=1444
// The api response is "mileageRange": { "max": 50000, "min": 1500 }
export const transformRangeProps = (rangeFilters: Filters) => ({
  mileageRange: {
    id: 'mileageRange',
    name: ['MinMileage', 'MaxMileage'],
    step: 500,
    defaultValues: getInitialRangeValues(500, rangeFilters.mileageRange),
    fullRangeValues: getFullRangeValues(500, rangeFilters.mileageRange),
  },
  yearRange: {
    id: 'yearRange',
    name: ['MaxYear', 'MinYear'],
    step: 1,
    defaultValues: getAgeInitialRangeValues(1, rangeFilters.yearRange),
    fullRangeValues: getAgeFullRangeValues(1, rangeFilters.yearRange),
  },
  priceRange: {
    id: 'priceRange',
    name: ['MaxPrice', 'MinPrice'],
    step: 100,
    defaultValues: getInitialRangeValues(100, rangeFilters.priceRange),
    fullRangeValues: getFullRangeValues(100, rangeFilters.priceRange),
  },
  monthlyPaymentRange: {
    id: 'monthlyPaymentRange',
    name: ['MaxMonthlyPayment', 'MinMonthlyPayment'],
    step: 100,
    defaultValues: getInitialRangeValues(
      100,
      rangeFilters.monthlyPaymentRange || {},
    ),
    fullRangeValues: getFullRangeValues(
      100,
      rangeFilters.monthlyPaymentRange || {},
    ),
  },
});

export const distanceOptions = translations => [
  { label: translations.searchDistanceNationwide, value: 'all' },
  { label: '10 KM', value: 10 },
  { label: '20 KM', value: 20 },
  { label: '50 KM', value: 50 },
  { label: '100 KM', value: 100 },
];
