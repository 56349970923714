// @flow
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import uuid from 'uuid/v1';
import { HeadingTwo, fontStyleOverride } from '../../components/Global';
import SocialMediaShare from '../../components/SocialMediaShare';

const MobileMenuWrapper = styled.div`
  width: 100%;
  height: 100vh;
  ${({ backgroundColour }) =>
    backgroundColour && `background-color: ${backgroundColour}`};
  z-index: 100;
  position: fixed;
  top: 0;
  left: 100%;
  transition: left 0.6s;
  ${({ open }) => open && 'left: 0;'};
`;
const Close = styled.div`
  color: #ffffff;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
`;
const Links = styled.div`
  width: 100%;
  border-top: 1px solid #808080;
`;
const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 20px;
  border-bottom: 1px solid #808080;
`;
const Select = styled.select`
  ${({ font }) => font && fontStyleOverride(font)};
  outline: none;
  background-color: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 0 8px;
  ::-ms-expand {
    display: none;
  }
  option {
    color: #000000;
  }
  margin: 0 7.5px;
`;
const ShareIcon = styled.img`
  opacity: 0.6;
`;

type Props = {
  config: Object,
  languages: Object[],
  currencies: Object[],
  links: Object[],
  navigate: string => void,
  selectedLanguage: string,
  selectedCurrency: string,
  changeLanguage: Object => void,
  changeCurrency: Object => void,
  socialShare: Object,
  territories: Object[],
  selectedTerritory: string,
  changeTerritory: Object => void,
};
type State = {
  open: boolean,
};

function SocialMediaLinks({ config, socialShare }: Props) {
  return (
    <ActionsWrapper>
      <ShareIcon src={config.socialShareIconUrl} />
      <SocialMediaShare
        iconColour="#FFFFFF"
        shareUrl={window.location.href}
        socialShare={socialShare}
        height={0}
      />
    </ActionsWrapper>
  );
}

function SelectMenu({
  config,
  changeLanguage,
  selectedLanguage,
  languages,
  changeCurrency,
  selectedCurrency,
  currencies,
  onMenuToggle,
  territories,
  selectedTerritory,
  changeTerritory,
}: Props) {
  return (
    <div>
      <Select
        font={config.menuBarLinkFont}
        value={selectedTerritory}
        onChange={event =>
          changeTerritory({
            territory: territories.find(t => t.value === event.target.value)
              .value,
            language: selectedLanguage.value,
          })
        }
      >
        {territories.map(territory => (
          <option key={uuid()} value={territory.value}>
            {territory.value}
          </option>
        ))}
      </Select>
      <Select
        font={config.menuBarLinkFont}
        value={selectedLanguage.value}
        onChange={event =>
          changeLanguage(languages.find(l => l.value === event.target.value))
        }
      >
        {languages.map(lang => (
          <option key={uuid()} value={lang.value}>
            {lang.labelShorthand}
          </option>
        ))}
      </Select>
      <Select
        font={config.menuBarLinkFont}
        value={selectedCurrency ? selectedCurrency.value : ''}
        onChange={event => {
          changeCurrency(currencies.find(c => c.value === event.target.value));
          onMenuToggle(false);
        }}
      >
        {!selectedCurrency && <option value="">£ / $</option>}
        {currencies.map(curr => (
          <option key={uuid()} value={curr.value}>
            {curr.labelShorthand}
          </option>
        ))}
      </Select>
    </div>
  );
}

export default class MobileMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  onMenuToggle = (open: boolean) => {
    this.setState(() => ({ open }));
  };

  render = () => {
    const {
      config,
      languages,
      currencies,
      links,
      navigate,
      selectedLanguage,
      changeLanguage,
      selectedCurrency,
      changeCurrency,
      socialShare,
      onMenuToggle,
      territories,
      selectedTerritory,
      changeTerritory,
    } = this.props;
    const { open } = this.state;
    const linkOverride = () => {
      return `
        border-bottom: 1px solid #808080;
        padding-top: 10px;
        padding-bottom: 10px;
        ${fontStyleOverride(config.menuBarLinkFont)};
      `;
    };
    return (
      <Fragment>
        <div onClick={() => this.onMenuToggle(true)} role="button" tabIndex={0}>
          <img src={config.burgerMenuUrl} alt="menu button" />
        </div>
        <MobileMenuWrapper
          open={open}
          backgroundColour={
            config.backgroundColour && config.backgroundColour.value
          }
        >
          <Close
            onClick={() => this.onMenuToggle(false)}
            alt="menu close button"
          >
            X
          </Close>
          <Links>
            {links.map(link => (
              <HeadingTwo
                key={uuid()}
                styleOverride={() => `
                  ${linkOverride()};
                  padding: 15px 20px;
                `}
                onClick={() => {
                  navigate(link.link);
                  this.onMenuToggle(false);
                }}
              >
                {link.label}
              </HeadingTwo>
            ))}
          </Links>
          <ActionsWrapper>
            <SelectMenu
              config={config}
              changeLanguage={changeLanguage}
              selectedLanguage={selectedLanguage}
              languages={languages}
              changeCurrency={changeCurrency}
              selectedCurrency={selectedCurrency}
              currencies={currencies}
              onMenuToggle={() => onMenuToggle}
              territories={territories}
              selectedTerritory={selectedTerritory}
              changeTerritory={changeTerritory}
            />
          </ActionsWrapper>
          <SocialMediaLinks config={config} socialShare={socialShare} />
        </MobileMenuWrapper>
      </Fragment>
    );
  };
}
