// @flow
import { select, put, takeLatest, call } from 'redux-saga/effects';
import { post } from '../../helpers/http';
import {
  getLocale,
  getMake,
  pdfGeneratorServiceUrl,
} from '../selectors/settings';
import { localiseCurrency } from '../localisation/numbers';
import { constants, actions } from './reducer';

const co2Images = {
  'A+':
    'https://res.cloudinary.com/motortrak/image/upload/v1582564544/cms/global/co2A_plus.svg',
  A:
    'https://res.cloudinary.com/motortrak/image/upload/v1582564544/cms/global/co2A.svg',
  B:
    'https://res.cloudinary.com/motortrak/image/upload/v1582564544/cms/global/co2B.svg',
  C:
    'https://res.cloudinary.com/motortrak/image/upload/v1582564544/cms/global/co2C.svg',
  D:
    'https://res.cloudinary.com/motortrak/image/upload/v1582564544/cms/global/co2D.svg',
  E:
    'https://res.cloudinary.com/motortrak/image/upload/v1582564544/cms/global/co2E.svg',
  F:
    'https://res.cloudinary.com/motortrak/image/upload/v1582564544/cms/global/co2F.svg',
  G:
    'https://res.cloudinary.com/motortrak/image/upload/v1582564544/cms/global/co2G.svg',
};

const emmissionDataFromSRP = vehicle => ({
  power: `${vehicle.engineDetails.powerKw || '-'}`,
  fuel_type: `${vehicle.fuel}`,
  fuel_type_with_brackets: `( ${vehicle.fuel} )`,
  energy_sources: `${vehicle.engine || '-'}`,
  vehicle_weight: '-',
  combined_consumption: `${vehicle.engineDetails.combinedConsumption}`,
  urban_consumption: `${vehicle.engineDetails.consumptionExtraurban}`,
  extra_urban_consumption: `${vehicle.engineDetails.consumptionUrban}`,
  combined_co2: `${vehicle.engineDetails.co2EmissionsCombined}`,
  co2_image: `${co2Images[vehicle.engineDetails.emissionsClass]}`,
});

const emissionsDataFromVDP = vehicle => ({
  power: `${vehicle.powerOutput || '-'}`,
  fuel_type: `${vehicle.specification.fuelType}`,
  fuel_type_with_brackets: `( ${vehicle.specification.fuelType} )`,
  energy_sources: `${vehicle.specification.engine || '-'}`,
  vehicle_weight: '-',
  combined_consumption: `${
    vehicle.specification.engineDetails.combinedConsumption
  }`,
  urban_consumption: `${
    vehicle.specification.engineDetails.consumptionExtraurban
  }`,
  extra_urban_consumption: `${
    vehicle.specification.engineDetails.consumptionUrban
  }`,
  combined_co2: `${vehicle.specification.engineDetails.co2EmissionsCombined}`,
  co2_image: `${co2Images[vehicle.specification.engineDetails.emissionsClass]}`,
});

const emmissionsDataFromVehicle = (vehicle, make, date, pageSpecific) => ({
  brand: `${make}`,
  model: `${vehicle.modelGroupName} ${vehicle.modelVariant}`,
  annual_tax: '-',
  fuel_cost: '-',
  fuel_cost_per_unit: '-',
  electricity_cost: '-',
  electricity_consumption: '-',
  date,
  ...pageSpecific,
});

function* printVehicleInfo(action) {
  const { payload: vehicle } = action;
  const locale = yield select(getLocale);
  const make = yield select(getMake);

  const generateEquipmentElements = arr =>
    arr.reduce((acc, item) => acc.concat(`<div>${item}</div>`), '');
  const formattedEngine = vehicle.specification.capacity / 1000;
  const formattedPrice =
    vehicle.price.value !== 0
      ? localiseCurrency(vehicle.price.value, locale, vehicle.price.currency, 2)
      : 'POA';
  const equipmentArray =
    (vehicle.otherEquipment.length && vehicle.otherEquipment[0].equipment) ||
    [];
  const equipmentColOne = equipmentArray.slice(
    0,
    equipmentArray.length / 2 + 1,
  );
  const equipmentColTwo = equipmentArray.slice(
    equipmentArray.length / 2 + 1,
    equipmentArray.length,
  );

  const pdfData = {
    vehicle_title: `${vehicle.registration} ${vehicle.modelGroupName}`,
    vehicle_price: formattedPrice,
    vehicle_bodystyle: vehicle.specification.bodystyle,
    vehicle_fuel_type: vehicle.specification.fuelType,
    vehicle_transmission: vehicle.transmissionType,
    vehicle_odometer: `${vehicle.specification.odometer.display}`,
    vehicle_hand_drive:
      vehicle.wheelDrive === 'L' ? 'Left Hand Drive' : 'Right Hand Drive',
    vehicle_capacity: `${formattedEngine} Litres`,
    vehicle_year: vehicle.registration,
    vehicle_exterior: vehicle.specification.exterior,
    vehicle_interior: vehicle.specification.interior,
    vehicle_veneer: vehicle.specification.veneer,
    vehicle_equipment_one: generateEquipmentElements(equipmentColOne),
    vehicle_equipment_two: generateEquipmentElements(equipmentColTwo),
    retailer_url: window.location.origin,
    retailer_name: vehicle.retailerInformation.name,
    retailer_address_one: vehicle.retailerInformation.address[0],
    retailer_address_two: `${(vehicle.retailerInformation.address[1],
    vehicle.retailerInformation.address[2])}`,
    retailer_phone: vehicle.retailerInformation.phone,
    retailer_email: vehicle.retailerInformation.email,
  };

  try {
    const pdfGeneratorUrl = yield select(pdfGeneratorServiceUrl);
    const response = yield call(post, {
      url: `${pdfGeneratorUrl}${make}/print-vdp`,
      data: pdfData,
    });

    const responseArrayBuffer = new Int8Array(response.data.body.data);
    const bufferToBlob = new Blob([responseArrayBuffer.buffer], {
      type: 'application/pdf',
    });
    // IE11 / Safari doesnt like createObjectUrl
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(
        bufferToBlob,
        `${pdfData.vehicle_title}.pdf`,
      );
    } else {
      const url = window.URL.createObjectURL(bufferToBlob);
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = url;
      tempLink.setAttribute('download', `${pdfData.vehicle_title}.pdf`);
      window.document.body.appendChild(tempLink);
      tempLink.click();
      window.document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    }
    yield put(actions.printingSuccessful());
  } catch (error) {
    yield put(actions.printingFailure());
  }
}

function* printEmissionInfo(action) {
  const { payload: vehicle } = action;

  const date = new Date();
  const todaysDate = `${date.getDate()}/${date.getMonth() +
    1}/${date.getFullYear()}`;

  const make = yield select(getMake);

  const pdfData = emmissionsDataFromVehicle(
    vehicle,
    make,
    todaysDate,
    !vehicle.engine
      ? emissionsDataFromVDP(vehicle)
      : emmissionDataFromSRP(vehicle),
  );

  try {
    const pdfGeneratorUrl = yield select(pdfGeneratorServiceUrl);
    const response = yield call(post, {
      url: `${pdfGeneratorUrl}print-emissions`,
      data: pdfData,
    });

    const responseArrayBuffer = new Int8Array(response.data.body.data);
    const bufferToBlob = new Blob([responseArrayBuffer.buffer], {
      type: 'application/pdf',
    });
    // IE11 / Safari doesnt like createObjectUrl
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(
        bufferToBlob,
        `${pdfData.brand}-${pdfData.model}.pdf`,
      );
    } else {
      const url = window.URL.createObjectURL(bufferToBlob);
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = url;
      tempLink.setAttribute(
        'download',
        `${pdfData.brand}-${pdfData.model}.pdf`,
      );
      window.document.body.appendChild(tempLink);
      tempLink.click();
      window.document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    }
    yield put(actions.printingSuccessful());
  } catch (error) {
    yield put(actions.printingFailure());
  }
}

export default function* printingSaga() {
  yield takeLatest(constants.printVehicleInfo, printVehicleInfo);
  yield takeLatest(constants.printEmissionInfo, printEmissionInfo);
}
