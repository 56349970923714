/* eslint-disable */
// @flow
import { css } from 'styled-components';
import type { Theme } from './';

type screenSizeQueries = {
  small: css,
  medium: css,
  large: css,
  extraLarge: css,
};

export const screenSizeOptions = {
  small: 576,
  medium: 769,
  large: 992,
  extraLarge: 1200,
  xxl: 1400,
};

const generateMediaQueries = (widthType: 'min' | 'max') =>
  Object.keys(screenSizeOptions).reduce((acc, label) => {
    acc[label] = (...args: []) => css`
      @media screen and (${widthType}-width: ${screenSizeOptions[label]}px) {
        ${css(...args)};
      }
    `;
    return acc;
  }, {});

const media: { min: screenSizeQueries, max: screenSizeQueries } = {
  min: generateMediaQueries('min'),
  max: generateMediaQueries('max'),
};

const arrowComtentTemplate = () => `
  :after {
    content: '';
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    display: inline-block;
    height: 0.45em;
    left: 0;
    position: relative;
    top: 0.4em;
    transform: rotate(45deg);
    vertical-align: top;
    width: 0.45em;
    margin: -1px 0 0 5px;
  }
`;

const themeTemplate = (theme: Theme) => `
color: ${theme.colour};
text-transform: ${theme.textTransform};
letter-spacing: ${theme.characterSpacing};
font-size: ${theme.fontSize};
font-family: ${theme.fontFamily};
`;

export const mixins = {
  arrowComtentTemplate,
  themeTemplate,
};

export default media;
