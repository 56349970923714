// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import Waypoint from 'react-waypoint';

import media from '../../theme';
import { Button } from '../../components/Global';
import FooterActionBanner from '../../components/FooterActionBanner';
import { actions as searchFilterActions } from '../../shared/searchFilter';
import { actions as financeActions } from '../../shared/financeCalculator';
import { actions as filterActions, helpers } from '../../shared/filters';

import ModelMatrixGrid from '../../components/ModelMatrixGrid';
import FiltersContainer from '../../linked-modules/Filters/FiltersContainer';
import ContentWrapper from '../../components/ContentWrapper';
import MobileFilters from '../../components/MobileFilters';
import FiltersStickyLocationSearchBar from '../../components/FiltersStickyLocationSearchBar';
import { SpinnerWrapper } from '../../components/Spinner';

const Container = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  ${media.min.large`
    padding: ${({ padding }) => (padding ? `${padding}` : '16px')};
    flex-direction: row;
  `};
`;

const MobileContainer = styled.div`
  display: flex;
  ${media.min.large`
    display: none
  `};
`;

const RightColumn = styled.div`
  display: block;
  width: 100%;
`;
const LeftColumn = styled.div`
  display: none;
  ${media.min.large`
    display: flex;
    flex-direction: column;
    max-width: 350px;
  `};
`;

const DesktopOnly = styled.div`
  display: block;
  ${media.max.large`
    display: none;
  `};
`;

const FiltersContainerWrapper = styled.div`
  width: 280px;
`;

type State = {
  showStickySearch: boolean,
};

export default class ModelMatrixSearch extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showStickySearch: true,
      displayMobileFilterOptions: false,
    };
    this.filtersContainerRef = React.createRef();
  }

  componentDidMount = () => {
    // We call init to force a first run of the reducer
    // - there used to be a more generic solution,
    //   but it broke the builder
    const {
      actions: { init, getModelGroups, getInventoryGroup },
      history,
      dispatch,
      featureFlags,
      shared,
    } = this.props;
    const query = history.location.search.substring(1);
    this.setState({
      query,
    });
    init();
    dispatch(filterActions.initialiseFilters(query));
    getModelGroups();
    getInventoryGroup(query);
    if (
      featureFlags.finance &&
      !shared.financeCalculator.representativeExample
    ) {
      dispatch(financeActions.loadRepresentativeExample());
    }
  };

  componentDidUpdate(prevProps: Props) {
    const {
      history,
      shared,
      actions: {
        getModelGroups,
        getInventoryGroup,
        updateModelCounts,
        convertPrices,
      },
    } = this.props;
    const query = history.location.search.substring(1);

    if (
      this.state.query !== query ||
      shared.sessionPreferences.location !==
        prevProps.shared.sessionPreferences.location
    ) {
      this.setState({ query });
      getInventoryGroup(query);
    }

    if (
      shared.filters.availableFilterValues &&
      shared.filters.availableFilterValues.model &&
      prevProps.shared.filters.availableFilterValues !==
        shared.filters.availableFilterValues
    ) {
      updateModelCounts(shared.filters.availableFilterValues.model);
    }

    if (
      prevProps.shared.sessionPreferences.language !==
      shared.sessionPreferences.language
    ) {
      getModelGroups();
    }

    if (
      prevProps.shared.sessionPreferences.market !==
      shared.sessionPreferences.market
    ) {
      this.updateForMarket();
    }

    if (
      prevProps.shared.sessionPreferences.currency !==
        shared.sessionPreferences.currency ||
      prevProps.shared.currencyConversion.exchangeRates !==
        shared.currencyConversion.exchangeRates
    ) {
      convertPrices();
    }
  }

  toggle = () =>
    this.setState(({ displayMobileFilterOptions }) => ({
      displayMobileFilterOptions: !displayMobileFilterOptions,
    }));

  toggleModelFilter = (models: string) => {
    const { shared } = this.props;
    const selectedModelFilter =
      shared.filters.selectedFilters && shared.filters.selectedFilters.model;
    this.props.dispatch(
      filterActions.updateFilters([
        {
          key: 'model',
          value: helpers.newArray(selectedModelFilter || [], models),
        },
      ]),
    );

    this.props.dispatch(
      searchFilterActions.toggleCheckboxFilter({
        section: 'models',
        filter: models,
      }),
    );
  };

  toggleStickySearchBar = ({ currentPosition, previousPosition }: string) => {
    if (currentPosition && previousPosition) {
      this.setState(() => ({
        showStickySearch: currentPosition === 'below',
      }));
    }
  };

  resetFilters = () => {
    const { dispatch } = this.props;
    dispatch(filterActions.resetFilters());
    dispatch(filterActions.initialiseFilters());
  };

  onStickySearchBarReset = () => {
    this.filtersContainerRef.current.collapseFilters();
  };

  updateForMarket = () => {
    const {
      dispatch,
      actions: { getInventoryGroup },
    } = this.props;
    dispatch(filterActions.initialiseFilters(this.state.query));
    getInventoryGroup(this.state.query);
  };

  render() {
    const {
      shared,
      state: { modelGroups = [] },
      dispatch,
      config,
      marketInfo,
      globalStyling,
      featureFlags,
      linkedModules,
      pathByModule,
    } = this.props;
    const { showStickySearch } = this.state;
    const { footerActionButtonHeight = 41 } = config || {};

    const ResetBtn = () => (
      <Button
        onClick={this.resetFilters}
        text={config.translations.searchCTAReset}
        buttonStyle={
          globalStyling.uiElements.primaryButton &&
          globalStyling.uiElements.primaryButton.buttonStyle
        }
        applyStyle="secondary"
        styleOverride={() => media.max.large`
            width: 100%;
            min-width: 0;
            margin: 0;
            min-height: ${footerActionButtonHeight}px;`}
      />
    );

    const SearchBtn = () => (
      <Button
        onClick={() => dispatch(filterActions.searchWithFilters())}
        text={config.translations.searchCTASearch}
        buttonStyle={
          globalStyling.uiElements.primaryButton &&
          globalStyling.uiElements.primaryButton.buttonStyle
        }
        applyStyle="primary"
        styleOverride={() => media.max.large`
            width: 100%;
            min-width: 0;
            margin: 0;
            min-height: ${footerActionButtonHeight}px;`}
      />
    );

    const filtersConfig = Object.values(linkedModules).find(
      lm => lm.name === 'Filters',
    );

    const filters = (
      <FiltersContainer
        {...filtersConfig}
        featureFlags={featureFlags}
        globalStyling={globalStyling}
        toggle={this.toggle}
        locale={marketInfo.locale}
        currencyCode={marketInfo.currencyCode}
        displayMobileFilterOptions={this.state.displayMobileFilterOptions}
        ref={this.filtersContainerRef}
        shared={shared}
        dispatch={dispatch}
        searchCountryCode={marketInfo.searchCountryCode}
      />
    );

    return (
      <ContentWrapper contentWidth={globalStyling.contentWidth}>
        <SpinnerWrapper
          loaded={shared.filters.total >= 0}
          globalStyling={globalStyling}
        >
          {() => (
            <Container padding={config.matrixWrapperPadding}>
              <MobileContainer>
                <MobileFilters
                  filters={filters}
                  translations={config.translations}
                  activeFiltersCount={shared.filters.activeFiltersCount}
                  globalStyling={globalStyling}
                  backgroundColour={
                    filtersConfig.config.stylingConfig &&
                    filtersConfig.config.stylingConfig.backgroundColour
                  }
                  borderColour={
                    filtersConfig.config.stylingConfig &&
                    filtersConfig.config.stylingConfig.borderColour
                  }
                  config={config}
                  searchCountryCode={marketInfo.searchCountryCode}
                />
              </MobileContainer>
              <LeftColumn>
                <FiltersContainerWrapper>{filters}</FiltersContainerWrapper>
              </LeftColumn>
              <RightColumn>
                <ModelMatrixGrid
                  carModels={modelGroups.filter(m => m.count !== undefined)}
                  onItemClick={this.toggleModelFilter}
                  selectedModels={
                    shared.filters.selectedFilters &&
                    shared.filters.selectedFilters.model
                  }
                  translations={config.translations}
                  selectedIconColour={
                    config.carFilterSelectedIconColour &&
                    config.carFilterSelectedIconColour.value
                  }
                  locale={marketInfo.locale}
                  titleFont={config.modelTitleFont}
                  featureFlags={featureFlags}
                  numberOfColumns={config.numberOfColumns}
                  borderColour={config.borderColour}
                  showPrice={config.showPrice}
                  showSubtitle={config.showSubtitle}
                  showStockNumber={config.showStockNumber}
                  modelCardStyling={config.modelCardStyling}
                  gridTilePadding={config.gridTilePadding}
                  globalStyling={globalStyling}
                  titleBottomMargin={config.titleBottomMargin}
                  showFinancePopup={config.showFinancePopup}
                />
              </RightColumn>
            </Container>
          )}
        </SpinnerWrapper>
        <FooterActionBanner
          stickyFooterBackgroundColour={config.stickyFooterBackgroundColour}
          stickyFooterBorderTop={config.stickyFooterBorderTop}
          leftButton={ResetBtn}
          rightButton={SearchBtn}
        />
        {config.useStickySearchBar && (
          <DesktopOnly>
            {showStickySearch && (
              <FiltersStickyLocationSearchBar
                count={shared.filters.total}
                translations={config.translations}
                dropdownTheme={config.dropdown}
                display={showStickySearch}
                globalStyling={globalStyling}
                pathByModule={pathByModule}
                shared={shared}
                onReset={this.onStickySearchBarReset}
                config={config}
              />
            )}
            <Waypoint onPositionChange={this.toggleStickySearchBar} />
          </DesktopOnly>
        )}
      </ContentWrapper>
    );
  }
}
