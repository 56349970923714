// @flow
import React from 'react';
import styled from 'styled-components';

const ControlsWrapper = styled.div`
  height: 50px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 10px;
  margin-top: -5px;
`;

const ImageViewToggle = styled.button`
  width: 25px;
  height: 25px;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  margin: 20px;
  cursor: pointer;
  align-self: center;
`;

const ImageViewIcon = styled.img`
  width: 100%;
  filter: brightness(0) invert(1);
`;

type Props = {
  imagesIcon: string,
  toggleImageView: () => void,
};

export default ({ imagesIcon, toggleImageView }: Props) => {
  return (
    <ControlsWrapper>
      <ImageViewToggle onClick={toggleImageView}>
        <ImageViewIcon src={imagesIcon} />
      </ImageViewToggle>
    </ControlsWrapper>
  );
};
