/* eslint-disable import/no-unresolved, import/extensions  */
import { actions } from 'cms-modules';

const {
  router: { constants },
} = actions;

const initialState = {
  history: {},
  path: '/',
  data: null,
  navigationHistory: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.historyCreated: {
      return {
        ...state,
        history: action.payload,
      };
    }
    case constants.navigateSuccess: {
      const {
        pathname,
        hash,
        key,
        search: queryParams,
        ...rest
      } = action.payload;
      return {
        ...state,
        ...rest,
        queryParams: queryParams.substring(1),
        path: pathname,
        navigationHistory: [pathname, ...state.navigationHistory],
      };
    }
    case constants.navigateWithPayloadSuccess: {
      const {
        pathname,
        hash,
        key,
        search: queryParams,
        ...rest
      } = action.payload;
      return {
        ...state,
        ...rest,
        queryParams: queryParams.substring(1),
        path: pathname,
        data: action.payload.state,
        navigationHistory: [pathname, ...state.navigationHistory],
      };
    }
    default:
      return state;
  }
}
