// @flow
import React from 'react';
import styled from 'styled-components';

import SearchResultBlock from '../SearchResultBlock';
import SearchResultGrid from '../SearchResultGridBlock';

const Results = styled.div`
  width: 100%;
`;

type Props = {
  config: Object,
  results: Object,
  translations: Object,
  onMoreInfoClick: Function,
  filtersExpanded: boolean,
  compareVehicle: Function,
  shortlistVehicle: Function,
  getShortListedIconColour: Function,
  getCompareIconColour: Function,
  globalStyling: Object,
  visibility: Object,
  locale: string,
  vehicleModelTitleFontWeight: number,
  showGridTiles: boolean,
  badgeStyling: Object,
  pricingSectionImage: string,
  headerItemFont: Object,
  listViewImageWidth: string,
  resultItemBackgroundColour: Option,
  moreInfoButtonRightAlign: boolean,
  tileImageMaxWidth: number,
  financeCellHorizontalPadding: number,
  showFinancePopup: boolean,
  handleZeroPriceAsText: boolean,
  showNumberOfImagesInsteadOfViews: boolean,
  badgeWithStripUrl: string,
  badgeWithoutStripUrl: string,
  warrantyBadgeMonths: Number,
  warrantyBadgeMaxMileage: Number,
  optionalVehicleTitle: boolean,
  vehicleTitleFont: Object,
  vehicleSubTitleFont: Object,
  backgroundColour: Option,
  financeWidthOverride: number,
  financePaddingOverride: boolean,
  financeHeadingFont: Object,
  financeSubHeadingFont: Object,
  actionRowStyles: Object,
  vehicleSpecContainer: Object,
  showOnDemand?: boolean,
};
export default function ListView({
  config,
  results,
  translations,
  onMoreInfoClick,
  filtersExpanded,
  compareVehicle,
  shortlistVehicle,
  getShortListedIconColour,
  getCompareIconColour,
  globalStyling,
  visibility,
  locale,
  vehicleModelTitleFontWeight,
  showGridTiles,
  badgeStyling,
  pricingSectionImage,
  headerItemFont,
  listViewImageWidth,
  resultItemBackgroundColour,
  moreInfoButtonRightAlign,
  tileImageMaxWidth,
  financeCellHorizontalPadding,
  showFinancePopup,
  handleZeroPriceAsText,
  showNumberOfImagesInsteadOfViews,
  badgeWithStripUrl,
  badgeWithoutStripUrl,
  warrantyOdometer,
  warrantyBadgeMonths,
  warrantyBadgeMaxMileage,
  optionalVehicleTitle,
  vehicleTitleFont,
  vehicleSubTitleFont,
  financeWidthOverride,
  financePaddingOverride,
  financeHeadingFont,
  financeSubHeadingFont,
  actionRowStyles,
  vehicleSpecContainer,
  showOnDemand,
  icon360,
}: Props) {
  return (
    <Results>
      {results.map(vehicle =>
        showGridTiles ? (
          <SearchResultGrid
            config={config}
            key={vehicle.id}
            vehicle={vehicle}
            placeholdingImage={results.placeholdingImage}
            translations={translations}
            onMoreInfoClick={onMoreInfoClick}
            filtersExpanded={filtersExpanded}
            compareVehicle={compareVehicle}
            shortlistVehicle={shortlistVehicle}
            shortListedIconColour={getShortListedIconColour(vehicle.id)}
            compareIconColour={getCompareIconColour(vehicle.id)}
            globalStyling={globalStyling}
            visibility={visibility}
            locale={locale}
            badgeStyling={badgeStyling}
            headerItemFont={headerItemFont}
            listViewImageWidth={listViewImageWidth}
            handleZeroPriceAsText={handleZeroPriceAsText}
            showNumberOfImagesInsteadOfViews={showNumberOfImagesInsteadOfViews}
            imageWidth="35"
            icon360={icon360}
          />
        ) : (
          <SearchResultBlock
            key={vehicle.id}
            vehicle={vehicle}
            placeholdingImage={results.placeholdingImage}
            translations={translations}
            onMoreInfoClick={onMoreInfoClick}
            vehiclePriceSectionWidth={config.vehiclePriceSectionWidth || 24}
            displayImage={!filtersExpanded}
            compareVehicle={compareVehicle}
            shortlistVehicle={shortlistVehicle}
            shortListedIconColour={getShortListedIconColour(vehicle.id)}
            compareIconColour={getCompareIconColour(vehicle.id)}
            globalStyling={globalStyling}
            visibility={visibility}
            locale={locale}
            vehicleModelTitleFontWeight={vehicleModelTitleFontWeight}
            pricingSectionImage={pricingSectionImage}
            backgroundColour={resultItemBackgroundColour}
            financeCellHorizontalPadding={financeCellHorizontalPadding}
            showFinanceCellBorder={false}
            imageMaxWidth={tileImageMaxWidth || 400}
            moreInfoButtonRightAlign={moreInfoButtonRightAlign}
            showFinancePopup={showFinancePopup}
            badgeWithStripUrl={badgeWithStripUrl}
            badgeWithoutStripUrl={badgeWithoutStripUrl}
            warrantyBadgeMonths={warrantyBadgeMonths}
            warrantyBadgeMaxMileage={warrantyBadgeMaxMileage}
            optionalVehicleTitle={optionalVehicleTitle}
            vehicleTitleFont={vehicleTitleFont}
            vehicleSubTitleFont={vehicleSubTitleFont}
            financeWidthOverride={financeWidthOverride}
            financePaddingOverride={financePaddingOverride}
            financeHeadingFont={financeHeadingFont}
            financeSubHeadingFont={financeSubHeadingFont}
            actionRowStyles={actionRowStyles}
            vehicleSpecContainer={vehicleSpecContainer}
            showOnDemand={showOnDemand}
            onDemandFont={config.onDemandFont}
            icon360={icon360}
            showEmissions={config.showEmissions}
          />
        ),
      )}
    </Results>
  );
}
