// @flow
/* eslint-disable react/jsx-indent, no-return-assign */
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { format } from 'date-fns';

import VdpWell from '../../components/VdpWell';
import VdpWellRr from '../../components/VdpWellRr';
import VdpAccordion from '../../components/VdpAccordion';
import VdpHighlightedFeatures from '../../components/VdpHighlightedFeatures';
import VdpOtherEquipment from '../../components/VdpOtherEquipment';
import VdpSpecification from '../../components/VdpSpecification';
import VdpPerformance from '../../components/VdpPerformance';
import ApprovedBenefits from '../../components/ApprovedBenefits';
import VdpRetailer from '../../components/VdpRetailer';
import VdpRetailerMap from '../../components/VdpRetailerMap';
import VdpSimilarVehicles from '../../components/VdpSimilarVehicles';
import VdpAccessories from '../../components/VdpAccessories';
import VdpGalleryAndMyDeal from '../../components/VdpGalleryAndMyDeal';
import VdpGalleryAndHighlights from '../../components/VdpGalleryAndHighlights';
import VdpFeatures from '../../components/VdpFeatures';
import VdpOnDemandSection from '../../components/VdpOnDemandSection';

import media from '../../theme';
import { actions as compareActions } from '../../shared/compare';
import { actions as shortlistActions } from '../../shared/shortlist';
import { actions as routerActions } from '../../actions/router';
import { actions as financeActions } from '../../shared/financeCalculator';
import { actions as valuationActions } from '../../shared/vehicleValuations';
import { actions as searchFilterActions } from '../../shared/searchFilter';
import { actions as sessionActions } from '../../shared/sessionPreferences';
import { actions as filterActions } from '../../shared/filters';
import { actions as printingActions } from '../../shared/printing';
import { actions as leadsActions } from '../../shared/leadsAuthentication';

import vehicleToCarResult from '../../helpers/converters';

import type { Font, Option } from '../../types';
import ContentWrapper from '../../components/ContentWrapper';

const themeFromProps = ({
  typeface,
  fontSize,
  kerning,
  transform,
  colour,
}: Font): Object => ({
  fontFamily: typeface ? typeface.label : '',
  fontSize: fontSize ? `${fontSize}px` : '',
  characterSpacing: kerning ? `${kerning}px` : '',
  textTransform: transform || '',
  colour: colour ? colour.value : '',
});

export interface Config {
  translations: Object;
  subNavBGColour?: string;
  subNavFont?: Font;
  subNavBorderColour?: string;
  subNavChevronColour?: string;
  buttonPrimaryFont?: Font;
  buttonPrimaryBGColour?: Option;
  buttonSecondaryFont?: Font;
  buttonSecondaryBorderColour?: Option;
  headerIconColour: Option;
  showDeal: boolean;
  showContactbuttons: boolean;
  onDemandBtn: Object;
  showOnDemand: boolean;
  showApprovedBenefits: boolean;
  gridTile: Object;
  rotateGallery: boolean;
  showEnquiryButton: boolean;
  configurableRetailerMap: boolean;
  designList: Object;
  equipmentList: Object;
  specificationList: Object;
  featuresList: Object;
  financePricingTable: Object;
  hideActionButtons: boolean;
  badgeStyling: Object;
  featuredSpec: Object;
  badgeWithStripUrl: string;
  badgeWithoutStripUrl: string;
  warrantyBadgeMonths: Number;
  warrantyBadgeMaxMileage: Number;
}

type Props = {
  config: Config,
  actions: {
    get360Info: Function,
    getVehicleInfo: Function,
    getSimilarVehicles: Function,
    similarVehiclesNext: Function,
    similarVehiclesPrev: Function,
    similarVehiclesNextMobile: Function,
    similarVehiclesPrevMobile: Function,
    accessoriesNextMobile: Function,
    accessoriesPrevMobile: Function,
    updateMyDealProduct: Function,
    saveMyDeal: Function,
    printMyDeal: Function,
    printMyDealNoFinance: Function,
    changeFinanceProduct: Object => void,
  },
  state: Object,
  history: Object,
  preview: boolean,
  showShareButton: boolean,
  shared: Object,
  dispatch: Function,
  pathByModule: Function,
  history: Object,
  featureFlags: Object,
  globalStyling: Object,
  finance: Object,
  socialShare: Object,
  marketInfo: Object,
};

const WellContainerDesktop = styled.div`
  display: block;
  ${media.max.large`
    display:none;
  `};
`;
const WellContainerMobile = styled.div`
  display: none;
  ${media.max.large`
    display:block;
    padding: 0 5%;
  `};
`;
const GalleryWrapper = styled.div`
  margin-bottom: 18px;
`;

export default class VDP extends Component<Props, *> {
  state = {
    showStickyBanner: false,
  };

  componentDidUpdate = prevProps => {
    const {
      state,
      shared,
      actions,
      dispatch,
      history: {
        location: { pathname, state: payload = { finance: null } },
      },
      finance: globalFinance,
      state: { context },
    } = this.props;
    if (
      prevProps.shared.currencyConversion.exchangeRates !==
      shared.currencyConversion.exchangeRates
    ) {
      dispatch(
        actions.updateVehiclePrices({
          vehicle: state.vdpConfig,
          similarVehicles: state.similarVehicles,
        }),
      );
    }

    if (
      prevProps.shared.sessionPreferences.language !==
      shared.sessionPreferences.language
    ) {
      const vehicleId = pathname.match(/(\/)([0-9]*)(-)/)[2];
      const { finance } = payload;
      const financeProductToUse =
        context || (globalFinance && globalFinance.defaultProduct);
      actions.getVehicleInfo({
        vehicleId,
        finance,
        financeProductToUse,
      });
    }
  };

  componentDidMount = () => {
    const {
      history: {
        location: { pathname, state: payload = { finance: null } },
      },
      actions,
      dispatch,
      featureFlags,
      finance: globalFinance,
      state: { context },
      config,
      finance: financeState,
    } = this.props;

    if (featureFlags.finance) {
      dispatch(
        financeActions.resetFinanceProduct({
          type: financeState.defaultProduct,
        }),
      );
    }

    const vehicleId = pathname.match(/(\/)([0-9]*)(-)/)[2];
    const { finance } = payload;
    const financeProductToUse =
      context || (globalFinance && globalFinance.defaultProduct);
    actions.getVehicleInfo({
      vehicleId,
      finance,
      financeProductToUse,
    });
    dispatch(shortlistActions.getVehicles());
    if (!config.hideSimilarVehicle) {
      actions.getSimilarVehicles({ vehicleId, financeProductToUse });
    }
    if (featureFlags.finance) {
      dispatch(financeActions.loadFinanceOptions(vehicleId));
    }
    dispatch(leadsActions.getLeadsToken());
  };

  componentWillUnmount() {
    const { dispatch, history } = this.props;
    const searchResultsMatcher = /.*\/searchresults\/.*/i;
    const goingToSrp = searchResultsMatcher.test(history.location.pathname);
    if (!goingToSrp) {
      dispatch(
        sessionActions.updateSessionPreferences('infiniteScroll', false),
      );
    }
  }

  toggleStickyBanner = (showStickyBanner: boolean) =>
    this.setState(() => ({ showStickyBanner }));

  downpaymentRange = () => {
    const {
      config,
      shared,
      state: { vdpConfig, context },
      finance,
    } = this.props;
    const marketFinanceOptions =
      shared.financeCalculator.financeOptions[
        context || (finance && finance.defaultProduct)
      ];
    if (marketFinanceOptions) {
      const { downpayment } = (
        (vdpConfig && vdpConfig.finance && vdpConfig) || { finance: {} }
      ).finance;
      const defaultDownpayment =
        downpayment !== null && downpayment !== undefined
          ? downpayment
          : marketFinanceOptions.downpaymentRange.max;
      return {
        id: 'downpaymentRange',
        name: 'downpayment',
        title: config.translations.financeDeposit,
        unit: '€',
        marks: {},
        step: 500,
        defaultValue: defaultDownpayment,
        fullRangeValues: {
          min: marketFinanceOptions.downpaymentRange.min,
          max: marketFinanceOptions.downpaymentRange.max,
        },
      };
    }
    return {};
  };

  termsRange = () => {
    const {
      config,
      shared,
      state: { vdpConfig, context },
      finance,
    } = this.props;
    const marketFinanceOptions =
      shared.financeCalculator.financeOptions[
        context || (finance && finance.defaultProduct)
      ];
    if (marketFinanceOptions) {
      const sortedOptions = (marketFinanceOptions.durationOptions || []).sort(
        (a, b) => a - b,
      );
      const exampleDefaultTerm =
        shared.financeCalculator.financeProductExample[context] &&
        shared.financeCalculator.financeProductExample[context].duration;
      const defaultTerm =
        ((vdpConfig && vdpConfig.finance && vdpConfig) || { finance: {} })
          .finance.duration || sortedOptions[0];
      return {
        id: 'termsRange',
        name: 'term',
        title: config.translations.term,
        unit: config.translations.months,
        step: 0,
        marks: sortedOptions.reduce((ac, cv) => ({ ...ac, [cv]: `${cv}` }), {}),
        defaultValue: exampleDefaultTerm || defaultTerm,
        fullRangeValues: {
          min: sortedOptions[0],
          max: sortedOptions[sortedOptions.length - 1],
        },
      };
    }
    return {};
  };

  render() {
    const {
      state: {
        vdpConfig,
        placeholdingImage,
        similarVehicles,
        vehiclesInView,
        similarVehiclePage,
        similarVehiclePageTotal,
        selectedVehicleMobile,
        similarVehiclePageMobile,
        similarVehiclePageTotalMobile,
        accessories,
        accessoriesInView,
        accessoriesPage,
        accessoriesPageTotal,
        selectedAccessoryMobile,
        accessoriesPageMobile,
        accessoriesPageTotalMobile,
        context,
        printing,
      },
      actions: {
        accessoriesNextMobile,
        accessoriesPrevMobile,
        similarVehiclesNext: paginateNext,
        similarVehiclesPrev: paginatePrev,
        similarVehiclesNextMobile: paginateNextMobile,
        similarVehiclesPrevMobile: paginatePrevMobile,
        updateMyDealProduct,
        updateMyDealWithPartExchange,
        saveMyDeal,
        printMyDeal,
        changeFinanceProduct,
      },
      dispatch,
      shared,
      pathByModule,
      preview,
      config,
      featureFlags,
      globalStyling,
      marketInfo,
      finance: globalFinance,
      socialShare,
    } = this.props;
    const sharedShortListedVehicles =
      shared.shortlist && shared.shortlist.vehicles;
    const sharedCompareVehicles = shared.compare && shared.compare.vehicles;
    const goToAnchor = element =>
      document
        .getElementById(element)
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    const defaultProductFinanceExample = preview
      ? {}
      : shared.financeCalculator.financeProductExample[
          context || (globalFinance && globalFinance.defaultProduct)
        ];
    const isListed = (id: string, vehicles: Vehicle[]) =>
      vehicles.find(vehicle => vehicle.id === (id || {}));
    const shortListedIconColour = isListed(
      (vdpConfig || {}).id,
      sharedShortListedVehicles,
    )
      ? config.iconColour && config.iconColour.value
      : '#ACACAC';
    const compareIconColour = isListed(
      (vdpConfig || {}).id,
      sharedCompareVehicles,
    )
      ? config.iconColour && config.iconColour.value
      : '#ACACAC';
    const vehicleDetails = vdpConfig;
    const { brandLogoUrl, pdfLineColor, translations } = config;
    const pdfConfig = { brandLogoUrl, pdfLineColor, translations };
    const goToModule = (name, retailerId) =>
      dispatch(
        routerActions.navigateWithQuery(pathByModule(name), { retailerId }),
      );
    const bookTestDrive = (name, id) =>
      dispatch(routerActions.navigate(`${pathByModule(name)}/${id}`));
    const resetFilters = () => {
      this.props.dispatch(
        sessionActions.updateSessionPreferences(
          'resetSearchResultsPageNumber',
          true,
        ),
      );
      this.props.dispatch(searchFilterActions.resetFilters());
      this.props.dispatch(filterActions.resetFilters());
    };
    const buttonBGColour =
      config.buttonPrimaryBGColour && config.buttonPrimaryBGColour.value;
    const buttonSecondaryBorderColour =
      config.buttonSecondaryBorderColour &&
      config.buttonSecondaryBorderColour.value;
    const iconColour = config.iconColour && config.iconColour.value;
    const buttonTheme = {
      ...themeFromProps(config.buttonPrimaryFont || {}),
      backgroundColour: buttonBGColour,
    };
    const secondaryButtonTheme = {
      ...themeFromProps(config.buttonSecondaryFont || {}),
      borderColour: buttonSecondaryBorderColour,
    };
    const financeProducts =
      globalFinance &&
      globalFinance.productTypes.map(pt => ({
        text: globalFinance.translations[`finance${pt}Label`], // config.translations.pcpLabel,
        onClick: () =>
          changeFinanceProduct({
            vehicleId: vdpConfig.id,
            productType: pt,
          }),
        path: pt,
      }));

    const printVehiclePdf = () =>
      dispatch(printingActions.printVehicleInfo(vehicleDetails));
    const printingState = shared.printing;
    return vehicleDetails ? (
      <Fragment>
        <Helmet>
          <title>
            {config.hideYear
              ? vehicleDetails.description
              : `${vehicleDetails.registration} ${vehicleDetails.description}`}
          </title>
        </Helmet>
        <GalleryWrapper>
          {config.showGalleryAndHighlights ? (
            <ContentWrapper contentWidth={globalStyling.contentWidth}>
              <VdpGalleryAndHighlights
                printVehiclePdf={printVehiclePdf}
                printingState={printingState}
                config={config}
                badgeStyling={config.badgeStyling}
                socialShare={socialShare}
                socialShareConfig={config.socialShareConfig}
                globalStyling={globalStyling}
                featureFlags={featureFlags}
                shortListedIconColour={shortListedIconColour}
                compareIconColour={compareIconColour}
                borderColour={config.borderColour}
                vehicleImages={{
                  slides: vehicleDetails.images.map(i => ({
                    id: i,
                    image: i,
                  })),
                }}
                rotateGallery={config.rotateGallery}
                placeholdingImage={placeholdingImage}
                vehicleInfo={vehicleDetails}
                translations={config.translations}
                compareVehicle={vehicle =>
                  dispatch(
                    compareActions.toggleVehicle(vehicleToCarResult(vehicle)),
                  )
                }
                shortlistVehicle={vehicle =>
                  dispatch(
                    shortlistActions.toggleVehicle(vehicleToCarResult(vehicle)),
                  )
                }
                bookTestDrive={() =>
                  bookTestDrive('TestDrive', vehicleDetails.id)
                }
                reserveThisVehicle={() =>
                  bookTestDrive('TestDrive', vehicleDetails.id)
                }
                makeAnEnquiry={() =>
                  dispatch(
                    routerActions.navigate(`/enquiry/${vehicleDetails.id}`),
                  )
                }
                makeATestDriveRequest={() =>
                  dispatch(
                    routerActions.navigate(`/testdrive/${vehicleDetails.id}`),
                  )
                }
                makeAReservation={() =>
                  dispatch(
                    routerActions.navigate(`/reserve/${vehicleDetails.id}`),
                  )
                }
                finance={vehicleDetails.finance}
                requestFinanceProductUpdate={(
                  duration,
                  downpayment,
                  additionalServices,
                  type,
                ) =>
                  dispatch(
                    financeActions.loadFinanceProduct({
                      duration,
                      downpayment,
                      additionalServices,
                      id: vehicleDetails.id,
                      type,
                    }),
                  )
                }
                updateMyDeal={updateMyDealProduct}
                updateMyDealWithPartExchange={updateMyDealWithPartExchange}
                saveMyDeal={() =>
                  saveMyDeal({
                    vehicleDetails,
                    vdpConfig: config,
                  })
                }
                termsRange={this.termsRange()}
                downpaymentRange={this.downpaymentRange()}
                financeProductExample={defaultProductFinanceExample}
                showStickyBanner={this.state.showStickyBanner}
                toggleStickyBanner={this.toggleStickyBanner}
                buttonTheme={buttonTheme}
                buttonSecondaryTheme={secondaryButtonTheme}
                iconColour={iconColour}
                printMyDeal={finance =>
                  printMyDeal({
                    vehicle: vehicleDetails,
                    translations: globalFinance && globalFinance.translations,
                    finance,
                    pdfConfig,
                  })
                }
                getValuationMakes={() =>
                  dispatch(valuationActions.loadVehicleMakes())
                }
                valuationMakes={shared.vehicleValuations.makes || []}
                getValuationModels={makeId =>
                  dispatch(valuationActions.loadVehicleModels(makeId))
                }
                valuationModels={shared.vehicleValuations.models || []}
                getValuationVersions={(makeId, modelId) =>
                  dispatch(
                    valuationActions.loadVehicleVersions(makeId, modelId),
                  )
                }
                valuationVersions={shared.vehicleValuations.versions || []}
                getValuationYears={(makeId, modelId, versionId) => {
                  dispatch(
                    valuationActions.loadVehicleRegistrationYears(
                      makeId,
                      modelId,
                      versionId,
                    ),
                  );
                }}
                valuationYears={shared.vehicleValuations.years || []}
                getValuationMonths={(makeId, modelId, year) => {
                  dispatch(
                    valuationActions.loadVehicleRegistrationMonths(
                      makeId,
                      modelId,
                      year,
                    ),
                  );
                }}
                valuationMonths={shared.vehicleValuations.months || []}
                getVehicleValuation={(
                  make,
                  model,
                  versionId,
                  year,
                  month,
                  odometer,
                ) => {
                  dispatch(
                    valuationActions.getVehicleValuation(
                      make,
                      model,
                      versionId,
                      year,
                      month,
                      odometer,
                      vehicleDetails,
                    ),
                  );
                }}
                vehicleValuation={shared.vehicleValuations.valuation}
                vehicleValuationRequestLimit={
                  shared.vehicleValuations.valuationRequestLimit
                }
                editVehicleValuation={vehicleId =>
                  dispatch(valuationActions.editVehicleValuation(vehicleId))
                }
                cancelEditVehicleValuation={() =>
                  dispatch(valuationActions.cancelEditVehicleValuation())
                }
                valuationError={shared.vehicleValuations.networkError}
                subHeaderType={config.subHeaderType}
                vehicleHeadingColour={config.vehicleNameHeadingColour}
                vehicleHeadingPadding={config.vehicleNameHeadingPadding}
                vehicleHeadingMargin={config.vehicleHeadingMargin}
                heartMobileNoPadding={config.heartMobileNoPadding}
                locale={marketInfo.locale}
                financeProducts={financeProducts}
                resetFinanceProduct={() =>
                  dispatch(
                    financeActions.resetFinanceProduct({
                      type: globalFinance && globalFinance.defaultProduct,
                    }),
                  )
                }
                context={
                  context || (globalFinance && globalFinance.defaultProduct)
                }
                contentWidth={globalStyling.contentWidth}
                goToAnchor={goToAnchor}
                showStickyBannerDealerLocation={
                  config.showStickyBannerDealerLocation
                }
                stickyBannerStyling={config.stickyBannerStyling}
                showStickyBannerContactUsButton={
                  config.showStickyBannerContactUsButton
                }
                hideVertSeparators={config.hideVertSeparators}
                imageCarousel={config.imageCarousel}
                showShareButton={config.showShareButton}
                handleZeroPriceAsText={config.handleZeroPriceAsText}
              />
            </ContentWrapper>
          ) : (
            <ContentWrapper contentWidth={globalStyling.contentWidth}>
              <VdpGalleryAndMyDeal
                globalStyling={globalStyling}
                featureFlags={featureFlags}
                shortListedIconColour={shortListedIconColour}
                compareIconColour={compareIconColour}
                vehicleImages={{
                  slides: vehicleDetails.images.map(i => ({
                    id: i,
                    image: i,
                  })),
                }}
                rotateGallery={config.rotateGallery}
                placeholdingImage={placeholdingImage}
                vehicleInfo={vehicleDetails}
                translations={config.translations}
                showShareButton={config.showShareButton}
                compareVehicle={vehicle =>
                  dispatch(
                    compareActions.toggleVehicle(vehicleToCarResult(vehicle)),
                  )
                }
                shortlistVehicle={vehicle =>
                  dispatch(
                    shortlistActions.toggleVehicle(vehicleToCarResult(vehicle)),
                  )
                }
                bookTestDrive={() =>
                  bookTestDrive('TestDrive', vehicleDetails.id)
                }
                reserveThisVehicle={() =>
                  bookTestDrive('TestDrive', vehicleDetails.id)
                }
                makeAnEnquiry={() =>
                  dispatch(
                    routerActions.navigate(`/enquiry/${vehicleDetails.id}`),
                  )
                }
                finance={vehicleDetails.finance}
                requestFinanceProductUpdate={(
                  duration,
                  downpayment,
                  additionalServices,
                  type,
                ) =>
                  dispatch(
                    financeActions.loadFinanceProduct({
                      duration,
                      downpayment,
                      additionalServices,
                      id: vehicleDetails.id,
                      type,
                    }),
                  )
                }
                updateMyDeal={updateMyDealProduct}
                updateMyDealWithPartExchange={updateMyDealWithPartExchange}
                saveMyDeal={() =>
                  saveMyDeal({
                    vehicleDetails,
                    vdpConfig: config,
                  })
                }
                termsRange={this.termsRange()}
                downpaymentRange={this.downpaymentRange()}
                financeProductExample={defaultProductFinanceExample}
                showStickyBanner={this.state.showStickyBanner}
                toggleStickyBanner={this.toggleStickyBanner}
                buttonTheme={buttonTheme}
                buttonSecondaryTheme={secondaryButtonTheme}
                iconColour={iconColour}
                printMyDeal={finance =>
                  printMyDeal({
                    vehicle: vehicleDetails,
                    translations: globalFinance && globalFinance.translations,
                    finance,
                    pdfConfig,
                  })
                }
                getValuationMakes={() =>
                  dispatch(valuationActions.loadVehicleMakes())
                }
                valuationMakes={shared.vehicleValuations.makes || []}
                getValuationModels={makeId =>
                  dispatch(valuationActions.loadVehicleModels(makeId))
                }
                valuationModels={shared.vehicleValuations.models || []}
                getValuationVersions={(makeId, modelId) =>
                  dispatch(
                    valuationActions.loadVehicleVersions(makeId, modelId),
                  )
                }
                valuationVersions={shared.vehicleValuations.versions || []}
                getValuationYears={(makeId, modelId, versionId) => {
                  dispatch(
                    valuationActions.loadVehicleRegistrationYears(
                      makeId,
                      modelId,
                      versionId,
                    ),
                  );
                }}
                valuationYears={shared.vehicleValuations.years || []}
                getValuationMonths={(makeId, modelId, year) => {
                  dispatch(
                    valuationActions.loadVehicleRegistrationMonths(
                      makeId,
                      modelId,
                      year,
                    ),
                  );
                }}
                valuationMonths={shared.vehicleValuations.months || []}
                getVehicleValuation={(
                  make,
                  model,
                  versionId,
                  year,
                  month,
                  odometer,
                ) => {
                  dispatch(
                    valuationActions.getVehicleValuation(
                      make,
                      model,
                      versionId,
                      year,
                      month,
                      odometer,
                      vehicleDetails,
                    ),
                  );
                }}
                vehicleValuation={shared.vehicleValuations.valuation}
                vehicleValuationRequestLimit={
                  shared.vehicleValuations.valuationRequestLimit
                }
                editVehicleValuation={vehicleId =>
                  dispatch(valuationActions.editVehicleValuation(vehicleId))
                }
                cancelEditVehicleValuation={() =>
                  dispatch(valuationActions.cancelEditVehicleValuation())
                }
                valuationError={shared.vehicleValuations.networkError}
                showDeal={config.showDeal}
                showContactbuttons={config.showContactbuttons}
                onDemandBtn={config.onDemandBtn}
                showOnDemand={config.showOnDemand}
                makeATestDriveEnquiry={() =>
                  dispatch(
                    routerActions.navigate(
                      `${config.testdriveCtaPath || 'enquiry'}/${
                        vehicleDetails.id
                      }`,
                    ),
                  )
                }
                makeAnOnDemandEnquiry={() =>
                  dispatch(
                    routerActions.navigate(
                      `${config.onDemandCtaPath}/${vehicleDetails.id}`,
                    ),
                  )
                }
                makeAReserveEnquiry={() =>
                  dispatch(
                    routerActions.navigate(
                      `${config.reserveCtaPath || 'enquiry'}/${
                        vehicleDetails.id
                      }`,
                    ),
                  )
                }
                subHeaderType={config.subHeaderType}
                vehicleHeadingColour={config.vehicleNameHeadingColour}
                vehicleHeadingPadding={config.vehicleNameHeadingPadding}
                vehicleHeadingMargin={config.vehicleNameHeadingMargin}
                heartMobileNoPadding={config.heartMobileNoPadding}
                myDealButtonContainerStyles={
                  (config.myDealButtonContainerStyles = {})
                }
                locale={marketInfo.locale}
                financeProducts={financeProducts}
                resetFinanceProduct={() =>
                  dispatch(
                    financeActions.resetFinanceProduct({
                      type: globalFinance && globalFinance.defaultProduct,
                    }),
                  )
                }
                context={
                  context || (globalFinance && globalFinance.defaultProduct)
                }
                financeTranslations={
                  globalFinance && globalFinance.translations
                }
                financeHeadingDisplay={
                  globalFinance && globalFinance.financeHeadingDisplay
                }
                printing={printing}
                showFinanceDescriptions={
                  globalFinance && globalFinance.showProductDescriptions
                }
                financePricingTable={config.financePricingTable}
                featuredSpec={config.featuredSpec}
                hideActionButtons={config.hideActionButtons}
                approvedImage={config.pricingSectionImage}
                galleryWidth={config.galleryWidth}
                bottomTextFont={config.bottomTextFont}
                socialShare={socialShare}
                stickyBannerStyling={config.stickyBannerStyling}
                config={config}
                badgeWithStripUrl={config.badgeWithStripUrl}
                badgeWithoutStripUrl={config.badgeWithoutStripUrl}
                warrantyBadgeMonths={config.warrantyBadgeMonths}
                warrantyBadgeMaxMileage={config.warrantyBadgeMaxMileage}
                hideYear={config.hideYear}
                featureSpecificationData={config.featureSpecificationData}
                imageCarousel={config.imageCarousel}
              />
            </ContentWrapper>
          )}
        </GalleryWrapper>

        {config.showOnDemand && (
          <VdpOnDemandSection
            config={config}
            globalStyling={globalStyling}
            translations={config.translations}
            navigate={url => dispatch(routerActions.navigate(url))}
          />
        )}

        {!config.showGalleryAndHighlights ? (
          <Fragment>
            <WellContainerDesktop>
              <VdpWell
                featureFlags={featureFlags}
                configuration={vehicleDetails}
                translations={config.translations}
                showRegistration={config.showRegistration}
                showOdometerInformation={config.showOdometerInformation}
                contentWidth={globalStyling.contentWidth}
                headerFont={config.wellHeaderFont}
                specificationList={config.specificationList}
                featuresList={config.featuresList}
                remarksList={config.remarksList}
                equipmentList={config.equipmentList}
                wellWrapper={config.wellWrapperFull}
                showEngineCapacity={config.showEngineCapacity}
                locale={marketInfo.locale}
                featuredSpec={config.featuredSpec}
              />
            </WellContainerDesktop>
            <WellContainerMobile>
              {vehicleDetails.highlightedFeatures && (
                <VdpAccordion
                  title={config.translations.vdpHighlightedFeaturesHeader}
                >
                  <VdpHighlightedFeatures
                    features={vehicleDetails.highlightedFeatures}
                    columns={1}
                  />
                </VdpAccordion>
              )}
              {featureFlags.vdpFeatures &&
                (vehicleDetails.specification.engineDetails
                  .combinedConsumption > 0 ||
                  vehicleDetails.specification.warranty
                    .remainingWarrantyWholeYears > 0) && (
                  <VdpAccordion title={config.translations.vdpFeaturesHeader}>
                    <VdpFeatures
                      combinedConsumption={
                        vehicleDetails.specification.engineDetails
                          .combinedConsumption
                      }
                      specification={vehicleDetails.specification}
                      featuresList={config.featuresList}
                      translations={config.translations}
                    />
                  </VdpAccordion>
                )}
              {vehicleDetails.otherEquipment &&
                vehicleDetails.otherEquipment.length > 0 && (
                  <VdpAccordion
                    title={config.translations.vdpOtherEquipmentHeader}
                  >
                    <VdpOtherEquipment
                      equipment={vehicleDetails.otherEquipment}
                      columns={1}
                    />
                  </VdpAccordion>
                )}
              {vehicleDetails.specification && !config.featuredSpec && (
                <VdpAccordion
                  title={config.translations.vdpSpecificationHeader}
                >
                  <VdpSpecification
                    specification={vehicleDetails.specification}
                    translations={config.translations}
                    showOdometerInformation={config.showOdometerInformation}
                    registration={
                      (vehicleDetails.registrationDate &&
                        format(
                          vehicleDetails.registrationDate,
                          'DD-MM-YYYY',
                        )) ||
                      vehicleDetails.registration
                    }
                    showRegistration={config.showRegistration}
                    vehicleInfo={vehicleDetails}
                    locale={marketInfo.locale}
                  />
                </VdpAccordion>
              )}
              {config.featuredSpec &&
                vehicleDetails.specification.engineDetails.powerKw &&
                vehicleDetails.specification.engineDetails
                  .consumptionExtraurban &&
                vehicleDetails.specification.engineDetails
                  .combinedConsumption &&
                vehicleDetails.specification.engineDetails
                  .co2EmissionsCombined &&
                vehicleDetails.specification.engineDetails.consumptionUrban && (
                  <VdpAccordion
                    title={config.translations.vdpSpecificationHeader}
                  >
                    <VdpSpecification
                      specification={vehicleDetails.specification}
                      translations={config.translations}
                      featuredSpec={config.featuredSpec}
                      vehicleInfo={vehicleDetails}
                      locale={marketInfo.locale}
                      styles={config.specificationList}
                    />
                  </VdpAccordion>
                )}
              {vehicleDetails.performance && (
                <VdpAccordion title={config.translations.vdpPerformanceHeader}>
                  <VdpPerformance
                    performance={vehicleDetails.performance}
                    isMobile
                  />
                </VdpAccordion>
              )}
            </WellContainerMobile>
          </Fragment>
        ) : (
          <Fragment>
            <VdpWellRr
              vehicle={vehicleDetails}
              translations={config.translations}
              designList={config.designList}
              equipmentList={config.equipmentList}
              specificationList={config.specificationList}
              featuresList={config.featuresList}
              globalStyling={globalStyling}
              infoIcon={config.infoIcon}
            />
          </Fragment>
        )}
        {accessories.length > 0 && (
          <VdpAccessories
            accessories={accessories}
            accessoriesInView={accessoriesInView}
            totalPages={accessoriesPageTotal}
            currentPage={accessoriesPage}
            mobileSelected={selectedAccessoryMobile}
            mobileCurrentPage={accessoriesPageMobile}
            mobileTotalPages={accessoriesPageTotalMobile}
            nextMobile={accessoriesNextMobile}
            previousMobile={accessoriesPrevMobile}
            translations={config.translations}
            config={config}
            globalStyling={globalStyling}
          />
        )}
        {config.configurableRetailerMap ? (
          <VdpRetailerMap
            buttonTheme={buttonTheme}
            buttonBGColour={buttonBGColour}
            retailer={vehicleDetails.retailerInformation}
            translations={config.translations}
            viewRetailerStock={retailer => {
              resetFilters();
              goToModule('VehicleSearchResults', retailer.id);
            }}
            makeAnEnquiry={() =>
              dispatch(routerActions.navigate(`/enquiry/${vehicleDetails.id}`))
            }
            globalStyling={globalStyling}
            config={config.retailerMapDetails}
            getDirectionsToRetailer={url =>
              dispatch(routerActions.navigate(url))
            }
          />
        ) : (
          <ContentWrapper contentWidth={globalStyling.contentWidth}>
            <VdpRetailer
              buttonTheme={buttonTheme}
              buttonBGColour={buttonBGColour}
              retailer={vehicleDetails.retailerInformation}
              translations={config.translations}
              viewRetailerStock={retailer => {
                resetFilters();
                goToModule('SearchResults', retailer.id);
              }}
              iconColour={iconColour}
              globalStyling={globalStyling}
              retailerLocationCountry={config.retailerLocationCountry}
              retailerHeaderFont={config.retailerHeaderFont}
              retailerInfoFont={config.retailerInfoFont}
              searchCountryCode={config.searchCountryCode}
            />
          </ContentWrapper>
        )}
        {config.showApprovedBenefits && (
          <ApprovedBenefits
            backgroundImage={config.approvedBenefits.backgroundImage}
            backgroundImageMobile={
              config.approvedBenefits.backgroundImageMobile
            }
            backgroundColour={config.approvedBenefits.backgroundColour}
            topSection={{
              headingText: config.translations.approvedBenfitsHeading,
              contentText: config.translations.approvedBenfitsContent,
            }}
            bottomSection={{
              options: config.approvedBenefits.options.map((o, i) => ({
                heading: config.translations[`option${i + 1}Heading`],
                content: config.translations[`option${i + 1}Content`],
                ...o,
              })),
            }}
            contentWidth={globalStyling.contentWidth}
            horizontalPadding={config.approvedBenefitsHorizontalPadding}
          />
        )}
        {!config.hideSimilarVehicle && (
          <ContentWrapper contentWidth={globalStyling.contentWidth}>
            <VdpSimilarVehicles
              similarVehicles={similarVehicles}
              vehiclesInView={vehiclesInView}
              next={paginateNext}
              previous={paginatePrev}
              totalPages={similarVehiclePageTotal}
              currentPage={similarVehiclePage}
              mobileSelected={selectedVehicleMobile}
              mobileCurrentPage={similarVehiclePageMobile}
              mobileTotalPages={similarVehiclePageTotalMobile}
              nextMobile={paginateNextMobile}
              previousMobile={paginatePrevMobile}
              translations={config.translations}
              compareVehicle={vehicle =>
                dispatch(compareActions.toggleVehicle(vehicle))
              }
              shortlistVehicle={vehicle =>
                dispatch(shortlistActions.toggleVehicle(vehicle))
              }
              moreInfoClick={(name, id) =>
                dispatch(routerActions.navigate(`${pathByModule(name)}/${id}`))
              }
              isListed={isListed}
              comparedVehicles={sharedCompareVehicles}
              shortlistedVehicles={sharedShortListedVehicles}
              buttonTheme={buttonTheme}
              placeholdingImage={placeholdingImage}
              globalStyling={globalStyling}
              gridTile={config.gridTile}
              locale={marketInfo.locale}
              selectedHeartColour={iconColour}
              selectedCompareColour={iconColour}
            />
          </ContentWrapper>
        )}
      </Fragment>
    ) : null;
  }
}
